import React, { useEffect, useRef, useState } from 'react';
import CardBox from '../CardBox';
import { Button } from '@maxtropy/components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import { workShopUsageData } from '../../data';
import { Spin } from 'antd';

const WorkshopUsage = () => {
  const chartRef = useRef<ReactEcharts>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleResize = () => {
    const echartsInstance = chartRef.current?.getEchartsInstance();
    if (echartsInstance) {
      echartsInstance.resize();
    }
  };

  const containerRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const echartsInstance = chartRef.current?.getEchartsInstance();
      if (echartsInstance) {
        echartsInstance.resize();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CardBox
      title="车间用量"
      subTitle={dayjs().format('YYYY-MM-DD')}
      extraContent={
        <Button
          type="link"
          onClick={() => {
            // @ts-ignore
            window.open(`${window.ENERGYPLATFORMSORIGIN}/layout/home`, '_self');
          }}
        >
          详情 {'>>'}
        </Button>
      }
      className={styles.cardBoxSty}
    >
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <div className={styles.charts_wrapper} ref={containerRef}>
          <ReactEcharts
            style={{ height: '100%' }}
            ref={d => {
              if (d) {
                chartRef.current = d;
              }
            }}
            option={getChartOption(workShopUsageData)}
            notMerge
            lazyUpdate={false}
          />
        </div>
      )}
    </CardBox>
  );
};

export default WorkshopUsage;
