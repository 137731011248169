import { fetch, Key } from '@maxtropy/components';
import qs from 'qs';
import { OuInfoProps } from './ou';
import { Device } from './ouDevice';
import { AllFcsListProps } from './fcs';
import { PageRequest, PageResponse } from './page';
import { DeviceStatus } from '@/shared/types';
import { CircuitProps } from './circuit';

export enum UetUnitType {
  PROD_LOAD,
  BSA,
  PV_STATION,
  LINE_PIPE,
  MICROGRID_POWER_TOPO,
  ENERGY_MEDIUM_TOPOLOGY,
  CHARGINGSTATION,
  // 负载
  LOAD,
  // 备用电源
  BACKUP_POWER,
  // 储能站(新)
  BSA_SECOND_EDITION,
  // 气体制备站
  GAS_PREPARATION_STATION,
  // 暖通空调站
  AIR_CONDITION,
}

export const UetUnitTypeFormat = {
  [UetUnitType.PROD_LOAD]: '负载/生产线',
  [UetUnitType.BSA]: '储能站',
  [UetUnitType.PV_STATION]: '光伏站',
  [UetUnitType.LINE_PIPE]: '管线',
  [UetUnitType.MICROGRID_POWER_TOPO]: '微网电能拓扑',
  [UetUnitType.ENERGY_MEDIUM_TOPOLOGY]: '能源/工质拓扑',
  [UetUnitType.CHARGINGSTATION]: '充电站',
  [UetUnitType.LOAD]: '负载',
  [UetUnitType.BACKUP_POWER]: '备用电源',
  [UetUnitType.BSA_SECOND_EDITION]: '储能站(新)',
  [UetUnitType.GAS_PREPARATION_STATION]: '气体制备站',
  [UetUnitType.AIR_CONDITION]: '暖通空调站',
};

export enum Status {
  DISABLE,
  ENABLE,
  INVALID,
  DRAFT,
}

export const statusFormat = {
  [Status.DISABLE]: '停用',
  [Status.ENABLE]: '启用',
  [Status.INVALID]: '作废',
  [Status.DRAFT]: '草稿',
};

export interface UetInfoProps {
  baseComplete: boolean;
  businessUnits: BusinessUnitsProps[];
  createCustomerUserName: string;
  createMuid: string;
  createTime: string;
  customerCode: string;
  customerFullName: string;
  customerName: string;
  id: number;
  mcid: string;
  name: string;
  ous: OuInfoProps[];
  serialNumber: string;
  status: Status;
  tenantMcid: string;
  updateCustomerUserName: string;
  updateMuid: string;
  updateTime: string;
}

export interface BusinessUnitsProps {
  id: number;
  uetId: number;
  businessUnitType: UetUnitType;
  complete: boolean;
}

export interface UetListReq extends PageRequest {
  mcid?: string;
}

export const getUetPage = (query: UetListReq) =>
  fetch<PageResponse<UetInfoProps>>(
    `/api/v2/uet/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

export interface CreateUetReq {
  name: string;
  mcid: string;
  ouIds: number[];
}

export const createUet = (params: CreateUetReq) =>
  fetch<{ id: number }>(
    `/api/v2/uet/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface UpdateUetReq {
  name?: string;
  ouIds?: number[];
  uetBusinessUnitTypeIds?: number[];
}

export const updateUet = (id: string, body: UpdateUetReq) =>
  fetch(
    `/api/v2/uet/update`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );

export const getUetInfo = (id: string) =>
  fetch<UetInfoProps>(
    `/api/v2/uet/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const getUetUnitType = () =>
  fetch<{ list: string[] }>(
    `/api/v2/uet/getUnitType`,
    {
      method: 'POST',
    },
    true
  );

export interface CreateUetBasicReq {
  uetBusinessUnitTypeIds: number[];
}

export const createUetBasic = (id: number, body: CreateUetBasicReq) =>
  fetch(
    `/api/v2/uet/setBasic`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );

export interface LoadTransformersProps {
  deviceId: number;
  targetLoad?: number;
  totalLoadDeviceId?: number;
  productionLoadDeviceId?: number;
  level: string;
  sequence: number;
  rootDeviceId?: number;
  parentDeviceId?: number;
}

export interface CreateUetProdLoadProps {
  loadTransformers: LoadTransformersProps[];
}

export const createUetProdLoad = (id: number, body: CreateUetProdLoadProps) =>
  fetch(
    `/api/v2/uet/setProdLoad`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );

export const updateUetProdLoad = (id: number, body: CreateUetProdLoadProps) =>
  fetch<{ result: boolean; failed: string[] }>(
    `/api/v2/uet/updateProdLoad`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );

export interface LoadTransformerDetailDtosProps {
  electricityAccountId?: number;
  electricityAccountNumber?: string;
  capacity: number;
  createTime: string;
  device: Device;
  deviceId: number;
  id: number;
  ousOfDevices?: OusOfDevicesProps;
  productionLoadDevice?: Device;
  productionLoadDeviceId?: number;
  targetLoad?: number;
  totalLoadDevice?: Device;
  totalLoadDeviceId?: number;
  uetProdLoadLinkDto: UetProdLoadLinkProps;
  unit: string;
  updateTime: string;
  ouIdOfProductionLoadDevice?: number;
  ouIdOfTotalLoadDevice?: number;
}

interface OusOfDevicesProps {
  [key: string]: OuInfoProps[];
}

interface UetProdLoadLinkProps {
  createTime: string;
  id: number;
  level: number;
  loadTransformerId: number;
  parent: number;
  root: number;
  sequence: number;
  uetId: number;
  updateTime: string;
}

export interface UetProdLoadInfoProps {
  loadTransformerDetailDtos: LoadTransformerDetailDtosProps[];
}

export const uetProdLoadInfo = (id: string) =>
  fetch<UetProdLoadInfoProps>(
    `/api/v2/uet/getProdLoad`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface CreateUetBsaReq {
  uetId: string;
  ouId?: number;
  name?: string;
  fcsId?: number;
  circuitId?: number;
  masterFcsId?: number;
  slaveFcsIds?: number[];
  pcsStruct?: number;
  hardwareVersion?: string;
  installedCapacity?: number;
  ratedPower?: number;
  meterId?: number;
}

// 阵列结构类型
export enum PcsStructureType {
  Single = 1, // 单pcs
  Muti = 2, // 多pcs
}

// 阵列结构类型的枚举类型的值
export const BasStructureTypeValue = {
  [PcsStructureType.Single]: '单PCS',
  [PcsStructureType.Muti]: '多PCS',
};

export interface BSAResProps {
  id?: number;
  name: string;
  status?: Status;
  customerName?: string;
  mcid?: string;
  ouId: number;
  ouName?: string;
  serialNumber?: string;
  pcsStruct?: PcsStructureType; // pcs构型
  hardwareVersion?: string; // 硬件版本
  installedCapacity?: number; // 装机容量
  ratedPower?: number; // 额定功率
  softwareVersion?: string; // 软件版本
  softwareName?: string;
  hardwareName?: string;
  hardwareVersionEnum?: string;
}

export interface MasterFcsProps {
  networkId?: number;
  id: number;
  name: string;
  serialNumber?: string;
  ouId: number;
  gatewayId: number;
  remark?: string;
  createTime?: string;
  updateTime?: string;
  updateByUserId?: number;
  softwareVersion?: string;
  type?: number;
}
interface UetDeviceProps {
  id: number;
  name: string; //设备名称
  sn: string;
  typeId: number;
  typeName: string; //类型全称,需要手动插入并设置
  modelId: number;
  modelName: string;
  code: string;
  status: number; // 设备状态
  edgeDeviceId: number; //采集设备id
  createTime: string;
}
export interface MeterDeviceProps extends UetDeviceProps {}
export interface PvMeterDeviceProps extends UetDeviceProps {}
export enum CircuitType {
  GRID_BILLING_CIRCUIT = 1,
  OUTGOING_CIRCUIT = 2,
  INCOMING_CIRCUIT = 3,
  BUS_COUPLER_CIRCUIT = 4,
  TRANSFORMER_CIRCUIT = 5,
}

export interface BSAInfo {
  fcsList: AllFcsListProps[];
  // slaveFcsList: MasterFcsProps[]; // 从控FCS
  // masterFcs: MasterFcsProps; // 主控FCS
  bsa: BSAResProps; // bsa信息
  circuit: CircuitProps; // 回路信息
  // fcs?: FcsListProps;
  customerCode: string;
  ouSerialNumber: string;
  meterDevice: MeterDeviceProps;
  pvMeterDevice: PvMeterDeviceProps;
  upstreamTransformerMeterDevice: PvMeterDeviceProps[];
}

export const createUetBsa = (body: CreateUetBsaReq) =>
  fetch<BSAInfo>(
    `/api/v2/uet/create-bsa`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

// 创建主从FCS的BSA
export const createUetMasterSlaveBsa = (body: CreateUetBsaReq) =>
  fetch<BSAInfo>(
    `/api/v2/uet/create-master-slave-bsa`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

export const getBsaInfo = (id: number) =>
  fetch<{ list: BSAInfo[] }>(
    `/api/v2/uet/getBsaInfo`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface DeviceListByMcidProps {
  id?: number;
  name?: string; //设备名称
  sn?: string;
  typeId?: number;
  typeName?: string; //类型全称,需要手动插入并设置
  modelId?: number;
  modelName?: string;
  code?: string;
  status?: number; //设备状态
  edgeDeviceId?: number; //采集设备id
  createTime: string;
}

// 通过组织查询设备列表
export const getDeviceListByOuId = (ouIds: number[]) =>
  fetch<{ list: DeviceListByMcidProps[] }>(
    `/api/v2/device/electricityMeter/ou/list`,
    {
      method: 'POST',
      body: JSON.stringify({ ouIds }),
    },
    true
  ).then(res => res.list ?? []);

export const updateUetStatus = (id: number, status: Status) =>
  fetch(
    `/api/v2/uet/setStatus`,
    {
      method: 'POST',
      body: JSON.stringify({ id, status }),
    },
    true
  );

export const checkUnique = (name: string) =>
  fetch<{ flag: boolean }>(
    `/api/v2/uet/checkUnique`,
    {
      method: 'POST',
      body: JSON.stringify({ name }),
    },
    true
  );

export const createAllUet = (id: string) =>
  fetch(
    `/api/v2/uet/setBsaInfo`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

interface DisabledUetProps {
  state: Status;
  password: string;
}

export const disabledUet = (id: number, body: DisabledUetProps) =>
  fetch(
    `/api/v2/uet/setDisable`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );

export const checkBsaUnique = (name: string) =>
  fetch<{ flag: boolean }>(
    `/api/v2/uet/checkBsaUnique`,
    {
      method: 'POST',
      body: JSON.stringify({ name }),
    },
    true
  );

export interface UpdateBsaReq {
  uetId: string;
  fcsId?: number;
  masterFcsId?: number;
  slaveFcsIds?: number[];
  bsaId: number;
  installedCapacity?: number;
  ratedPower?: number;
  meterId?: number;
  name?: string;
  circuitId?: number;
  pvMeterId?: number;
  upstreamTransformerMeterIds?: number[];
}

export const updateBsa = (body: UpdateBsaReq) =>
  fetch<{ result: boolean }>(
    `/api/v2/uet/update-bsa`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

export interface AddPipeReq {
  name: string; //名称
  ouId: Key; //运营单元id
  deviceId: Key; //设备id
  uetId: Key; //能源拓扑id
  remark: string; //备注
}

export const addPipe = (body: AddPipeReq) => {
  return fetch(
    '/api/v2/line/pipe/add',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export const delPipe = (id: Key) => {
  return fetch(
    `/api/v2/line/pipe/del`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    },
    true
  );
};

export interface PipeDeviceListRes {
  id: Key;
  name: string; //设备名称
  sn: string;
  typeId: Key;
  typeName: string;
  modelId: Key;
  modelName: string;
  code: string;
  status: DeviceStatus; //设备状态
  edgeDeviceId: Key; //采集设备id
  createTime: string;
}

export interface PipeDeviceListQuery {
  ouId: Key;
}

export interface PipeListQuery {
  uetId: Key;
}

export interface PipeListRes {
  id: Key;
  name: string; //管线名称
  deviceId: Key; //设备id
  deviceName: string; //设备名称
  deviceCode: string; //设备编号
  ouId: Key; //运营单元id
  ouName: string; //运营单元名称
  deleteFlag: boolean; //删除标志
  createByUserId: Key; //操作人id
  createByUsername: string; //操作人名称
  createTime: string;
  updateTime: string;
}

// 管线列表(类目为积算仪)
export const getPipeList = (query: PipeListQuery) => {
  return fetch<PipeListRes[]>(`/api/line/pipe/list?${qs.stringify(query, { indices: false })}`, {
    method: 'GET',
  });
};

export async function getUetList(customerMcids: string[]) {
  const res = await fetch<{ list: UetInfoProps[] }>(
    `/api/v2/uet/list`,
    {
      method: 'POST',
      body: JSON.stringify({ customerMcids }),
    },
    true
  );
  return res.list;
}

// S60 能源/工质拓扑
export enum EnergyWorkingProcessType {
  TRANSMISSION_AND_DISTRIBUTION = 0,
  PREPARATION_AND_HANDLE = 1,
  STORAGE = 2,
}

export const EnergyWorkingProcessTypeDisplay = {
  [EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION]: '输配节点', // 输配过程
  [EnergyWorkingProcessType.PREPARATION_AND_HANDLE]: '制取/处理节点', // 制取/处理过程
  [EnergyWorkingProcessType.STORAGE]: '存储节点',
};

// 列表展示
export const EnergyWorkingProcessTypeListDisplay = {
  [EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION]: '输配能源介质', // 输配过程
  [EnergyWorkingProcessType.PREPARATION_AND_HANDLE]: '制取/处理能源介质', // 制取/处理过程
  [EnergyWorkingProcessType.STORAGE]: '存储能源介质',
};

// 用于拓扑图的图例
export const EnergyWorkingProcessTypeLabel = {
  [EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION]: '输配节点', // 输配过程
  [EnergyWorkingProcessType.PREPARATION_AND_HANDLE]: '制取/处理节点', // 制取/处理过程
  [EnergyWorkingProcessType.STORAGE]: '存储节点',
};

// 用于拓扑图的图例颜色
export const EnergyWorkingProcessTypeLabelColor = {
  [EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION]: '#4A90E2',
  [EnergyWorkingProcessType.PREPARATION_AND_HANDLE]: '#854ECA',
  [EnergyWorkingProcessType.STORAGE]: '#389E0D',
};

export interface UetEMTListResponse {
  id: number;
  name?: string; //拓扑名称
  code?: string; //编号
  energyMediumSceneId?: number; //能源/工质场景id
  energyMediumSceneName?: string; //能源/工质场景名称
  uetId: number; //所属UET的id
  countOfProcess?: number; //过程数
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 查询UET下的EMT
export const getUetEMTList = (id: string) =>
  fetch<{ list: UetEMTListResponse[] }>(
    `/api/v2/uet/getEmt`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);

export interface ProcessListDetailResponse {
  energyMediumTopologyVo: UetEMTListResponse;
  processVos: ProcessVosResponse[];
}

export interface ouSimpleVo {
  id?: number;
  name?: string;
}

export interface processEntryOrExitSimpleVo {
  id: number;
  name?: string; //名称
  processId?: number; //所属过程id
}

export interface ProcessVosResponse {
  id: number;
  name?: string; // 名称
  type?: EnergyWorkingProcessType; //类型
  energyMediumId?: number; //能源/工质id
  energyMediumVos?: EnergyMediumVosProps[]; // 能源介质
  // energyMediumName?: string; // 能源介质名称
  hasConversion?: boolean; //是否发生转换
  energyMediumTopologyId?: number; // 所属能源/工质拓扑id
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
  ouSimpleVos?: ouSimpleVo[]; // 所属运营单元列表
  picture?: number;
  pictureKey?: string;
  processEntrySimpleVos?: processEntryOrExitSimpleVo[]; //包含的入口列表
  processExitSimpleVos?: processEntryOrExitSimpleVo[]; //包含的出口列表
  // processExitSimpleVos?: processEntryOrExitSimpleVo[]; //包含的出口列表
}

export interface processEntryVos {
  id?: number;
  name?: string; //名称
  energyMediumId?: number; //能源/工质id
  energyMediumName?: string;
  refId?: number; //引用的出口id，如果工质为电能，则为回路id
  refName?: string; //引用的出口的名称，如果工质为电能，则为回路名称
  processId?: number; //所属过程id
  deviceOuVos?: deviceSimpleVos[]; //计量设备列表
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

export interface deviceSimpleVos {
  id?: number;
  name?: string;
}

// 查询UET下某一EMT的某个过程的详情
// export const getUetEMTProcessDetail = (id: string, emtId: string, processId: string) =>
//   fetch<UetEMTProcessDetailResponse>(`/api/uet/${id}/emt/${emtId}/process/${processId}`);

export interface UetEnergyMediumSceneListResponse {
  id: number; //能源介质场景id
  sceneName?: string; //能源介质场景名称
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 获取能源/工质场景列表
export const getUetEnergyMediumSceneList = () =>
  fetch<{ list: UetEnergyMediumSceneListResponse[] }>(
    '/api/v2/uet/emt/getEnergyMediumSceneList',
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

export interface CreateUETEMTReq {
  name: string; //名称
  energyMediumSceneId: number; //能源/工质场景id
}

export interface CreateUETEMTResponse {
  id?: number;
  name?: string; //拓扑名称
  code?: string; //编号
  energyMediumSceneId?: number; //能源/工质场景id
  energyMediumSceneName?: string; //能源/工质场景名称
  uetId?: number; //所属UET的id
  countOfProcess?: number; //过程数
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 创建UET下的EMT
export const createUETEMT = (id: string, body: CreateUETEMTReq) => {
  return fetch<CreateUETEMTResponse>(
    `/api/v2/uet/addEmt`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...body }),
    },
    true
  );
};

export interface UetEnergyMediumListResponse {
  id: number; //能源介质id
  energyName?: string; //能源介质名称
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 根据能源/工质场景获取可选的能源/工质列表
export const getUetEnergyMediumList = (energyMediumSceneId: string) =>
  fetch<{ list: UetEnergyMediumListResponse[] }>(
    `/api/v2/uet/emt/getEnergyMediumList`,
    {
      method: 'POST',
      body: JSON.stringify({ id: energyMediumSceneId }),
    },
    true
  ).then(res => res.list);

export interface CreateUETEMTProcessReq {
  type?: EnergyWorkingProcessType; // 类型	枚举: 0,1
  name: string; //过程名称
  energyMediumIds: number[]; //必须 能源/工质id
  ouIds: number[]; //过程所属OU id列表
  hasConversion?: boolean; //是否发生转换
  picture?: number;
  pictureKey?: string;
  processEntryCreateRequests: processEntryCreateRequestsVos[]; //过程入口创建请求体
  processExitCreateRequests: Omit<processEntryCreateRequestsVos, 'refId'>[]; //过程出口创建请求体
  processNodeCreateRequests: Omit<processEntryCreateRequestsVos, 'refId'>[]; //过程节点内创建请求体
}
export interface UpdateUETEMTProcessReq {
  type?: EnergyWorkingProcessType; // 类型	枚举: 0,1
  name: string; //过程名称
  energyMediumIds: number[]; //必须 能源/工质id
  ouIds: number[]; //过程所属OU id列表
  hasConversion?: boolean; //是否发生转换
  picture?: number;
  pictureKey?: string;
  processEntryUpdateRequests: processEntryCreateRequestsVos[]; //过程入口创建请求体
  processExitUpdateRequests: Omit<processEntryCreateRequestsVos, 'refId'>[]; //过程出口创建请求体
}

export interface processEntryCreateRequestsVos {
  name: string; //入口名称
  energyMediumId: number; //能源/工质id
  refId?: number; //引自出口id，或回路id
  deviceIds?: number[]; //计量设备id
  deviceVos?: DeviceVoProps[]; // 计量设备
  type?: number;
}

export interface EnergyMediumVosProps {
  id?: number;
  energyName?: string;
  createTime?: string;
  updateTime?: string;
}

export interface CreateUETEMTProcessResponse {
  id?: number;
  name?: string; //名称
  type?: EnergyWorkingProcessType; //	类型	枚举: 0,1枚举备注: 0 :TRANSPORT 1 :PROCESS
  hasConversion?: boolean; //是否发生转换
  energyMediumTopologyId?: number; //所属能源/工质拓扑id
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
  ouSimpleVos?: ouSimpleVo[]; //所属运营单元列表
  processEntryVos?: processEntryVos[]; //包含的入口列表
  processExitVos?: Omit<processEntryVos, 'refId' | 'refName'>[]; //包含的出口列表
  processNodeVos?: Omit<processEntryVos, 'refId' | 'refName'>[]; //包含的节点内列表
  processNodeVo?: Omit<processEntryVos, 'refId' | 'refName'>; //包含的节点内列表

  picture?: number;
  pictureKey?: string;

  energyMediumId?: number; //能源/工质id
  energyMediumVos?: EnergyMediumVosProps[]; // 能源介质

  hasPermission?: boolean; // 是否有ou权限
  isEdit?: boolean; // 是否是在编辑状态
}

// 能源/工质id与name配置项
export const energyMediumIdLinkName = [
  { id: 2001, name: '新水' },
  { id: 2002, name: '硬水' },
  { id: 2003, name: '消防水' },
  { id: 2004, name: '饮用水' },
  { id: 2005, name: '中水' },
  { id: 2006, name: '下水/污水' },
  { id: 2007, name: '冷却水' },
  { id: 2008, name: '高温水' },
  { id: 2009, name: '去离子水' },
  { id: 2010, name: '纯水' },
  { id: 2011, name: '软水' },
  { id: 2012, name: '冷媒水' },
  { id: 2013, name: '饱和蒸汽' },
  { id: 2014, name: '湿蒸汽' },
  { id: 2015, name: '过热蒸汽' },
  { id: 2016, name: '超临界水' },
  { id: 2017, name: '非饱和水' },
  { id: 2018, name: '液化天然气（LNG）' },
  { id: 2019, name: '压缩天然气（CNG）' },
  { id: 2020, name: '天然气（CH4）' },
  { id: 2021, name: '压缩空气' },
  { id: 2022, name: '氧气' },
  { id: 2023, name: '氮气' },
  { id: 2024, name: '二氧化碳' },
  { id: 2025, name: '氢' },
  { id: 2026, name: '合成气体' },
  { id: 2027, name: '焊接屏蔽气体' },
  { id: 2028, name: '制冷剂' },
  { id: 2029, name: '冷水' },
  { id: 2030, name: '电能' },
  { id: 2031, name: '工业液体' },
];

export interface RfInfoResponse {
  id: number;
  name?: string; //名称
  processId?: number; //所属过程id
  energyMediumTopologyName?: string; // 拓扑名称
}

// 根据所在的UET和EMT拓扑，查询相同能源/工质的出口 引自
export const getUetEMTCandidateExitList = (id: string, energyMediumId: string, processId?: string) =>
  fetch<{ list: RfInfoResponse[] }>(
    `/api/v2/uet/getCandidateExit`,
    {
      method: 'POST',
      body: JSON.stringify({ id, energyMediumId, processId }),
    },
    true
  ).then(res => res.list);

// 根据所在的UET，查询可选的回路列表
export const getUetCircuitList = (id: string) =>
  fetch<{ list: CircuitProps[] }>(
    `/api/v2/uet/emt/getCandidateCircuit`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);

export interface CandidateDeviceQueryParams extends PageRequest {
  typeIds?: number[] | number; //设备类目id列表
  codeOrName?: string; //设备编号或名称
  tag?: string; //设备标签
  ouIds?: number[]; //过程所属OU的id列表
}

export interface DeviceResProps {
  rootTypeId: number;
  deviceVo: DeviceVoProps;
}

export interface DeviceVoProps {
  id: number;
  rootTypeId: number;
  name?: string; //设备名称
  sn?: string;
  typeId?: number; // 设备类型id
  modelId?: number; // 设备型号id
  // modelName?: string;
  status?: number; //设备状态
  createTime?: string;
  updateTime?: string; //修改时间
  manufactureDate?: string; //出厂日期
  installationDate?: string; //安装日期
  pic?: string; //设备图片
  picFile?: string[];
  rootMcid?: string; //所属组织
  customerMcid?: string; //所属组织
  assetCode?: string; //资产编码
  updateSource?: number; //最后操作方（运管侧、租户侧）
  updateByUserFullName?: string; //最后操作人
  updateByUserId?: string; //最后操作人id
  deviceExtTemplateId?: number; //模板id
  templateVersion?: number; //模板版本号
  code?: string; //设备编码
  typeName?: string; //设备类型
  customerName?: string; //所属组织简称
  customerFullName?: string; //所属组织全称
  tags?: string[]; //标签
  statisticsDate?: string; //开始统计日期
  dataPropertyId?: number; //数据属性id
}

// 根据能源/工质和过程所属OU查询可选的计量设备
export const getUetEMTcandidateDeviceList = (id: string, query: CandidateDeviceQueryParams) =>
  fetch<PageResponse<DeviceResProps>>(
    `/api/v2/uet/emt/pageCandidateDevice`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query }),
    },
    true
  );

// 根据能源/工质场景和能源/工质查询可选的一级类目
export const getUetEMTcandidateLevel1TypeIdsList = (energyMediumId: string) =>
  fetch<{ list: number[] }>(
    `/api/v2/uet/emt/getCandidateLevel1TypeIds`,
    {
      method: 'POST',
      body: JSON.stringify({ id: energyMediumId }),
    },
    true
  ).then(res => res.list);

// 删除过程
export const delEMTprocess = (id: Key) => {
  return fetch(
    `/api/v2/uet/deleteProcess`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

export interface UetEMTPreviewResponse {
  nodes: NodesProps[];
  pointEdges: PointEdgesProps[];
}

export interface ItemPort {
  id?: number;
  hasDevice?: boolean;
}

export interface NodesProps {
  id: number; //过程id
  name?: string; //过程名称
  entryIds?: number[]; //入口id列表
  exitIds?: number[]; //出口id列表
  entryVos?: ItemPort[]; //入口id列表
  exitVos?: ItemPort[]; //出口id列表
  info?: {
    type?: EnergyWorkingProcessType;
    permissions?: number;
    pictureKey?: string;
  };
}

export interface PointEdgesProps {
  sourceId?: number;
  targetId?: number;
}

// 检查拓扑名称是否已存在
export const checkUetEMTNameUnique = (name: string) =>
  fetch<{ flag: boolean }>(`/api/v2/uet/emt/nameExist`, { method: 'POST', body: JSON.stringify({ name }) }, true).then(
    res => res.flag
  );

export interface UpdateUetEMTReq {
  id?: number;
  name: string;
}

export interface UpdateUetEMTResponse {
  id: number;
  name?: string; //拓扑名称
  code?: string; //编号
  energyMediumSceneId?: number; //能源/工质场景id
  energyMediumSceneName?: string; //能源/工质场景名称
  uetId?: number; //所属UET的id
  countOfProcess?: number; //过程数
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 更新EMT
export const updateUetEMT = (id: string, emtId: string, body: UpdateUetEMTReq) =>
  fetch<UpdateUetEMTResponse>(
    `/api/v2/uet/updateEmt`,
    {
      method: 'POST',
      body: JSON.stringify({ id, emtId, ...body }),
    },
    true
  );

export interface UetEMTAllEnergyMediumListResponse {
  id: number; //能源介质id
  energyName: string; //能源介质名称
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 获取所有能源/工质列表
export const getUetEMTAllEnergyMediumList = () =>
  fetch<{ list: UetEMTAllEnergyMediumListResponse[] }>(
    `/api/v2/uet/emt/allEnergyMediumList`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

// 检查过程名称是否已存在
export const checkUetEMTProcessNameUnique = (emtId: string, name: string) =>
  fetch<{ flag: boolean }>(
    `/api/v2/uet/emt/processNameExist`,
    { method: 'POST', body: JSON.stringify({ name, emtId }) },
    true
  ).then(res => res.flag);

// 查询staff是否有能源/工质拓扑下的过程的权限
export const checkUetEMTProcessPermissioned = (id: string) =>
  fetch<{ data: { [key: string]: boolean } }>(
    `/api/v2/uet/emt/process/getPermission`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.data);

export interface UetEMTProcessEntryNameUniqueParams {
  name?: string;
  processEntryId?: string;
}

export interface UetEMTProcessExitNameUniqueParams {
  name?: string;
  processExitId?: string;
}

// 根据所在的UET和EMT拓扑，查询相同能源/工质的出口
export const getUetEmtCandidateExit = (id: string, energyMediumId: string, processId?: string) =>
  fetch<{ list: RfInfoResponse[] }>(
    `/api/v2/uet/emt/getCandidateExit`,
    {
      method: 'POST',
      body: JSON.stringify({ id, energyMediumId, processId }),
    },
    true
  ).then(res => res.list);

// S63

export interface ChargeStationListResponse {
  id: number;
  name: string;
  ouId: number;
  ouName: string;
  code: string;
  status: number;
  uetId: number;
  circuitId: number;
  circuitName: string;
  installedCapacity: number;
  updateByUserId: string;
  updateByUsername: string;
  district: string;
  address: string;
  latitude: number;
  longitude: number;
  adcode: string;
  tenantMcid: string;
  updateTime: number;
}

// 获取充电站列表
export const getUetChargeStationList = (uetId: Key) =>
  fetch<{ list: ChargeStationListResponse[] }>(
    `/api/v2/uet/chargingStation/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id: uetId }),
    },
    true
  ).then(res => res.list);

export interface CreateUETChargeStationReq {
  uetId: number; //拓扑Id
  ouId: number; //关联的OU
  name: string; //名称
  installedCapacity: number; //装机容量
  circuitId: number; //关联的回路
}

// 新增充电站
export const createUETChargeStation = (body: CreateUETChargeStationReq) => {
  return fetch<ChargeStationListResponse>(
    `/api/v2/uet/addChargingStation`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export interface UpdateUetChargeStationReq {
  id: number; //充电站Id
  ouId: number; //关联的OU
  name: string; //名称
  installedCapacity?: number; //装机容量
  circuitId?: number; //关联的回路
}

// 编辑充电站
export const updateUetChargeStation = (body: UpdateUetChargeStationReq) =>
  fetch<ChargeStationListResponse>(
    `/api/v2/uet/chargingStation/edit`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

export interface ElectricityDeviceListReq {
  mcid: string;
}

export interface ElectricityDeviceListResponse {
  id: number;
  name: string;
  sn: string;
  typeId: number;
  typeName: string;
  modelId: number;
  modelName: string;
  code: string;
  status: number;
  edgeDeviceId: number;
}

// 获取电表列表
export const getUetElectricityDeviceList = async (body: ElectricityDeviceListReq) => {
  const res = await fetch<{ list: ElectricityDeviceListResponse[] }>(
    '/api/v2/uet/electricity/device/list',
    {
      method: 'POST',
      body: JSON.stringify({ mcid: body.mcid }),
    },
    true
  );
  return res.list;
};
