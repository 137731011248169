import ReactEcharts from 'echarts-for-react';
import ResizeObserver from 'rc-resize-observer';
import dayjs from 'dayjs';
import { TimedInfo } from '.';
import { useRef } from 'react';
import { DatePickerType } from '../..';

const AnalysisChart = ({ data, dateType }: { data: TimedInfo['timedInfoList']; dateType: DatePickerType }) => {
  const chartRef = useRef<ReactEcharts>();

  return (
    <ResizeObserver
      onResize={({ width }) => {
        chartRef.current?.getEchartsInstance().resize({ width });
      }}
    >
      <ReactEcharts
        ref={e => {
          if (e) chartRef.current = e;
        }}
        style={{
          minHeight: 375,
          height: '40vh',
        }}
        option={getChartOption(data, dateType)}
        notMerge
        lazyUpdate={false}
      />
    </ResizeObserver>
  );
};

const baseSeriesOption = (data: (number | undefined)[], name: string, color: string) => ({
  data,
  type: 'line',
  smooth: true,
  symbol: 'circle',
  showSymbol: false,
  symbolSize: 10,
  lineStyle: {
    type: 'dotted',
  },
  name,
  itemStyle: {
    borderWidth: 2,
    borderColor: '#fff',
    color,
  },
});

const getChartOption = (data: TimedInfo['timedInfoList'], dateType: DatePickerType) => {
  return {
    grid: {
      left: 10,
      right: 20,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12, 8, 12],
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 12,
        lineHeight: 20,
      },
      formatter: (tooltipData: any) => {
        return `
          <div style='color: rgba(255,255,255,0.65)'>${tooltipData[0]?.name ?? '--'}</div>
          <div >${data?.[0]?.deviceName ?? '--'}</div>
          <div>${tooltipData
            ?.map(
              (item: any) =>
                `<div style='width: 174px;display: flex;align-items: center;margin-bottom: 4px;'><span style='display:inline-block;width:8px;height:8px;background:${
                  item?.color
                };border-radius:50%;margin-right:6px'></span> ${
                  item.seriesName ?? '--'
                }<span style='flex:1;text-align: right'>${item.value ?? '--'}${
                  item.seriesType === 'bar' ? 'h' : '%'
                }</span></div></div>`
            )
            .join('')}</div>
        `;
      },
    },
    legend: [
      {
        right: 120,
        top: 6,
        bottom: 8,
        icon: 'rect',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        itemWidth: 16,
        itemHeight: 4,
        itemGap: 16,
        itemStyle: {
          borderColor: 'transparent',
          borderWidth: 0,
        },
        padding: [0, 8, 0, 0],
        data: [{ name: '空转率' }, { name: 'OEE' }, { name: '时间稼动率' }, { name: '性能稼动率' }, { name: '良品率' }],
      },
      {
        top: 6,
        right: 0,
        bottom: 8,
        icon: 'rect',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        itemWidth: 12,
        itemHeight: 12,
        itemGap: 16,
        padding: [0, 8, 0, 0],
        data: [{ name: '计划外停机时长' }],
      },
    ],
    xAxis: [
      {
        type: 'category',
        data: data!.map(i => dayjs(i.ts).format('MM-DD')),
        axisTick: {
          show: false,
        },
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 14,
        },
        axisPointer: {
          type: 'shadow',
        },
      },
      {
        type: 'category',
        data: data!.map(i => dayjs(i.ts).format('MM-DD')),
        show: false,
        axisPointer: {
          type: 'line',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '%',
        nameGap: 8,
        alignTicks: true,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
      },
      {
        type: 'value',
        name: '小时',
        nameGap: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
      },
    ],
    series: [
      baseSeriesOption(
        // @ts-ignore
        data!.map(i => i.idling),
        '空转率',
        '#ABD335'
      ),
      baseSeriesOption(
        data!.map(i => i.oee),
        'OEE',
        '#2D8DFF'
      ),
      baseSeriesOption(
        data!.map(i => i.timeOee),
        '时间稼动率',
        '#52E7FF'
      ),
      baseSeriesOption(
        data!.map(i => i.performanceOee),
        '性能稼动率',
        '#6F47FF'
      ),
      baseSeriesOption(
        data!.map(i => i.yieldRate),
        '良品率',
        '#FF477B'
      ),
      {
        name: '计划外停机时长',
        type: 'bar',
        yAxisIndex: 1,
        barWidth: data && data?.length > 15 ? 8 : 24,
        data: data!.map(i => i.unplannedShutdownTime),
        itemStyle: {
          color: '#FFCB47',
        },
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        bottom: 20,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
      },
    ],
  };
};

export default AnalysisChart;
