import React, { useRef } from 'react';
import mainBg from '../imgs/bg1@2x.png';
import UnitCoFactoryWide from '../components/UnitCoFactoryWide';
import EnergyApplication from '../components/EnergyApplication';
import AlarmList from '../components/AlarmList';
import { energyApplicationList } from '../data';
import styles from '../index.module.scss';
import graphStyles from './index.module.scss';
import classnames from 'classnames/bind';
import { TabEnum } from '../components/Header';
import StationArea from './components/StationArea';

const cx = classnames.bind(graphStyles);

interface DispatchOverviewTabProps {
  setSelectedTab: (tab: TabEnum) => void;
}

const DispatchOverviewTab = ({ setSelectedTab }: DispatchOverviewTabProps) => {
  return (
    <div className={styles.inner_center}>
      <div className={styles.center_center_box}>
        <img src={mainBg} width="100%" alt="" />
        <svg className={graphStyles.highlight} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#48D53D90' }} />
              <stop offset="100%" style={{ stopColor: '#FFD90090' }} />
            </linearGradient>

            <filter id="blur-effect">
              <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.8" />
              </feComponentTransfer>
            </filter>
          </defs>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={graphStyles.highlight}
            width="1520px"
            height="970px"
            viewBox="0 0 1520 970"
            version="1.1"
          >
            <g id="250324补充投标页面" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="外高桥组态-总站（前端开发）备份" transform="translate(0.000000, -110.000000)">
                <g id="管子中心定位" transform="translate(0.000000, 110.000000)">
                  <polygon
                    className={graphStyles.region}
                    onClick={() => setSelectedTab(TabEnum.GAS)}
                    id="location"
                    points="412 478 412 488 495 577 495 565 624 518 624 511 546 432"
                  />
                  <rect id="rectangle" stroke="transparent" x="0.5" y="0.5" width="1519" height="969" />
                </g>
              </g>
            </g>
          </svg>
        </svg>
        {/* 站点绘制区域 */}
        <StationArea />
      </div>
      <div className={styles.center_right_box}>
        {/* 右侧 */}
        <div className={styles.right_wrapper}>
          <div className={styles.content_wrapper}>
            <div className={styles.content_top_box}>
              <AlarmList title="报警列表" />
            </div>
            <div className={styles.content_top_box}>
              <UnitCoFactoryWide />
            </div>
            <div className={styles.content_top_box}>
              <EnergyApplication title="用能申请" data={energyApplicationList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DispatchOverviewTab;
