import { DatePicker, Form, Input, InputNumber, Modal, Radio, Select, ShowInput, Upload } from '@maxtropy/components';
import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { debounce, isNil } from 'lodash-es';
import {
  apiV2ReasoningAnalysisTimePost,
  apiV2ReasoningCheckModelNamePost,
  apiV2ReasoningDetailPost,
  apiV2ReasoningGetAllowDevicePost,
  apiV2ReasoningGetAllowPropertyPost,
  apiV2ReasoningGetAllowUetPost,
} from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { InWindowDisplay, InWindowType, PredictDataGranularType, PredictDataGranularTypeDisplay } from '../../utils';

export interface formSubmitProps {
  modelName: string;
  uetId: number;
  deviceId: string;
  propertyId: number;
  propertyName?: string;
  trainType: number;
  trainTs?: any[] | string;
  predictRange?: string;
  predictRangeType?: string;
  predictResolutionType?: string;
  csvKey?: string;
  environKey?: string;
  inWindow?: string;
  inWindowResolution?: string;
}

export interface ICreateOrEditModelModal {
  modelId?: number;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: formSubmitProps) => Promise<void>;
}

const { Option } = Select;

const { RangePicker } = DatePicker;

const CreateOrEditModelModal: FC<ICreateOrEditModelModal> = props => {
  const { modelId, visible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();
  const trainType = Form.useWatch('trainType', form);
  const uetId: number = Form.useWatch('uetId', form);
  const [predictRangeType, setPredictRangeType] = useState<string>('D'); // 预测数据范围
  const [inWindow, setInWindow] = useState<string>('D'); // 训练数据窗口粒度

  // 编辑查询详情
  const { data: detail } = useRequest(
    () => {
      return apiV2ReasoningDetailPost({
        id: modelId,
      });
    },
    {
      ready: !!modelId && visible,
      refreshDeps: [modelId, visible],
    }
  );

  // 编辑的时候表达赋值
  useEffect(() => {
    if (!detail) return;
    form.setFieldsValue({
      modelName: detail.modelName,
      uetId: detail.uetId,
      deviceId: detail.pvStationId + '-' + detail.deviceId + '-' + detail.pointId,
      propertyId: detail.propertyId,
      trainType: detail.trainType,
      csvKey: detail.csvKey,
      environKey: detail.environKey,
      trainTs:
        detail.trainType === 0
          ? dayjs(detail.trainStartTs).format('YYYY-MM-DD HH:mm:ss') +
            '~' +
            dayjs(detail.trainEndTs).format('YYYY-MM-DD HH:mm:ss')
          : [dayjs(detail.trainStartTs), dayjs(detail.trainEndTs)],
      predictRange: detail.predictRange,
      inWindow: detail.inWindow,
      predictResolutionType: detail.predictResolutionType,
    });
    setPredictRangeType(detail.predictRangeType ?? 'D');
    setInWindow(detail.inWindowResolution ?? 'H');
  }, [detail]);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // 当天起始时间
    let currentStartTime = dayjs(dayjs().format('YYYY-MM-DD') + ' 0:0:0');
    return current >= currentStartTime;
  };

  // 获取存在光伏站及并网点电表的uet列表
  const { data: uetList } = useRequest(
    async () => {
      const res = await apiV2ReasoningGetAllowUetPost({});
      return res.list;
    },
    { ready: visible }
  );

  // 获取uet下的光伏并网点电表
  const { data: deviceList } = useRequest(
    async () => {
      const res = await apiV2ReasoningGetAllowDevicePost({ id: uetId });
      return res.list;
    },
    { ready: !!uetId, refreshDeps: [uetId] }
  );

  // 获取模型允许的数据属性
  const { data: propertyList } = useRequest(
    async () => {
      const res = await apiV2ReasoningGetAllowPropertyPost({});
      return res.list;
    },
    { ready: visible }
  );

  const onOK = async () => {
    const values = await form.validateFields();
    const propertyName = propertyList?.find(item => item.propertyId === values.propertyId)?.propertyName;
    if (isNil(values.trainTs)) {
      Modal.error({
        title: '请输入训练数据时间范围',
      });
      return;
    }
    if (isNil(values.inWindow)) {
      Modal.error({
        title: '请输入训练数据窗口',
      });
      return;
    }
    if (isNil(values.predictRange)) {
      Modal.error({
        title: '请输入预测数据范围',
      });
      return;
    }
    if (isNil(values.predictResolutionType)) {
      Modal.error({
        title: '请输入预测数据颗粒度',
      });
      return;
    }
    await onSubmit({
      ...values,
      propertyName,
      predictRangeType: predictRangeType,
      inWindowResolution: inWindow,
    });
    form.resetFields();
  };

  // 预测数据范围类型
  const predictRangeAfter = (
    <Select
      onChange={v => {
        setPredictRangeType(v);
        form.validateFields(['predictRange']);
      }}
      value={predictRangeType}
    >
      <Option value="D">天</Option>
      <Option value="H">小时</Option>
      <Option value="1M">分钟</Option>
    </Select>
  );

  // 训练数据窗口粒度 D,H,1M
  const inWindowRangeAfter = (
    <Select
      onChange={v => {
        setInWindow(v);
        form.validateFields(['inWindow']);
      }}
      value={inWindow}
    >
      <Option value={InWindowType.DAY}>{InWindowDisplay[InWindowType.DAY]}</Option>
      <Option value={InWindowType.HOUR}>{InWindowDisplay[InWindowType.HOUR]}</Option>
      <Option value={InWindowType.MIN}>{InWindowDisplay[InWindowType.MIN]}</Option>
    </Select>
  );

  // 自定义校验规则，确保输入的是整数且在范围内
  const validateIntegerRange = (_: any, value: number, minValue: number, maxValue: number) => {
    if (value === undefined || value === null) {
      return Promise.reject(new Error('请输入整数'));
    }
    if (!Number.isInteger(value)) {
      return Promise.reject(new Error('只能输入整数'));
    }
    if (value < minValue || value > maxValue) {
      return Promise.reject(new Error(`输入范围必须在 ${minValue} - ${maxValue} 之间`));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      size="large"
      destroyOnClose
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onOK}
      title={modelId ? '编辑光伏预测模型' : '新建光伏预测模型'}
      okText={modelId ? '重新训练' : '确定'}
      bodyScroll
    >
      <Form form={form} labelAlign="left">
        <Form.Item
          name="modelName"
          label="模型名称"
          rules={[
            { required: true, message: '请输入模型名称' },
            { max: 50, message: '最多输入50个字符' },
          ]}
        >
          <Input
            placeholder="请输入"
            onChange={debounce((e: any) => {
              if (isNil(e.target.value) || e.target.value === '') return;
              apiV2ReasoningCheckModelNamePost({
                name: e.target.value,
              }).then(res => {
                if (isNil(res)) return;
                if (res.flag) {
                  form.setFields([
                    {
                      name: 'modelName',
                      errors: ['模型名称重复'],
                    },
                  ]);
                } else {
                  form.setFields([
                    {
                      name: 'name',
                      errors: [],
                    },
                  ]);
                }
              });
            }, 500)}
          />
        </Form.Item>
        <Form.Item name="uetId" label="所属UET" rules={[{ required: true, message: '请选择所属UET' }]}>
          <Select
            options={(uetList ?? []).map(item => ({
              value: item.uetId,
              label: item.uetName,
            }))}
            placeholder="请选择"
            onChange={() => form.setFieldValue('deviceId', undefined)}
          />
        </Form.Item>
        <Form.Item name="deviceId" label="训练对象" rules={[{ required: true, message: '请选择训练对象' }]}>
          <Select
            options={(deviceList ?? []).map(item => ({
              value: item.pvStationId + '-' + item.deviceId + '-' + item.pointId,
              label: item.pvStationName + '-' + item.pointName,
            }))}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          name="propertyId"
          label="训练对象数据属性"
          rules={[{ required: true, message: '请选择训练对象数据属性' }]}
        >
          <Select
            options={(propertyList ?? []).map(item => ({
              value: item.propertyId,
              label: item.propertyName,
            }))}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          name="trainType"
          label="训练方式"
          rules={[{ required: true, message: '请选择训练对象数据属性' }]}
          initialValue={0}
        >
          <Radio.Group
            onChange={e => {
              if (detail?.trainType === e.target.value) {
                form.setFieldValue(
                  'trainTs',
                  detail?.trainType === 0
                    ? dayjs(detail.trainStartTs).format('YYYY-MM-DD HH:mm:ss') +
                        '~' +
                        dayjs(detail.trainEndTs).format('YYYY-MM-DD HH:mm:ss')
                    : [dayjs(detail?.trainStartTs), dayjs(detail?.trainEndTs)]
                );
              } else {
                form.setFieldValue('trainTs', undefined);
              }
            }}
          >
            <Radio value={0}>基于CSV文件</Radio>
            <Radio value={1}>基于系统数据</Radio>
          </Radio.Group>
        </Form.Item>
        {trainType === 0 && (
          <div className={styles.files_wrapper}>
            <div className={styles.label_seat}></div>
            <Form.Item name="csvKey">
              <Upload
                accept={['.csv']}
                uploadText="上传文件"
                fileSize={20}
                maxCount={1}
                tip="上传光伏站并网点电表的运行数据格式限制为.csv，不超过20MB"
                onChange={val => {
                  if (val) {
                    form.setFieldValue('trainTs', '数据加载中...');
                    apiV2ReasoningAnalysisTimePost({ fileKey: val as string }).then(res => {
                      form.setFieldValue(
                        'trainTs',
                        dayjs(res.startTs).format('YYYY-MM-DD HH:mm:ss') +
                          '~' +
                          dayjs(res.endTs).format('YYYY-MM-DD HH:mm:ss')
                      );
                    });
                  }
                }}
                onRemove={() => {
                  form.setFieldValue('trainTs', undefined);
                }}
              />
            </Form.Item>
            <Form.Item name="environKey">
              <Upload
                accept={['.csv']}
                uploadText="上传文件"
                fileSize={20}
                maxCount={1}
                tip="上传环境数据，格式限制为.csv，不超过20MB"
              />
            </Form.Item>
          </div>
        )}
        <Form.Item label={<span className={styles.required}>训练参数：</span>} style={{ marginBottom: 0 }}>
          <Form.Item name="trainTs" label="训练数据时间范围">
            {trainType === 0 ? (
              <ShowInput />
            ) : (
              <RangePicker
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                showTime={{ format: 'YYYY-MM-DD HH:mm' }}
                format="YYYY-MM-DD HH:mm"
              />
            )}
          </Form.Item>
          <Form.Item
            label="训练数据窗口"
            name="inWindow"
            rules={[
              {
                validator: (_, value) =>
                  validateIntegerRange(
                    _,
                    value,
                    1,
                    inWindow === InWindowType.DAY ? 31 : inWindow === InWindowType.MIN ? 60 : 24
                  ),
              },
            ]}
          >
            <InputNumber
              precision={0} // 禁止输入小数
              step={1} // 每次递增/递减 1
              // min={0}
              // max={inWindow === InWindowType.DAY ? 31 : 24}
              style={{ width: '100%' }}
              addonAfter={inWindowRangeAfter}
            />
          </Form.Item>
          <Form.Item
            label="预测数据范围"
            name="predictRange"
            rules={[
              {
                validator: (_, value) =>
                  validateIntegerRange(
                    _,
                    value,
                    1,
                    predictRangeType === InWindowType.DAY ? 31 : predictRangeType === InWindowType.MIN ? 60 : 24
                  ),
              },
            ]}
          >
            <InputNumber
              precision={0} // 禁止输入小数
              step={1} // 每次递增/递减 1
              // min={0}
              // max={inWindow === InWindowType.DAY ? 31 : 24}
              style={{ width: '100%' }}
              addonAfter={predictRangeAfter}
            />
          </Form.Item>
          <Form.Item label="预测数据颗粒度" name="predictResolutionType">
            <Select>
              <Option value={PredictDataGranularType.MIN}>
                {PredictDataGranularTypeDisplay[PredictDataGranularType.MIN]}
              </Option>
              <Option value={PredictDataGranularType.FIFTEEN}>
                {PredictDataGranularTypeDisplay[PredictDataGranularType.FIFTEEN]}
              </Option>
              <Option value={PredictDataGranularType.THIRTY}>
                {PredictDataGranularTypeDisplay[PredictDataGranularType.THIRTY]}
              </Option>
              <Option value={PredictDataGranularType.HOUR}>
                {PredictDataGranularTypeDisplay[PredictDataGranularType.HOUR]}
              </Option>
            </Select>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrEditModelModal;
