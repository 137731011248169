import CompressorImg from './icons/CompressorImg@2x.png';
import ThermometerImg from './icons/ThermometerImg@2x.png';
import GCImg from './icons/GCImg@2x.png';
import GSTImg from './icons/GSTImg@2x.png';
import SBEImg from './icons/SBEImg@2x.png';
import NGImg from './icons/NGImg@2x.png';
import RefrigeratorImg from './icons/RefrigeratorImg@2x.png';
import PressureGaugeImg from './icons/PressureGaugeImg@2x.png';
import ValveImg from './icons/ValveImg@2x.png';
import FlowMeterImg from './icons/FlowMeterImg@2x.png';

export enum Status {
  Disabled = 0,
  Enable,
}

export const StatusDisplay = {
  [Status.Enable]: '启用',
  [Status.Disabled]: '禁用',
};

export enum OperationType {
  ADD,
  EDIT,
}

export const OperationTypeDisplay = {
  [OperationType.ADD]: '新增',
  [OperationType.EDIT]: '编辑',
};

export enum StationType {
  ACS = 1,
  NGS,
}

export const StationTypeDisplay = {
  [StationType.ACS]: '空压站',
  [StationType.NGS]: '制氮站',
};

export enum ACSType {
  SM = 0,
  EMC,
}

export const ACSTypeDisplay = {
  [ACSType.SM]: '自营',
  [ACSType.EMC]: '托管',
};

// 设备类型 1：计量电表  2：流量计 3：压力表 4：温度计 5：纯度监测仪 6: 露点温度计
export enum DeviceType {
  ElectricityMeter = 1,
  Flowmeter,
  PressureGauge,
  Thermometer,
  PurityMonitoringInstrument,
  DewPointThermometer,
}

export enum NodeType {
  COMPRESSOR = 1,
  NITROGEN_GENERATOR,
  GAS_TANK,
  STATION_OUTLET,
  GAS_MANIFOLD,
  THERMOMETER,
  REFRIGERATOR,
  FLOW_METER,
  PRESSURE_GAUGE,
  VALVE,
}

export enum NodeStatus {
  RUNNING = 0,
  OFFLINE = 1,
  SHUT = 2,
}

export const NodeTypeDisplay = {
  [NodeType.COMPRESSOR]: '空压机',
  [NodeType.NITROGEN_GENERATOR]: '制氮机',
  [NodeType.GAS_TANK]: '储气罐',
  [NodeType.STATION_OUTLET]: '站房出口',
  [NodeType.GAS_MANIFOLD]: '集/分气包',
  [NodeType.THERMOMETER]: '温度计',
  [NodeType.REFRIGERATOR]: '冷干机',
  [NodeType.FLOW_METER]: '流量计',
  [NodeType.PRESSURE_GAUGE]: '压力表',
  [NodeType.VALVE]: '阀门',
};

export const IconKey = {
  [NodeType.COMPRESSOR]: CompressorImg,
  [NodeType.NITROGEN_GENERATOR]: NGImg,
  [NodeType.GAS_TANK]: GSTImg,
  [NodeType.STATION_OUTLET]: SBEImg,
  [NodeType.GAS_MANIFOLD]: GCImg,
  [NodeType.THERMOMETER]: ThermometerImg,
  [NodeType.REFRIGERATOR]: RefrigeratorImg,
  [NodeType.FLOW_METER]: FlowMeterImg,
  [NodeType.PRESSURE_GAUGE]: PressureGaugeImg,
  [NodeType.VALVE]: ValveImg,
};

export const nodeSize: Record<number, [number, number]> = {
  [NodeType.COMPRESSOR]: [72, 56],
  [NodeType.NITROGEN_GENERATOR]: [56, 72],
  [NodeType.GAS_TANK]: [56, 96],
  [NodeType.STATION_OUTLET]: [72, 56],
  [NodeType.GAS_MANIFOLD]: [72, 56],
  [NodeType.THERMOMETER]: [24, 48],
  [NodeType.REFRIGERATOR]: [72, 56],
  [NodeType.FLOW_METER]: [24, 48],
  [NodeType.PRESSURE_GAUGE]: [24, 48],
  [NodeType.VALVE]: [24, 32],
};
