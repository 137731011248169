import React, { useEffect, useState } from 'react';
import mainBg from './imgs/bg3@2x.png';
import styles from './index.module.scss';
import AlarmList from './components/AlarmList';
import SwitchRecord from './components/SwitchRecord';
import LightingElecConsumption from './components/LightingElecConsumption';
import { TabEnum } from './components/Header';

interface LightingTabProps {
  setSelectedTab: (tab: TabEnum) => void;
}

// @ts-ignore
const ouIds = window.LIGHTING_OU_IDS ? JSON.parse(window.LIGHTING_OU_IDS) : [];

const LightingTab = ({ setSelectedTab }: LightingTabProps) => {
  return (
    <div className={styles.inner_center}>
      <div className={styles.center_center_box}>
        <img src={mainBg} width="100%" alt="" />
        <div className={styles.backBtn} onClick={() => setSelectedTab(TabEnum.OVERVIEW)}>
          返回调度总览
        </div>
      </div>

      <div className={styles.center_right_box}>
        {/* 右侧 */}
        <div className={styles.right_wrapper}>
          <div className={styles.content_wrapper}>
            <div className={styles.content_top_box}>
              <AlarmList title="报警列表-公区照明" ouIds={ouIds} />
            </div>
            <div className={styles.content_top_box}>
              <LightingElecConsumption />
            </div>
            <div className={styles.content_top_box}>
              <SwitchRecord title="开关记录" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightingTab;
