import {
  EllipsisSpan,
  Table,
  Button,
  Wrapper,
  Paging,
  usePaging,
  useBreadcrumbRoutes,
  Form,
  Modal,
  CustomFilter,
  DatePicker,
  Tag,
} from '@maxtropy/components';
import ReactEcharts from 'echarts-for-react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { meterProjectPagingItem } from '@/api/productionConsumptionBoard';

import dayjs from 'dayjs';
import { Space } from 'antd';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';

type SearchParams = {
  alarmDateRange?: any;
};

const { RangePicker } = DatePicker;

const originMockData = [
  {
    id: 1,
    escalationTime: '2025年03月21日',
    ruleName: '压力骤降报警',
    alarmContent: '5#空压站-空压站出口压力3数值异常',
    alarmLevel: 1,
    alarmDevice: '5#空压站-空压站出口压力3',
    abnoramlPeriod: '13:11:24',
  },
  {
    id: 2,
    escalationTime: '2025年03月14日',
    ruleName: '配电系统过载',
    alarmContent: '薄板中心C跨电表电流超载',
    alarmLevel: 1,
    alarmDevice: '薄板中心C跨电表',
    abnoramlPeriod: '11:07:52',
  },
  {
    id: 3,
    escalationTime: '2025年03月5日',
    ruleName: '压力容器异常',
    alarmContent: '储气罐压力表1-2压力持续升高',
    alarmLevel: 1,
    alarmDevice: '储气罐压力表1-2',
    abnoramlPeriod: '12:29:11',
  },
  {
    id: 4,
    escalationTime: '2025年02月22日',
    ruleName: '温度传感器故障',
    alarmContent: '冷干机温度计1-2温度信号中断',
    alarmLevel: 2,
    alarmDevice: '冷干机温度计1-2',
    abnoramlPeriod: '09:44:15',
  },
  {
    id: 5,
    escalationTime: '2025年02月14日',
    ruleName: '电源缺相报警',
    alarmContent: '涂装2除湿机3电表电源相位缺失',
    alarmLevel: 2,
    alarmDevice: '涂装2除湿机3电表',
    abnoramlPeriod: '11:53:07',
  },
  {
    id: 6,
    escalationTime: '2025年02月11日',
    ruleName: '三相不平衡',
    alarmContent: '切割中心B跨电表相位异常',
    alarmLevel: 3,
    alarmDevice: '切割中心B跨电表',
    abnoramlPeriod: '14:52:03',
  },
  {
    id: 7,
    escalationTime: '2025年01月29日',
    ruleName: '温度过高报警',
    alarmContent: '冷干机温度计1-1温度突破安全阈值',
    alarmLevel: 0,
    alarmDevice: '冷干机温度计1-1',
    abnoramlPeriod: '16:45:18',
  },
  {
    id: 8,
    escalationTime: '2025年01月18日',
    ruleName: '电力系统异常',
    alarmContent: '涂装1除湿机1电表瞬时功率异常',
    alarmLevel: 1,
    alarmDevice: '涂装1除湿机1电表',
    abnoramlPeriod: '10:32:09',
  },
  {
    id: 9,
    escalationTime: '2025年01月08日',
    ruleName: '二氧化碳泄漏',
    alarmContent: '涂装1期C跨-二氧化碳流量计16数值异常',
    alarmLevel: 0,
    alarmDevice: '涂装1期C跨-二氧化碳流量计16',
    abnoramlPeriod: '07:58:26',
  },
  {
    id: 10,
    escalationTime: '2024年12月27日',
    ruleName: '通讯模块故障',
    alarmContent: '35KV总站电表数据传输中断',
    alarmLevel: 2,
    alarmDevice: '35KV总站电表',
    abnoramlPeriod: '08:34:02',
  },
  {
    id: 11,
    escalationTime: '2024年12月19日',
    ruleName: '设备离线报警',
    alarmContent: '曲面中心-曲面中心压力传感器离线',
    alarmLevel: 3,
    alarmDevice: '曲面中心-曲面中心压力',
    abnoramlPeriod: '18:03:47',
  },
  {
    id: 12,
    escalationTime: '2024年12月07日',
    ruleName: '压缩空气泄漏',
    alarmContent: '涂装2期C跨-压缩空气流量计6数值异常',
    alarmLevel: 0,
    alarmDevice: '涂装2期C跨-压缩空气流量计6',
    abnoramlPeriod: '16:08:57',
  },
  {
    id: 13,
    escalationTime: '2024年11月25日',
    ruleName: '天然气计量偏差',
    alarmContent: '涂装2期VOCs6-天然气流量计2数据异常',
    alarmLevel: 2,
    alarmDevice: '涂装2期VOCs6-天然气流量计2',
    abnoramlPeriod: '15:39:04',
  },
  {
    id: 14,
    escalationTime: '2024年11月17日',
    ruleName: '数据采集失败',
    alarmContent: '薄板中心-压缩空气流量计1信号丢失',
    alarmLevel: 3,
    alarmDevice: '薄板中心-压缩空气流量计1',
    abnoramlPeriod: '09:02:35',
  },
  {
    id: 15,
    escalationTime: '2024年11月12日',
    ruleName: '流量计通讯中断',
    alarmContent: '空压机流量计1-1数据丢失',
    alarmLevel: 3,
    alarmDevice: '空压机流量计1-1',
    abnoramlPeriod: '14:55:41',
  },
  {
    id: 16,
    escalationTime: '2024年11月03日',
    ruleName: '瞬时流量超标',
    alarmContent: '薄板中心A跨-天然气流量计1流量超限',
    alarmLevel: 1,
    alarmDevice: '薄板中心A跨-天然气流量计1',
    abnoramlPeriod: '09:27:55',
  },
  {
    id: 17,
    escalationTime: '2024年10月30日',
    ruleName: '供氧系统异常',
    alarmContent: '曲面中心A跨-氧气流量计1流量波动',
    alarmLevel: 3,
    alarmDevice: '曲面中心A跨-氧气流量计1',
    abnoramlPeriod: '13:21:38',
  },
];

// 抄表对象类型
export type readingObjectsType = {
  id: number;
  name: string;
};

const columns: any = [
  {
    title: '序号',
    ellipsis: true,
    dataIndex: 'num',
    width: 60,
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '上报时间',
    ellipsis: true,
    dataIndex: 'escalationTime',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警规则名称',
    ellipsis: true,
    dataIndex: 'ruleName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警信息',
    ellipsis: true,
    dataIndex: 'alarmContent',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    ellipsis: true,
    dataIndex: 'alarmLevel',
    render: (v: AlarmLevel) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
            {AlarmLevelDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '报警设备/工序',
    ellipsis: true,
    dataIndex: 'alarmDevice',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '异常日志时段',
    dataIndex: 'abnoramlPeriod',
    ellipsis: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface FilterParams extends Omit<meterProjectPagingItem, 'page' | 'size'> {}

const chartData = [
  {
    name: '异常节拍数据',
    list: [
      {
        time: '2025-03-28 13:30:00',
        value: 54,
      },
      {
        time: '2025-03-28 14:30:00',
        value: 74,
      },
      {
        time: '2025-03-28 15:30:00',
        value: 43,
      },
      {
        time: '2025-03-28 15:50:00',
        value: 76,
      },
      {
        time: '2025-03-28 16:30:00',
        value: 72,
      },
      {
        time: '2025-03-28 16:50:00',
        value: 23,
      },
      {
        time: '2025-03-28 17:50:00',
        value: 54,
      },
      {
        time: '2025-03-28 18:50:00',
        value: 65,
      },
    ],
  },
  {
    name: '实际节拍数据',
    list: [
      {
        time: '2025-03-28 13:30:00',
        value: 55,
      },
      {
        time: '2025-03-28 14:30:00',
        value: 76,
      },
      {
        time: '2025-03-28 15:30:00',
        value: 56,
      },
      {
        time: '2025-03-28 15:50:00',
        value: 34,
      },
      {
        time: '2025-03-28 16:30:00',
        value: 53,
      },
      {
        time: '2025-03-28 16:50:00',
        value: 26,
      },
      {
        time: '2025-03-28 17:50:00',
        value: 46,
      },
      {
        time: '2025-03-28 18:50:00',
        value: 95,
      },
    ],
  },
  {
    name: '标准节拍数据',
    list: [
      {
        time: '2025-03-28 13:30:00',
        value: 45,
      },
      {
        time: '2025-03-28 14:30:00',
        value: 74,
      },
      {
        time: '2025-03-28 15:30:00',
        value: 45,
      },
      {
        time: '2025-03-28 15:50:00',
        value: 34,
      },
      {
        time: '2025-03-28 16:30:00',
        value: 45,
      },
      {
        time: '2025-03-28 16:50:00',
        value: 54,
      },
      {
        time: '2025-03-28 17:50:00',
        value: 34,
      },
      {
        time: '2025-03-28 18:50:00',
        value: 85,
      },
    ],
  },
];

const markNormalAreaData = [
  {
    type: '节拍正常',
    color: '#52C41A10',
    startTime: '2025-03-28 13:31:00',
    endTime: '2025-03-28 15:59:00',
  },
];

const markErrorAreaData = [
  {
    type: '节拍异常',
    color: '#E6424220',
    startTime: '2025-03-28 15:59:00',
    endTime: '2025-03-28 17:29:00',
  },
];
const markBlockAreaData = [
  {
    type: '间隔异常',
    color: '#FAAD1420',
    startTime: '2025-03-28 17:29:00',
    endTime: '2025-03-28 18:49:00',
  },
];

const getNormalMarkAreaData = () => {
  return markNormalAreaData.map(item => {
    return [
      {
        xAxis: dayjs(item?.startTime).valueOf(),
        itemStyle: {
          color: item?.color,
        },
      },
      {
        xAxis: dayjs(item?.endTime).valueOf(),
      },
    ];
  });
};
const getErrorMarkAreaData = () => {
  return markErrorAreaData.map(item => {
    return [
      {
        xAxis: dayjs(item?.startTime).valueOf(),
        itemStyle: {
          color: item?.color,
        },
      },
      {
        xAxis: dayjs(item?.endTime).valueOf(),
      },
    ];
  });
};
const getBlockMarkAreaData = () => {
  return markBlockAreaData.map(item => {
    return [
      {
        xAxis: dayjs(item?.startTime).valueOf(),
        itemStyle: {
          color: item?.color,
        },
      },
      {
        xAxis: dayjs(item?.endTime).valueOf(),
      },
    ];
  });
};

const RunLogAlarm: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { setTotalCount, setPageOffset } = pagingInfo;
  const [tableList, setTableList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [mockData, setMockData] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, contextHolder] = Modal.useModal();

  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    // @ts-ignore
    const mockList = JSON.parse(window?.runLogAlarmData ?? '[]');
    setMockData(mockList);
    setTotalCount(mockList.length ?? 0);
    setTableList(mockList.map((m: any, index: number) => ({ num: index + 1, ...m })));
  }, []);

  const onFinish = (val: any) => {
    const { alarmDateRange } = val;
    setSearchParams(val);
    setLoading(true);
    setTimeout(() => {
      if (alarmDateRange && alarmDateRange.length === 2) {
        const startDate = dayjs(alarmDateRange[0]);
        const endDate = dayjs(alarmDateRange[1]);

        // 筛选符合日期范围的数据
        const filteredData = mockData.filter(item => {
          const escalationDate = dayjs(item.escalationTime, 'YYYY年MM月DD日', true);
          return escalationDate.isBetween(startDate, endDate, null, '[]'); // [] 表示包含边界
        });

        setTotalCount(filteredData.length);
        setTableList(filteredData.map((m, index) => ({ num: index + 1, ...m })));
      } else {
        // 如果没有选择日期，则恢复所有数据
        setTotalCount(mockData.length);
        setTableList(mockData.map((m, index) => ({ num: index + 1, ...m })));
      }
      setLoading(false);
    }, 200);
  };

  const getChartOption = () => {
    return {
      color: ['#FF4D4F', '#16DD8E', '#2D8DFF', '#52C41A10', '#E6424220', '#FAAD1420'],
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
      },
      legend: [
        {
          right: 650,
          itemHeight: 4,
          itemWidth: 16,
          itemGap: 24,
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          data: [
            {
              name: '异常节拍数据',
              icon: 'rect',
            },
          ],
        },
        {
          right: 530,
          itemHeight: 4,
          itemWidth: 16,
          itemGap: 24,
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          data: [
            {
              name: '实际节拍数据',
              icon: 'rect',
            },
          ],
        },
        {
          right: 400,
          itemHeight: 4,
          itemWidth: 16,
          itemGap: 24,
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          data: [
            {
              name: '标准节拍数据',
              icon: 'rect',
            },
          ],
        },
        {
          right: 300,
          itemHeight: 16,
          itemWidth: 16,
          itemGap: 24,
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          data: [
            {
              name: '节拍正常',
              icon: 'rect',
            },
          ],
        },
        {
          right: 200,
          itemHeight: 16,
          itemWidth: 16,
          itemGap: 24,
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          data: [
            {
              name: '节拍异常',
              icon: 'rect',
            },
          ],
        },
        {
          right: 100,
          itemHeight: 16,
          itemWidth: 16,
          itemGap: 24,
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          data: [
            {
              name: '间隔异常',
              icon: 'rect',
            },
          ],
        },
      ],

      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none', // 关键：点击图例时不影响 X 轴范围
        },
      ],
      xAxis: [
        {
          type: 'time',
          // minInterval: 60 * 1000,
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
            margin: 16,
            formatter: function (e: number) {
              return dayjs(e, 'x').format('HH:mm');
            },
          },

          splitNumber: 10, // 控制间隔数量
          splitLine: {
            show: true,
            lineStyle: {
              color: '#FFFFFF20',
              type: 'solid',
            },
          },
          // min: dayjs('2025-03-28 15:30:00').valueOf(), // 设定最小值
          // max: dayjs('2025-03-28 16:50:00').valueOf(), // 设定最大值
        },
      ],
      yAxis: [
        {
          name: 'kW',
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          type: 'value',
          splitLine: {
            lineStyle: { color: 'rgba(255,255,255,0.30)' },
          },
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
        },
      ],
      series: [
        ...chartData.map(item => ({
          name: item.name,
          type: 'line',
          symbol: 'none',
          data: (item.list ?? []).map(i => [dayjs(i.time).valueOf(), i.value]),
        })),
        {
          name: '节拍正常',
          type: 'line',
          data: [],
          markArea: {
            data: getNormalMarkAreaData(),
          },
        },
        {
          name: '节拍异常',
          type: 'line',
          data: [],
          markArea: {
            data: getErrorMarkAreaData(),
          },
        },
        {
          name: '间隔异常',
          type: 'line',
          data: [],
          markArea: {
            data: getBlockMarkAreaData(),
          },
        },
      ],
    };
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
    navigate(`?`);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 220,
      render: (v: any, record: any) => {
        return (
          <Space size={16}>
            <Button type="link" onClick={() => setVisible(true)}>
              异常运行日志
            </Button>
            {record.status === 1 ? (
              <Button type="link" disabled>
                已关闭
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                    setTableList(prevList =>
                      prevList.map(item => (item.id === record.id ? { ...item, status: 1 } : item))
                    );
                  }, 200);
                }}
              >
                关闭
              </Button>
            )}
            <Button
              type="link"
              onClick={() => {
                window.open(
                  // @ts-ignore
                  `${window.WORKORDERORIGIN}/workOrder/repairWorkOrder/workOrderList/workOrderAdd?problemSource=10&operationUnitCode=2764&alarmTypeCode=5`,
                  '_blank'
                );
              }}
            >
              发起工单
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper
      className="page_wrapper"
      filters={
        <div>
          <CustomFilter<FilterParams> colSpan={6} form={form} onFinish={onFinish} onReset={onReset}>
            <Form.Item name="alarmDateRange" label="触发报警日期">
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </CustomFilter>
        </div>
      }
      routes={[...(breadcrumbRoutes?.routes ?? [])]}
    >
      <Table
        rowKey={'id'}
        sticky
        loading={loading}
        scroll={{ x: 1300 }}
        dataSource={tableList}
        columns={buildColumns}
        style={{ marginTop: 10 }}
      />

      <Paging pagingInfo={pagingInfo} />

      {contextHolder}
      {visible && (
        <Modal
          size="big"
          open={visible}
          title="异常运行日志"
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
        >
          <ReactEcharts
            option={getChartOption()}
            style={{ height: 400, width: 1000 }}
            // notMerge
            lazyUpdate={false}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default RunLogAlarm;
