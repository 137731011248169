import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import CardBox from '../CardBox';
import ReactEcharts from 'echarts-for-react';
import { ShareDataContext } from '@/pages/ZYFDashboard';
import { Carousel, Spin } from 'antd';
import dayjs from 'dayjs';
import { recordList } from '@/pages/ControlCenter/data';

export enum ActionEnum {
  ON = '开启',
  OFF = '关闭',
}

const SwitchRecord = ({ title }: { title: string }) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <CardBox title={title} subTitle={dayjs().format('YYYY-MM-DD')} className={styles.cardBoxSty}>
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <>
          <div className={styles.tableHeader}>
            <div style={{ width: '50%' }}>照明点</div>
            <div style={{ width: '20%' }}>动作</div>
            <div style={{ width: '30%' }}>时间</div>
          </div>
          <div className={styles.tableBody}>
            <Carousel
              autoplay={true}
              vertical={true}
              dots={false}
              cssEase="linear"
              autoplaySpeed={800}
              slidesToShow={6}
              speed={800}
            >
              {recordList.map((item, index) => (
                <div className={styles.tableRow} key={index}>
                  <div style={{ width: '50%' }}>{item.name}</div>
                  <div style={{ width: '20%', color: item.action === ActionEnum.ON ? '#52C41A' : '#FFFFFF' }}>
                    {item.action}
                  </div>
                  <div style={{ width: '30%' }}>{dayjs(item.time, 'HH:mm').format('MM-DD HH:mm')}</div>
                </div>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </CardBox>
  );
};

export default SwitchRecord;
