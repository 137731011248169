import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Empty, Tag } from '@maxtropy/components';
import { Carousel, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import CardBox from '../CardBox';
import styles from './index.module.scss';
import { AlarmLogResponse, getAlarmLogList } from '@/api/alarm';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import dayjs from 'dayjs';

const AlarmList = ({ ouIds, title }: { ouIds?: number[]; title: string }) => {
  const [records, setRecords] = useState<AlarmLogResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [levelInfo, setLevelInfo] = useState<{ [key: string]: number }>({});

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getAlarmLogList({
      page: 1,
      size: 50,
      ouIds: ouIds || [],
    }).then(res => {
      setRecords(res.list || []);
      setLoading(false);
      // 根据alarmLevel，统计各等级报警数量，用于展示
      const levelInfo: { [key: string]: number } = {};
      res.list.forEach(item => {
        if (levelInfo[item.alarmLevel!]) {
          levelInfo[item.alarmLevel!] += 1;
        } else {
          levelInfo[item.alarmLevel!] = 1;
        }
      });
      setLevelInfo(levelInfo);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardBox
      title={title}
      className={styles.cardBoxSty}
      extraContent={
        <Button type="link" onClick={() => navigate('/device/alarm/record')}>
          详情{'>>'}
        </Button>
      }
    >
      <Spin spinning={loading}>
        <div className={styles.monitorLevelList}>
          <div className={`${styles.monitorItem} ${styles.monitorHighest}`}>
            <span className={styles.monitorNum}>{levelInfo[AlarmLevel.HIGHEST] || 0}</span>
            <span>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</span>
          </div>
          <div className={`${styles.monitorItem} ${styles.monitorHigh}`}>
            <span className={styles.monitorNum}>{levelInfo[AlarmLevel.HIGH] || 0}</span>
            <span>{AlarmLevelDisplay[AlarmLevel.HIGH]}</span>
          </div>
          <div className={`${styles.monitorItem} ${styles.monitorMiddle}`}>
            <span className={styles.monitorNum}>{levelInfo[AlarmLevel.MIDDLE] || 0}</span>
            <span>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</span>
          </div>
          <div className={`${styles.monitorItem} ${styles.monitorLow}`}>
            <span className={styles.monitorNum}>{levelInfo[AlarmLevel.LOW] || 0}</span>
            <span>{AlarmLevelDisplay[AlarmLevel.LOW]}</span>
          </div>
          <div className={`${styles.monitorItem} ${styles.monitorLowest}`}>
            <span className={styles.monitorNum}>{levelInfo[AlarmLevel.LOWEST] || 0}</span>
            <span>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</span>
          </div>
        </div>
        <div className={styles.alarmList}>
          {records.length > 0 ? (
            <Carousel
              autoplay={true}
              vertical={true}
              dots={false}
              cssEase="linear"
              infinite={true}
              autoplaySpeed={800}
              slidesToShow={6}
              speed={800}
            >
              {records.map(item => (
                <div key={item.id}>
                  <div className={styles.alarmItem}>
                    <div style={{ width: '52px' }}>
                      <Tag border="solid" color={AlarmLevelColorDisplay[item.alarmLevel!]}>
                        {AlarmLevelDisplay[item.alarmLevel!]}
                      </Tag>
                    </div>
                    <div className={styles.overflowSty} style={{ width: '100px' }}>
                      {item.deviceName}
                    </div>
                    <div className={styles.overflowSty} style={{ width: 0, flex: 1 }}>
                      {item.alarmName}
                    </div>
                    <div style={{ width: '90px' }}>{dayjs(item.alarmTime).format('MM-DD HH:mm:ss')}</div>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            <Empty style={{ marginTop: 12 }} description="暂无数据" />
          )}
        </div>
      </Spin>
    </CardBox>
  );
};

export default AlarmList;
