export enum StationEnum {
  GAS,
  ELECTRICITY,
  WATER_PUMP,
}

export const StationEnumDisplay = {
  [StationEnum.GAS]: '气体站房',
  [StationEnum.ELECTRICITY]: '电力站房',
  [StationEnum.WATER_PUMP]: '水泵站房',
};

// 场景类型
export enum SceneTypeEnum {
  // 工业气体
  INDUSTRIAL_GAS,
  // 用气
  GAS,
  // 空压站
  AIR_COMPRESSOR_STATION,
}
