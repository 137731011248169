import { ActionEnum } from './components/SwitchRecord';
import { SceneTypeEnum, StationEnum } from './DispatchOverviewTab/components/StationArea/type';
import { AlarmLevel } from '@/shared/types';

// 按体积
export const m3Data = [
  {
    name: '氧气',
    value: 12950,
  },
  {
    name: '天然气',
    value: 9192,
  },
  {
    name: '压缩空气',
    value: 23519,
  },
  {
    name: '氮气',
    value: 1040,
  },
  {
    name: '氩气',
    value: 448,
  },
  {
    name: '二氧化碳',
    value: 20410.9,
    unit: 't',
  },
];

// 按质量
export const tData = [
  {
    name: '氧气',
    value: 18.5,
  },
  {
    name: '天然气',
    value: 6.57,
  },
  {
    name: '压缩空气',
    value: 30.4,
  },
  {
    name: '氮气',
    value: 1.3,
  },
  {
    name: '氩气',
    value: 0.8,
  },
  {
    name: '二氧化碳',
    value: 40.1,
  },
];

// 大屏2图表数据
export const workShopUsageData = [
  {
    name: '天然气',
    list: [
      {
        value: 870.28848,
      },
      {
        value: 870.28848,
      },
      {
        value: 648.45024,
      },
      {
        value: 682.5792,
      },
      {
        value: 1006.80432,
      },
      {
        value: 708.17592,
      },
      {
        value: 819.09504,
      },
      {
        value: 784.96608,
      },
      {
        value: 955.61088,
      },
      {
        value: 742.30488,
      },
      {
        value: 802.03056,
      },
      {
        value: 989.73984,
      },
      {
        value: 605.78904,
      },
      {
        value: 639.918,
      },
      {
        value: 947.07864,
      },
      {
        value: 528.99888,
      },
      {
        value: 827.62728,
      },
      {
        value: 810.5628,
      },
      {
        value: 597.2568,
      },
      {
        value: 844.69176,
      },
      {
        value: 861.75624,
      },
      {
        value: 648.45024,
      },
      {
        value: 844.69176,
      },
      {
        value: 750.83712,
      },
      {
        value: 511.9344,
      },
      {
        value: 784.96608,
      },
      {
        value: 622.85352,
      },
      {
        value: 989.73984,
      },
      {
        value: 836.15952,
      },
      {
        value: 733.77264,
      },
      {
        value: 614.32128,
      },
    ],
  },
  {
    name: '二氧化碳',
    list: [
      {
        value: 2216.3292,
      },
      {
        value: 1879.6716,
      },
      {
        value: 3057.9732,
      },
      {
        value: 2384.658,
      },
      {
        value: 2048.0004,
      },
      {
        value: 2244.384,
      },
      {
        value: 2973.8088,
      },
      {
        value: 2384.658,
      },
      {
        value: 3029.9184,
      },
      {
        value: 2917.6992,
      },
      {
        value: 3198.2472,
      },
      {
        value: 2468.8224,
      },
      {
        value: 2412.7128,
      },
      {
        value: 2581.0416,
      },
      {
        value: 3338.5212,
      },
      {
        value: 1711.3428,
      },
      {
        value: 1907.7264,
      },
      {
        value: 2749.3704,
      },
      {
        value: 2468.8224,
      },
      {
        value: 1935.7812,
      },
      {
        value: 1907.7264,
      },
      {
        value: 2777.4252,
      },
      {
        value: 2216.3292,
      },
      {
        value: 1683.288,
      },
      {
        value: 3198.2472,
      },
      {
        value: 3086.028,
      },
      {
        value: 3170.1924,
      },
      {
        value: 2637.1512,
      },
      {
        value: 2300.4936,
      },
      {
        value: 1739.3976,
      },
      {
        value: 2412.7128,
      },
    ],
  },
  {
    name: '压缩空气',
    list: [
      {
        value: 2055.3858,
      },
      {
        value: 2025.5976,
      },
      {
        value: 3097.9728,
      },
      {
        value: 3485.2194,
      },
      {
        value: 3544.7958,
      },
      {
        value: 3485.2194,
      },
      {
        value: 2889.4554,
      },
      {
        value: 3157.5492,
      },
      {
        value: 2561.7852,
      },
      {
        value: 3246.9138,
      },
      {
        value: 2889.4554,
      },
      {
        value: 2412.8442,
      },
      {
        value: 3038.3964,
      },
      {
        value: 3366.0666,
      },
      {
        value: 2859.6672,
      },
      {
        value: 2919.2436,
      },
      {
        value: 3336.2784,
      },
      {
        value: 2472.4206,
      },
      {
        value: 2383.056,
      },
      {
        value: 2621.3616,
      },
      {
        value: 3455.4312,
      },
      {
        value: 1936.233,
      },
      {
        value: 3515.0076,
      },
      {
        value: 2323.4796,
      },
      {
        value: 1876.6566,
      },
      {
        value: 2085.174,
      },
      {
        value: 1906.4448,
      },
      {
        value: 3187.3374,
      },
      {
        value: 2651.1498,
      },
      {
        value: 2055.3858,
      },
      {
        value: 3157.5492,
      },
    ],
  },
  {
    name: '氧气',
    list: [
      {
        value: 230.9328,
      },
      {
        value: 221.03568,
      },
      {
        value: 339.80112,
      },
      {
        value: 270.52128,
      },
      {
        value: 303.51168,
      },
      {
        value: 296.9136,
      },
      {
        value: 389.28672,
      },
      {
        value: 369.49248,
      },
      {
        value: 227.63376,
      },
      {
        value: 362.8944,
      },
      {
        value: 204.54048,
      },
      {
        value: 290.31552,
      },
      {
        value: 221.03568,
      },
      {
        value: 349.69824,
      },
      {
        value: 257.32512,
      },
      {
        value: 234.23184,
      },
      {
        value: 392.58576,
      },
      {
        value: 250.72704,
      },
      {
        value: 204.54048,
      },
      {
        value: 273.82032,
      },
      {
        value: 329.904,
      },
      {
        value: 280.4184,
      },
      {
        value: 260.62416,
      },
      {
        value: 366.19344,
      },
      {
        value: 283.71744,
      },
      {
        value: 234.23184,
      },
      {
        value: 204.54048,
      },
      {
        value: 343.10016,
      },
      {
        value: 346.3992,
      },
      {
        value: 320.00688,
      },
      {
        value: 207.83952,
      },
    ],
  },
];

// 大屏3图表数据
export const LightingElecConsumptionData = [
  {
    name: '照明用电量',
    list: [
      {
        value: 2112,
      },
      {
        value: 2355,
      },
      {
        value: 2377,
      },
      {
        value: 2247,
      },
      {
        value: 2084,
      },
      {
        value: 2232,
      },
      {
        value: 3510,
      },
      {
        value: 2020,
      },
      {
        value: 3794,
      },
      {
        value: 3599,
      },
      {
        value: 2173,
      },
      {
        value: 3768,
      },
      {
        value: 2710,
      },
      {
        value: 2205,
      },
      {
        value: 3892,
      },
      {
        value: 3548,
      },
      {
        value: 2934,
      },
      {
        value: 2706,
      },
      {
        value: 2688,
      },
      {
        value: 3167,
      },
      {
        value: 2655,
      },
      {
        value: 3265,
      },
      {
        value: 3475,
      },
      {
        value: 3005,
      },
      {
        value: 3135,
      },
      {
        value: 3186,
      },
      {
        value: 2230,
      },
      {
        value: 2656,
      },
      {
        value: 2904,
      },
      {
        value: 2952,
      },
      {
        value: 2952,
      },
    ],
  },
];

export const energyApplicationList = [
  { name: '薄板车间', time: '10:42', type: '天然气', value: '520m³' },
  { name: '涂装车间', time: '8:09', type: '压缩空气', value: '6900m³' },
  { name: '部件中心', time: '6:07', type: '压缩空气', value: '3000m³' },
  { name: '管子中心', time: '10:09', type: '氮气', value: '210kg' },
  { name: '平直中心', time: '9:03', type: '二氧化碳', value: '3.2t' },
  { name: '6.7#平台', time: '8:32', type: '二氧化碳', value: '4t' },
  { name: '8#平台', time: '7:49', type: '压缩空气', value: '4400m³' },
  { name: '1号码头', time: '10:11', type: '氧气', value: '1.2t' },
  { name: '2号码头', time: '10:12', type: '天然气', value: '700m³' },
  { name: '3号码头', time: '6:08', type: '天然气', value: '600m³' },
];

export const pipeCenterList = [
  { name: '管子中心', time: '7:09', type: '天然气', value: '670m³' },
  { name: '管子中心', time: '8:42', type: '氧气', value: '2.1t' },
  { name: '管子中心', time: '6:15', type: '压缩空气', value: '3000m³' },
  { name: '管子中心', time: '10:17', type: '氮气', value: '160kg' },
  { name: '管子中心', time: '8:03', type: '二氧化碳', value: '2.2t' },
  // 复制一份，数据太少滚动不生效
  { name: '管子中心', time: '7:09', type: '天然气', value: '670m³' },
  { name: '管子中心', time: '8:42', type: '氧气', value: '2.1t' },
  { name: '管子中心', time: '6:15', type: '压缩空气', value: '3000m³' },
  { name: '管子中心', time: '10:17', type: '氮气', value: '160kg' },
  { name: '管子中心', time: '8:03', type: '二氧化碳', value: '2.2t' },
];

export const recordList = [
  {
    name: '1#厂区主干道路灯',
    action: ActionEnum.ON,
    time: '18:25',
  },
  {
    name: '薄板车间库顶灯',
    action: ActionEnum.ON,
    time: '19:10',
  },
  {
    name: '2#成品仓库高杆灯',
    action: ActionEnum.ON,
    time: '20:00',
  },
  {
    name: '管子中心东侧工位灯',
    action: ActionEnum.OFF,
    time: '5:50',
  },
  {
    name: '7#装卸平台探照灯',
    action: ActionEnum.ON,
    time: '19:23',
  },
  {
    name: '码头高杆灯',
    action: ActionEnum.OFF,
    time: '6:15',
  },
  {
    name: '污水处理站路灯3#',
    action: ActionEnum.ON,
    time: '19:08',
  },
  {
    name: '12#应急灯',
    action: ActionEnum.OFF,
    time: '5:45',
  },
  {
    name: '停车场立柱灯',
    action: ActionEnum.OFF,
    time: '7:00',
  },
  {
    name: '食堂外围景观灯',
    action: ActionEnum.ON,
    time: '18:06',
  },
];

export const stationList = [
  {
    stationType: StationEnum.ELECTRICITY,
    stationName: '35kV总电站',
    stationInfo: {
      todayUsage: 25.2,
      unit: '万kWh',
    },
    points: {
      placement: 'right',
      from: {
        x: (611 / 1520) * 100,
        y: (839 / 970) * 100,
      },
      to: {
        x: (855 / 1520) * 100,
        y: (590 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.ELECTRICITY,
    stationName: '1#10kV变电站',
    stationInfo: {
      todayUsage: 4.5,
      unit: '万kWh',
    },
    points: {
      placement: 'right',
      from: {
        x: (79 / 1520) * 100,
        y: (140 / 970) * 100,
      },
      to: {
        x: (271.36 / 1520) * 100,
        y: (593.8 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.ELECTRICITY,
    stationName: '2#10kV变电站',
    stationInfo: {
      todayUsage: 8.8,
      unit: '万kWh',
    },
    points: {
      placement: 'right',
      from: {
        x: (362 / 1520) * 100,
        y: (319 / 970) * 100,
      },
      to: {
        x: (554 / 1520) * 100,
        y: (414 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.ELECTRICITY,
    stationName: '3#10kV变电站',
    stationInfo: {
      todayUsage: 6.7,
      unit: '万kWh',
      status: AlarmLevel.LOW,
    },
    points: {
      from: {
        x: (799 / 1520) * 100,
        y: (356 / 970) * 100,
      },
      to: {
        x: (870 / 1520) * 100,
        y: (570 / 970) * 100,
      },
      placement: 'right',
    },
  },
  {
    stationType: StationEnum.ELECTRICITY,
    stationName: '4#10kV变电站',
    stationInfo: {
      todayUsage: 5.2,
      unit: '万kWh',
    },
    points: {
      placement: 'right',
      from: {
        x: (1072 / 1520) * 100,
        y: (673 / 970) * 100,
      },
      to: {
        x: (1210 / 1520) * 100,
        y: (490 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '1#液氧站',
    stationInfo: {
      todayUsage: 4.9,
      unit: 't',
      openedValve: 3,
      totalValve: 4,
    },
    points: {
      from: {
        x: (1325 / 1520) * 100,
        y: (303 / 970) * 100,
      },
      to: {
        x: (1084 / 1520) * 100,
        y: (524 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '2#液氧站',
    stationInfo: {
      todayUsage: 6.4,
      unit: 't',
      openedValve: 2,
      totalValve: 4,
    },
    points: {
      from: {
        x: (26 / 1520) * 100,
        y: (268 / 970) * 100,
      },
      to: {
        x: (220 / 1520) * 100,
        y: (560 / 970) * 100,
      },
      placement: 'right',
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '3#液氧站',
    stationInfo: {
      todayUsage: 7.2,
      unit: 't',
      openedValve: 3,
      totalValve: 4,
    },
    points: {
      from: {
        x: (959 / 1520) * 100,
        y: (17 / 970) * 100,
      },
      to: {
        x: (950 / 1520) * 100,
        y: (250 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '1#二氧化碳站',
    stationInfo: {
      todayUsage: 13.9,
      unit: 't',
      openedValve: 4,
      totalValve: 4,
    },
    points: {
      from: {
        x: (1325 / 1520) * 100,
        y: (447 / 970) * 100,
      },
      to: {
        x: (1100 / 1520) * 100,
        y: (540 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '2#二氧化碳站',
    stationInfo: {
      todayUsage: 11.0,
      unit: 't',
      openedValve: 4,
      totalValve: 4,
    },
    points: {
      placement: 'right',
      from: {
        x: (26 / 1520) * 100,
        y: (447 / 970) * 100,
      },
      to: {
        x: (238 / 1520) * 100,
        y: (565 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '3#二氧化碳站',
    stationInfo: {
      todayUsage: 15.2,
      unit: 't',
      openedValve: 4,
      totalValve: 4,
    },
    points: {
      from: {
        x: (1325 / 1520) * 100,
        y: (17 / 970) * 100,
      },
      to: {
        x: (970 / 1520) * 100,
        y: (262 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.INDUSTRIAL_GAS,
    stationName: '液氮站',
    stationInfo: {
      todayUsage: 1.3,
      unit: 't',
      openedValve: 1,
      totalValve: 4,
    },
    points: {
      from: {
        x: (326 / 1520) * 100,
        y: (532 / 970) * 100,
      },
      to: {
        x: (293 / 1520) * 100,
        y: (584 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.GAS,
    stationName: '1#天然气站',
    stationInfo: {
      todayUsage: 458,
      unit: 'm³',
      openedValve: 2,
      totalValve: 4,
    },
    points: {
      placement: 'right',
      from: {
        x: (26 / 1520) * 100,
        y: (600 / 970) * 100,
      },
      to: {
        x: (270 / 1520) * 100,
        y: (612 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.GAS,
    stationName: '2#天然气站',
    stationInfo: {
      todayUsage: 8734,
      unit: 'm³',
      openedValve: 3,
      totalValve: 4,
    },
    points: {
      from: {
        x: (1140 / 1520) * 100,
        y: (17 / 970) * 100,
      },
      to: {
        x: (959 / 1520) * 100,
        y: (259 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.GAS,
    stationName: 'VOCs一期',
    stationInfo: {
      todayUsage: 193,
      unit: 'kWh',
    },
    points: {
      placement: 'right',
      from: {
        x: (814 / 1520) * 100,
        y: (814 / 970) * 100,
      },
      to: {
        x: (950 / 1520) * 100,
        y: (619 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.GAS,
    stationName: 'VOCs二期',
    stationInfo: {
      todayUsage: 430,
      unit: 'kWh',
    },
    points: {
      placement: 'right',
      from: {
        x: (810 / 1520) * 100,
        y: (485 / 970) * 100,
      },
      to: {
        x: (986 / 1520) * 100,
        y: (600 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.GAS,
    stationName: 'VOCs三期',
    stationInfo: {
      todayUsage: 651,
      unit: 'kWh',
    },
    points: {
      from: {
        x: (1059 / 1520) * 100,
        y: (819 / 970) * 100,
      },
      to: {
        x: (1050 / 1520) * 100,
        y: (675 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.AIR_COMPRESSOR_STATION,
    stationName: '1#空压站',
    stationInfo: {
      todayUsage: 3300,
      unit: 'kWh',
      openedValve: 5,
      totalValve: 5,
    },
    points: {
      placement: 'right',
      from: {
        x: (547 / 1520) * 100,
        y: (680 / 970) * 100,
      },
      to: {
        x: (900 / 1520) * 100,
        y: (640 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.AIR_COMPRESSOR_STATION,
    stationName: '2#空压站',
    stationInfo: {
      todayUsage: 2265,
      unit: 'kWh',
      status: AlarmLevel.HIGH,
      openedValve: 5,
      totalValve: 5,
    },
    points: {
      from: {
        x: (610 / 1520) * 100,
        y: (205 / 970) * 100,
      },
      to: {
        x: (577 / 1520) * 100,
        y: (370.78 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.AIR_COMPRESSOR_STATION,
    stationName: '3#空压站',
    stationInfo: {
      todayUsage: 397,
      unit: 'kWh',
      openedValve: 4,
      totalValve: 4,
    },
    points: {
      from: {
        x: (1325 / 1520) * 100,
        y: (157 / 970) * 100,
      },
      to: {
        x: (1051 / 1520) * 100,
        y: (506 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.GAS,
    sceneType: SceneTypeEnum.AIR_COMPRESSOR_STATION,
    stationName: '4#空压站',
    stationInfo: {
      todayUsage: 1276,
      unit: 'kWh',
      openedValve: 4,
      totalValve: 4,
    },
    points: {
      placement: 'right',
      from: {
        x: (26 / 1520) * 100,
        y: (746 / 970) * 100,
      },
      to: {
        x: (291 / 1520) * 100,
        y: (631 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.WATER_PUMP,
    stationName: '自来水加压泵站',
    stationInfo: {
      todayUsage: 290,
      unit: 'm³',
      openedValve: 2,
      totalValve: 2,
      openedPump: 2,
      totalPump: 2,
    },
    points: {
      from: {
        x: (1325 / 1520) * 100,
        y: (596 / 970) * 100,
      },
      to: {
        x: (1125 / 1520) * 100,
        y: (584 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.WATER_PUMP,
    stationName: '污水站',
    stationInfo: {
      todayUsage: 291,
      unit: 'm³',
      openedValve: 3,
      totalValve: 3,
      openedPump: 3,
      totalPump: 3,
    },
    points: {
      placement: 'right',
      from: {
        x: (213 / 1520) * 100,
        y: (793 / 970) * 100,
      },
      to: {
        x: (525 / 1520) * 100,
        y: (562 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.WATER_PUMP,
    stationName: '中水站',
    stationInfo: {
      todayUsage: 631,
      unit: 'm³',
      openedValve: 1,
      totalValve: 2,
      openedPump: 1,
      totalPump: 2,
    },
    points: {
      from: {
        x: (1325 / 1520) * 100,
        y: (782 / 970) * 100,
      },
      to: {
        x: (1179 / 1520) * 100,
        y: (584 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.WATER_PUMP,
    stationName: '船坞水泵房',
    stationInfo: {
      todayUsage: 94,
      unit: 'm³',
      status: AlarmLevel.MIDDLE,
      openedValve: 1,
      totalValve: 2,
      openedPump: 1,
      totalPump: 2,
    },
    points: {
      placement: 'right',
      from: {
        x: (636 / 1520) * 100,
        y: (421 / 970) * 100,
      },
      to: {
        x: (765 / 1520) * 100,
        y: (396 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.WATER_PUMP,
    stationName: '上游雨水泵房',
    stationInfo: {
      todayUsage: 4.2,
      unit: 'm³',
      openedValve: 1,
      totalValve: 2,
      openedPump: 0,
      totalPump: 4,
    },
    points: {
      placement: 'right',
      from: {
        x: (306 / 1520) * 100,
        y: (128 / 970) * 100,
      },
      to: {
        x: (537 / 1520) * 100,
        y: (391.78 / 970) * 100,
      },
    },
  },
  {
    stationType: StationEnum.WATER_PUMP,
    stationName: '下游雨水泵房',
    stationInfo: {
      todayUsage: 6.7,
      unit: 'm³',
      openedValve: 0,
      totalValve: 2,
      openedPump: 0,
      totalPump: 4,
    },
    points: {
      placement: 'right',
      from: {
        x: (683 / 1520) * 100,
        y: (17 / 970) * 100,
      },
      to: {
        x: (882 / 1520) * 100,
        y: (271.78 / 970) * 100,
      },
    },
  },
];

export const pointList = [
  {
    form: {
      x: 959,
      y: 142,
    },
    to: {
      x: 950,
      y: 364,
    },
  },
];
