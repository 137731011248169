import {
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  InputNumber,
  Select,
  Table,
  Tooltip,
  Wrapper,
  message,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { Space } from 'antd';
import { useParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { getEnergyPlanDetailList, getEnergyPlanOverview, PlanRange, PlanType } from '@/api/plustekEnergyPlan';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { apiV2CacheGetPost, apiV2CacheSavePost } from '@maxtropy/device-customer-apis-v2';

interface PlanAlarmConfigProps {}

const dayList = Array.from({ length: 12 }, (_, i) => i + 1);

const PlanAlarmConfig: React.FC<PlanAlarmConfigProps> = props => {
  const { id: energyPlanId } = useParams<{ id: string }>();
  const routes = [{ name: 'KPI报警设置' }];
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [activeTime, setActiveTime] = useState<string>();
  const [timeList, setTimeList] = useState<any>([]);
  const [tempList, setTempList] = useState<any[]>([]);
  const [cacheList, setCacheList] = useState<any[]>([]);

  useEffect(() => {
    getEnergyPlanDetailList({
      energyPlanId: Number(energyPlanId),
      planType: PlanType.MONTH,
      planRange: PlanRange.ENERGY_UNIT,
    })
      .then(res => {
        const monthId = (res.list ?? [])?.[0]?.id;
        if (!isNil(monthId)) {
          getEnergyPlanOverview(monthId)
            .then(res => {
              const objList = res.mediumObjects?.filter(m => m.energyPlanDetailObjects);
              const nextObjList = objList.filter(k => k.energyPlanDetailObjects.some(n => n.objectData));
              setTempList(nextObjList);
              const timeList = nextObjList?.[0]?.energyPlanDetailObjects?.[0].objectData?.map(j => ({
                title: dayjs(j.fillingDate).format('YYYY年MM月'),
                fillingDate: j.fillingDate,
              }));
              setTimeList(timeList);
              setActiveTime(timeList?.[0].fillingDate);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .finally(() => setLoading(false));
  }, [energyPlanId]);

  useEffect(() => {
    apiV2CacheGetPost({ cacheKey: 'kpiAlarmConfig' }).then(res => {
      const list = JSON.parse(res.cacheValue ?? '[]');
      setCacheList(list);
    });
  }, []);

  useEffect(() => {
    if (isNil(activeTime)) return;
    const historyData = cacheList.find(n => n.month === activeTime)?.list;

    const tableList = tempList.map((m, index: number) => {
      const activeItem = activeTime ?? timeList?.[0].fillingDate;
      const current = m.energyPlanDetailObjects?.[0]?.objectData.find((n: any) => n.fillingDate === activeItem);
      return {
        energyMediumId: m.energyMediumId,
        energyMediumName: m.energyMediumName,
        monthGoal: current?.fillingData,
        alarmThreshold: historyData?.[index]?.alarmThreshold ?? undefined,
        consumptionProgress: historyData?.[index]?.consumptionProgress ?? undefined,
        startAlarmDays: historyData?.[index]?.startAlarmDays ?? undefined,
        singledayExtremeWarning: historyData?.[index]?.singledayExtremeWarning ?? undefined,
      };
    });
    form.setFieldsValue({ list: tableList });
  }, [activeTime, tempList, cacheList]);

  const columns = [
    {
      title: '能源介质',
      width: 200,
      dataIndex: 'energyMediumName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '本月目标',
      dataIndex: 'monthGoal',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: (
        <div>
          <span style={{ marginRight: 5 }}>执行进度报警阀值</span>
          <Tooltip title="预算消耗率/时间进度">
            <InfoCircleOutlined style={{ fontSize: 12, color: 'var(--mx-warning-color)' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'alarmThreshold',
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => {
        return isEdit ? (
          <Form.Item name={['list', index, 'alarmThreshold']} style={{ marginBottom: 0 }}>
            <InputNumber style={{ width: '100%' }} addonAfter={'%'} />
          </Form.Item>
        ) : (
          <EllipsisSpan
            value={isNil(v) ? <span style={{ color: 'var(--mx-text-desc-color)' }}>未设定</span> : `${v}%`}
          />
        );
      },
    },
    {
      title: (
        <div>
          <span style={{ marginRight: 5 }}>起始消耗进度</span>
          <Tooltip title="总预算消耗进度低于此百分比不报警">
            <InfoCircleOutlined style={{ fontSize: 12, color: 'var(--mx-warning-color)' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'consumptionProgress',
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => {
        return isEdit ? (
          <Form.Item name={['list', index, 'consumptionProgress']} style={{ marginBottom: 0 }}>
            <InputNumber style={{ width: '100%' }} addonAfter={'%'} />
          </Form.Item>
        ) : (
          <EllipsisSpan
            value={isNil(v) ? <span style={{ color: 'var(--mx-text-desc-color)' }}>未设定</span> : `${v}%`}
          />
        );
      },
    },
    {
      title: (
        <div>
          <span style={{ marginRight: 5 }}>开始预警天数</span>
          <Tooltip title="对应天数之前不进行预警">
            <InfoCircleOutlined style={{ fontSize: 12, color: 'var(--mx-warning-color)' }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'startAlarmDays',
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => {
        return isEdit ? (
          <Form.Item name={['list', index, 'startAlarmDays']} style={{ marginBottom: 0 }}>
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              options={dayList.map(m => ({ value: m, label: m }))}
            />
          </Form.Item>
        ) : (
          <EllipsisSpan value={isNil(v) ? <span style={{ color: 'var(--mx-text-desc-color)' }}>未设定</span> : v} />
        );
      },
    },
    {
      title: '单日极值预警',
      dataIndex: 'singledayExtremeWarning',
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => {
        return isEdit ? (
          <Form.Item name={['list', index, 'consumptionProgress']} style={{ marginBottom: 0 }}>
            <InputNumber style={{ width: '100%' }} addonAfter={'%'} />
          </Form.Item>
        ) : (
          <EllipsisSpan
            value={isNil(v) ? <span style={{ color: 'var(--mx-text-desc-color)' }}>未设定</span> : `${v}%`}
          />
        );
      },
    },
  ];

  const onFinish = () => {
    const values = form.getFieldsValue();

    const oldIndex = cacheList.findIndex(k => k.month === activeTime);
    let newList = cacheList;
    if (oldIndex > -1) {
      newList.splice(oldIndex, 1, {
        month: activeTime,
        list: values.list,
      });
    } else {
      newList = [
        ...cacheList,
        {
          month: activeTime,
          list: values.list,
        },
      ];
    }
    setCacheList(newList);

    apiV2CacheSavePost({ cacheKey: 'kpiAlarmConfig', cacheValue: JSON.stringify(cacheList) }).then(res => {
      message.success('保存成功');
      setIsEdit(false);
    });
  };
  return (
    <Wrapper className={styles.editPlanWrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px 8px 20px' }}>
        <FormTitle title={`报警设置`}></FormTitle>
        <Space size={8}>
          {isEdit ? (
            <>
              <Button type="primary" onClick={onFinish}>
                保存
              </Button>
              <Button
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                取消
              </Button>
            </>
          ) : (
            <>
              <Button
                type="primary"
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                编辑
              </Button>
              <Button
                onClick={() => {
                  window.open('/energy/analysis/plustekEnergyPlan', '_self');
                }}
              >
                返回
              </Button>
            </>
          )}
        </Space>
      </div>

      <div className={styles.planContent}>
        <div className={styles.timeList}>
          {timeList.map((item: any) => (
            <div
              key={item.id}
              className={classNames(styles.timeItem, activeTime === item.fillingDate ? styles.active : null)}
              onClick={() => {
                setActiveTime(item.fillingDate);
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className={styles.divider} />
        <div className={styles.rightStyle}>
          <Form form={form} isOrigin={true}>
            <Form.Item name="list" valuePropName="dataSource">
              <Table bordered rowKey="energyMediumId" columns={columns} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};
export default PlanAlarmConfig;
