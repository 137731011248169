import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Wrapper,
  useUpdate,
  useBreadcrumbRoutes,
  EllipsisSpan,
  Button,
  Empty,
  Modal,
  Form,
  Tag,
  Table,
  Select,
  FormTitle,
  SubContent,
  useCurrent,
  TenantType,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Row, Col, Space, TableColumnsType, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  AlarmLogDetailResponse,
  AlarmLogPointSTFHData,
  AlarmLogRuleRelatedPropertiesResponse,
  deviceAssetsResponse,
  deviceAssetTypeEmun,
  getAlarmLogDetail,
  getAlarmLogRuleRelatedProperties,
  readAlarmLog,
  getAlarmLogPointSTFHList,
  getRuleRelatedPropertyList,
  RelatedPropertyDetail,
} from '../../../api/alarm';
import { AlarmChannel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import dayjs from 'dayjs';
import { useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { qingflowWorkDetailPath } from '../const';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DataZoomComponent,
  ToolboxComponent,
  LegendComponent,
  MarkLineComponent,
} from 'echarts/components';
import {
  ComposeOption,
  DataZoomComponentOption,
  LineSeriesOption,
  TitleComponentOption,
  ToolboxComponentOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
  LegendComponentOption,
  MarkLineComponentOption,
} from 'echarts';
import qs from 'qs';
import { isEmpty, isNil } from 'lodash-es';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { getOuIdsByDeviceId } from '@/api/device';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

const routes = [{ name: '记录详情' }];

type RelatedPropertyTableData = {
  key: React.Key;
  dataPropertyNameWithUnit: string;
  alarmValue: number;
  recoverValue: number;
  realtimeValue: number;
};

echarts.use([
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DataZoomComponent,
  ToolboxComponent,
  LegendComponent,
  MarkLineComponent,
  LineChart,
  CanvasRenderer,
]);

const AlarmRecordDetail: FC = () => {
  const isChannel = useCurrent()?.tenant?.tenantType === TenantType.CHANNEL;

  const echartsTheme = useEchartsTheme();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const hasCreateWorkOrderPermission = useHasPermission(PermissionsType.B_WORKER_ORDER_CREATE);
  const [updateState, updateFn] = useUpdate();

  const [data, setData] = useState<AlarmLogDetailResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [energyAssetInfo, setEnergyAssetInfo] = useState<deviceAssetsResponse[]>([]);
  const [showEnergyAssetInfoVisible, setShowEnergyAssetInfoVisible] = useState<boolean>(false);
  const [showHighFrequencyRecorderModal, setShowHighFrequencyRecorderModal] = useState<boolean>(false);
  const [relatedProperties, setRelatedProperties] = useState<AlarmLogRuleRelatedPropertiesResponse[]>([]);
  const relatedPropertyTableData: RelatedPropertyTableData[] = useMemo(() => {
    return relatedProperties.map(v => ({
      key: v.dataPropertyId,
      dataPropertyNameWithUnit: v.generalName
        ? `${v.dataPropertyName}（${v.generalName}）`
        : `${v.dataPropertyName}（--）`,
      alarmValue: v.alarmValue,
      recoverValue: v.recoverValue,
      realtimeValue: v.realtimeValue,
    }));
  }, [relatedProperties]);
  // 高频录波多选框
  const [ruleRelatedPropertyList, setRuleRelatedPropertyList] = useState<RelatedPropertyDetail[]>([]);
  const [selectedRelatedPropertyId, setSelectedRelatedPropertyId] = useState<string>('');
  // 高频录波数据
  const [selectedRelatedPropertySTFHData, setSelectedRelatedPropertySTFHData] = useState<AlarmLogPointSTFHData[]>([]);
  const timerRef = useRef<number>();
  const mountedRef = useRef(false);
  const [renderLoading, setRenderLoading] = useState(false);

  const relatedPropertyTableColumns: TableColumnsType<RelatedPropertyTableData> = [
    {
      title: '数据属性',
      dataIndex: 'dataPropertyNameWithUnit',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '触发时',
      dataIndex: 'alarmValue',
      ellipsis: { showTitle: true },
      render: (v: number) => (isNil(v) ? '--' : <EllipsisSpan value={v} />),
    },
    {
      title: '恢复时',
      dataIndex: 'recoverValue',
      ellipsis: { showTitle: true },
      render: (v: number) => (isNil(v) ? '--' : <EllipsisSpan value={v} />),
    },
    {
      title: '实时值',
      dataIndex: 'realtimeValue',
      ellipsis: { showTitle: true },
      render: (v: number) => (isNil(v) ? '--' : <EllipsisSpan value={v} />),
    },
  ];

  const option: ComposeOption<
    | DataZoomComponentOption
    | LineSeriesOption
    | TitleComponentOption
    | ToolboxComponentOption
    | TooltipComponentOption
    | XAXisComponentOption
    | YAXisComponentOption
    | LegendComponentOption
    | MarkLineComponentOption
  > = {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    title: {},
    legend: {},
    animation: false,
    tooltip: {
      show: true,
      formatter: (params: any) => {
        const { data } = params;
        const [time, value] = data as [number, number];
        return `
          <div>
            <div>时间: ${dayjs(time).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>数值: <strong>${value}</strong></div>
          </div>
        `;
      },
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter: '{HH}:{mm}',
      },
    },
    yAxis: {
      // 单位配置
      name: ruleRelatedPropertyList.find(v => selectedRelatedPropertyId === v.id.toString())?.generalName,
      nameTextStyle: {
        align: 'left',
        padding: [0, 0, 0, -15],
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: '',
        sampling: 'lttb',
        type: 'line',
        smooth: true,
        data: selectedRelatedPropertySTFHData.map(v => [v.ts, v.value]),
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'solid',
            color: '#FF4D4F',
          },
          data: [
            {
              label: { formatter: `平均功率: {c}kW`, position: 'insideEndBottom' },
              yAxis: 50,
            },
          ],
        },
      },
    ],
  };

  useEffect(() => {
    if (id) {
      getAlarmLogDetail(id).then(res => {
        if (res?.recoveryTime && res?.alarmTime) {
          const diff = dayjs.duration(dayjs(res?.recoveryTime).diff(dayjs(res?.alarmTime)));
          let durationText = '';
          if (diff.days() > 0) durationText += `${diff.days()}天`;
          if (diff.hours() > 0) durationText += `${diff.hours()}时`;
          if (diff.minutes() > 0) durationText += `${diff.minutes()}分`;
          res.durationTime = durationText;
        }

        setData(res);
      });
    }
  }, [id, updateState]);

  useEffect(() => {
    if (id && !isEmpty(selectedRelatedPropertyId)) {
      getAlarmLogPointSTFHList(id, selectedRelatedPropertyId!.toString()).then(res =>
        setSelectedRelatedPropertySTFHData(res.list ?? [])
      );
    }
  }, [id, selectedRelatedPropertyId]);

  const fetchRelatedProperties = useCallback(() => {
    if (id) {
      getAlarmLogRuleRelatedProperties(id).then(res => {
        setRelatedProperties(res.list ?? []);
      });
    }
  }, [id]);

  const longPollRelatedProperties = useCallback(async () => {
    await fetchRelatedProperties();
    if (mountedRef.current) {
      // 1 分钟请求一次
      timerRef.current = window.setTimeout(longPollRelatedProperties, 1000 * 60);
    }
  }, [fetchRelatedProperties]);

  useEffect(() => {
    if (data?.state === 0) {
      // 已恢复不进行轮询
      fetchRelatedProperties();
    } else {
      // 未恢复的报警进行轮询
      mountedRef.current = true;
      longPollRelatedProperties();
    }

    return () => {
      mountedRef.current = false;
      window.clearTimeout(timerRef.current);
    };
  }, [data?.state, fetchRelatedProperties, id, longPollRelatedProperties]);

  useEffect(() => {
    if (id) {
      getRuleRelatedPropertyList(id).then(res => {
        if (!isEmpty(res.list)) {
          setRuleRelatedPropertyList(res.list ?? []);
          setSelectedRelatedPropertyId(res?.list?.[0].id.toString());
        }
      });
    }
  }, [data?.ruleId, id]);

  const readLog = (alarmId: number) => {
    if (id) {
      readAlarmLog(id).then(res => {
        if (res.flag) {
          Modal.success({
            title: '已读！',
            okText: '确定',
            onOk() {
              updateFn();
            },
            onCancel() {
              updateFn();
            },
          });
        }
      });
    }
  };

  const showEnergyAssetInfo = (val: deviceAssetsResponse[]) => {
    setShowEnergyAssetInfoVisible(true);
    setEnergyAssetInfo(val);
  };

  const renderChart = () => {
    if (isEmpty(selectedRelatedPropertySTFHData)) {
      return (
        <Empty
          description={`暂无${
            ruleRelatedPropertyList.find(v => v.id.toString() === selectedRelatedPropertyId)?.name
          }的高频录波数据`}
        />
      );
    } else {
      return (
        <ReactEChartsCore
          style={{ border: '1px solid #ffffff33', marginTop: '16px' }}
          echarts={echarts}
          theme={echartsTheme}
          notMerge
          option={option}
          loadingOption={{
            text: '加载中',
          }}
          showLoading={renderLoading}
          onEvents={{
            rendered: () => {
              setRenderLoading(false);
            },
            finished: () => {
              setRenderLoading(false);
            },
          }}
        />
      );
    }
  };

  // 点击发起工单时, 用设备id去查下ouId
  const goHref = (id: number, alarmId: number) => {
    getOuIdsByDeviceId(id).then(res => {
      let ids = res.map(item => item.id);
      window.open(
        `${window.ALARMWORKORDER}/workOrder/repairWorkOrder/workOrderList/workOrderAdd?${qs.stringify(
          {
            problemSource: 10,
            operationUnitCode: (ids ?? []).join(','),
            alarmId,
            alarmTypeCode: -1,
          },
          { indices: false }
        )}`,
        '_self'
      );
    });
  };
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form layout="vertical" {...formLayout}>
        <FormTitle title={routes[0].name} />
        <SubContent title="设备信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item label="设备编号">
                <Space>
                  {isChannel ? (
                    <ShowInput value={data?.deviceCode} />
                  ) : (
                    <Link to={`/device/manage/device/${data?.deviceId}/detail`}>
                      <ShowInput value={data?.deviceCode} />
                    </Link>
                  )}
                </Space>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="设备名称">
                <ShowInput value={data?.deviceName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="所属租户">
                <ShowInput value={data?.tenantName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="能源资产信息">
                {!data?.deviceAssets || data?.deviceAssets.length === 0 ? (
                  '暂无'
                ) : isChannel ? (
                  <span>--</span>
                ) : (
                  <Button
                    style={{ paddingLeft: 0 }}
                    type="link"
                    onClick={() => showEnergyAssetInfo(data.deviceAssets!)}
                  >
                    点击查看
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警等级">
                <ShowInput
                  value={
                    <Tag border="solid" color={AlarmLevelColorDisplay[data?.alarmLevel!]}>
                      {AlarmLevelDisplay[data?.alarmLevel!]}
                    </Tag>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="报警详情">
          <Row>
            <Col span={8}>
              <Form.Item label="报警信息">
                <ShowInput value={data?.alarmName} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警时间">
                <ShowInput value={dayjs(data?.alarmTime).format(dateFormat)} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="恢复时间">
                <ShowInput value={data?.recoveryTime ? dayjs(data?.recoveryTime).format(dateFormat) : '未恢复'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="持续时间">
                <ShowInput value={data?.durationTime ?? '--'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警规则">
                {isChannel ? (
                  <ShowInput value={data?.ruleName} />
                ) : (
                  <Link to={`/device/rule/list/detail/${data?.ruleId}`}>
                    <ShowInput value={data?.ruleName} />
                  </Link>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="规则编号">
                <ShowInput value={data?.ruleCode} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="readTime" label="已读时间">
                <Space size={60}>
                  <ShowInput value={data?.readTime ? dayjs(data?.readTime).format(dateFormat) : undefined} />
                </Space>
              </Form.Item>
            </Col>
            {/* 若为网关离线报警或者渠道是朗新, 不显示关联属性 */}
            {data?.edgeGatewayNo || data?.channel === AlarmChannel.LONGSHINE ? null : (
              <Col span={20}>
                <Form.Item
                  label="关联属性"
                  tooltip={{
                    title: '根据时间，前后3分钟范围内无数据则不显示。实时值每分钟更新。',
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  {isEmpty(relatedProperties) ? (
                    <Empty description="报警规则未配置关联属性" />
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '16px',
                          marginBottom: '16px',
                        }}
                      >
                        {/* 关联属性存在非录波数据, 显示下面文字 */}
                        {relatedProperties.every(v => v.isSthf) ? (
                          <div />
                        ) : (
                          <Typography.Text type="secondary">
                            未采集高频录波数据，数据与实际触发可能不一致。
                          </Typography.Text>
                        )}
                        <Typography.Link
                          onClick={() => {
                            setShowHighFrequencyRecorderModal(true);
                            setRenderLoading(true);
                          }}
                        >
                          高频录波
                        </Typography.Link>
                      </div>

                      <Table
                        bordered
                        // 如果为 0 说明报警已恢复, 无需展示实时值
                        columns={
                          data?.state === 0
                            ? relatedPropertyTableColumns.slice(0, relatedPropertyTableColumns.length - 1)
                            : relatedPropertyTableColumns
                        }
                        dataSource={relatedPropertyTableData}
                        pagination={false}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
        </SubContent>

        <Space className="sticky-footer" size={8}>
          {isChannel ? (
            <Button
              className={styles.button}
              onClick={() => {
                if (window.history.length > 1) {
                  navigate(-1);
                } else {
                  window.location.href = document.referrer;
                }
              }}
            >
              返回
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                onClick={() => {
                  goHref(+data?.deviceId!, data?.id!);
                }}
              >
                发起工单
              </Button>
              {hasCreateWorkOrderPermission ? (
                data?.ruleLogWorkOrderId ? (
                  <Button onClick={() => window.open(qingflowWorkDetailPath, '_blank')}>查看工单</Button>
                ) : (
                  <Button>
                    <Link to={`/device/alarm/record/${data?.id}/create`}>创建工单</Link>
                  </Button>
                )
              ) : null}
              <Button
                className={styles.button}
                onClick={() => {
                  if (window.history.length > 1) {
                    navigate(-1);
                  } else {
                    window.location.href = document.referrer;
                  }
                }}
              >
                返回
              </Button>
              <Button
                disabled={!!data?.readTime}
                type="primary"
                onClick={() => {
                  readLog(data?.id!);
                }}
              >
                确认已读
              </Button>

              <Button
                type="primary"
                disabled={!data?.deviceId}
                onClick={() => {
                  window.open(
                    `/data/history/device?deviceId=${data?.deviceId}&alarmTime=${dayjs(data?.alarmTime).valueOf()}`,
                    '_blank'
                  );
                }}
              >
                设备数据
              </Button>
            </>
          )}
        </Space>
      </Form>
      <Modal
        title="能源资产信息"
        open={showEnergyAssetInfoVisible}
        onCancel={() => setShowEnergyAssetInfoVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setShowEnergyAssetInfoVisible(false)}>
            关闭
          </Button>,
        ]}
      >
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.PVSTATION).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>光伏能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.PVSTATION)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}

        {energyAssetInfo.filter(
          item =>
            item.deviceAssetType === deviceAssetTypeEmun.ENERGYSTORAGEARRAY ||
            item.deviceAssetType === deviceAssetTypeEmun.NEW_ENERGYSTORAGEARRAY
        ).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>储能能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(
                  item =>
                    item.deviceAssetType === deviceAssetTypeEmun.ENERGYSTORAGEARRAY ||
                    item.deviceAssetType === deviceAssetTypeEmun.NEW_ENERGYSTORAGEARRAY
                )
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}

        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.NETENERGY).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>微网能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.NETENERGY)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.CHARGING).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>充电站能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.CHARGING)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.GAS_PREPARATION_STATION).length !==
          0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>气体制备站能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.GAS_PREPARATION_STATION)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
      </Modal>

      <Modal
        size="normal"
        title="高频录波"
        open={showHighFrequencyRecorderModal}
        onCancel={() => {
          setShowHighFrequencyRecorderModal(false);
          setRenderLoading(true);
        }}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setShowHighFrequencyRecorderModal(false);
            }}
          >
            确定
          </Button>
        }
      >
        <Row justify="space-between">
          <Space size={8}>
            <span>数据属性: </span>
            <Select
              optionFilterProp="label"
              value={selectedRelatedPropertyId}
              options={ruleRelatedPropertyList.map(v => ({ value: v.id.toString(), label: v.name }))}
              onChange={(value: string) => {
                setSelectedRelatedPropertyId(value);
              }}
              style={{ display: 'inline-block', width: '150px' }}
            />
          </Space>
          {isEmpty(selectedRelatedPropertySTFHData) ? (
            <div />
          ) : (
            <Button
              onClick={() => {
                window.open(`/api/v2/rule/alarm/log/point-sthf-list/export?id=${id}`);
              }}
            >
              导出数据
            </Button>
          )}
        </Row>
        {renderChart()}
      </Modal>
    </Wrapper>
  );
};

export default AlarmRecordDetail;
