import React, { useState, useEffect, useRef } from 'react';

interface LineConnectorProps {
  startRef: React.RefObject<HTMLButtonElement>;
  imageRef?: React.RefObject<HTMLImageElement>;
  startPoint: { x: number; y: number };
  endPoint: { x: number; y: number };
  bends?: Array<[number, number]>;
  placement?: 'left' | 'right';
}

const LineConnector = ({
  startRef,
  imageRef,
  startPoint,
  endPoint,
  placement = 'left',
  bends = [],
}: LineConnectorProps) => {
  const [path, setPath] = useState('');

  useEffect(() => {
    if (!startRef.current) return;

    const startRect = startRef.current.getBoundingClientRect();
    const svgWidth = 1520; // SVG 容器的宽度
    const svgHeight = 970; // SVG 容器的高度
    // 将百分比值转换为像素值
    const startXPixels = (startPoint.x / 100) * 1520;
    const startYPixels = (startPoint.y / 100) * 970;
    // 计算起点（按钮中心）
    // const startX = startXPixels;
    const startX = startXPixels + (placement === 'right' ? startRect.width : 0);
    const startY = (startPoint.y / 100) * svgHeight + startRect.height / 2;
    // const startY = startYPixels + startRect.height / 2;
    // 计算终点（图片上的点，相对于图片）
    const endX = (endPoint.x / 100) * 1520;
    const endY = (endPoint.y / 100) * 970;

    // 动态计算拐角点
    const calculateBends = (startX: number, startY: number, endX: number, endY: number): Array<[number, number]> => {
      const bends: Array<[number, number]> = [];

      // 如果起点在终点的左侧，添加一个水平拐角点
      if (startX < endX) {
        bends.push([endX, startY]); // 水平拐角点
      }
      // 如果起点在终点的右侧，添加一个水平拐角点
      else if (startX > endX) {
        bends.push([endX, startY]); // 水平拐角点
      }

      return bends;
    };

    const dynamicBends = calculateBends(startX, startY, endX, endY);
    // 计算折线路径
    let points = [[startX, startY], ...dynamicBends, [endX, endY]];
    const pathStr = points.map(p => p.join(',')).join(' ');

    setPath(pathStr);
  }, [startRef, imageRef, endPoint, startPoint, bends, placement]);

  return (
    <svg
      style={{
        position: 'fixed',
        top: 110,
        left: 0,
        width: 1520,
        height: 970,
        pointerEvents: 'none',
        zIndex: 2,
      }}
    >
      {/* <polyline points={path} stroke="red" strokeWidth="1" fill="none" strokeDasharray="3,3" /> */}
      <polyline points={path} stroke="rgba(255, 255, 255, 0.2)" strokeWidth="1" fill="none" strokeDasharray="3,3" />
    </svg>
  );
};

export default LineConnector;
