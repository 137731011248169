import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { V2OeeApplyGetOeeDayPostResponse } from '@maxtropy/device-customer-apis-v2';
import { Empty, Tabs } from '@maxtropy/components';
import SubTitle from '../subTitle';
import AnalysisChart from './AnalysisChart';
import styles from './index.module.scss';
import { DatePickerType } from '../..';

type TimedInfoList = V2OeeApplyGetOeeDayPostResponse['timedInfoList'];
type BaseOeeInfoList = V2OeeApplyGetOeeDayPostResponse['baseOeeInfo'];
export type TimedInfo = Exclude<TimedInfoList, undefined>[number];

type DeviceAnalysisProp = {
  timedInfoList?: TimedInfoList;
  baseOeeInfoList?: BaseOeeInfoList;
  dateType: DatePickerType;
};

const DeviceAnalysis = ({ timedInfoList, baseOeeInfoList, dateType }: DeviceAnalysisProp) => {
  const [activeKey, setActiveKey] = useState<string>('');

  useEffect(() => {
    setActiveKey('0');
  }, [timedInfoList]);

  const RenderTabPane = ({ data }: { data: TimedInfo }) => {
    const baseOeeInfo = baseOeeInfoList?.find(device => device.deviceId === data.deviceId);
    console.log('222', baseOeeInfo);
    const baseRateInfo = [
      {
        name: '设备OEE',
        value: baseOeeInfo?.oee,
      },
      {
        name: '时间稼动率',
        value: baseOeeInfo?.timeOee,
      },
      {
        name: '性能稼动率',
        value: baseOeeInfo?.performanceOee,
      },
      {
        name: '良品率',
        value: baseOeeInfo?.yieldRate,
      },
    ];

    return (
      <div>
        <div className={styles.deviceBoard}>
          {baseRateInfo.map(i => (
            <div className={styles.deviceItem}>
              <div className={styles.name}>{i.name}</div>
              <div>{i.value ?? '--'}%</div>
            </div>
          ))}
        </div>
        <AnalysisChart data={data.timedInfoList} dateType={dateType} />
      </div>
    );
  };

  return (
    <div>
      <SubTitle title="设备OEE分析" />
      {isEmpty(timedInfoList) ? (
        <Empty style={{ marginTop: 50 }} />
      ) : (
        <Tabs
          activeKey={activeKey}
          onChange={key => setActiveKey(key)}
          items={timedInfoList!.map((item, index) => ({
            key: String(index),
            label: item.deviceName,
            children: <RenderTabPane data={item} />,
          }))}
        />
      )}
    </div>
  );
};

export default DeviceAnalysis;
