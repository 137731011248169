import React from 'react';
import mainBg from './imgs/bg2@2x.png';
import styles from './index.module.scss';
import AlarmList from './components/AlarmList';
import EnergyApplication from './components/EnergyApplication';
import { pipeCenterList } from './data';
import WorkshopUsage from './components/WorkshopUsage';
import { TabEnum } from './components/Header';

// @ts-ignore
const ouIds = window.GAS_OU_IDS ? JSON.parse(window.GAS_OU_IDS) : [];

interface UseGasTabProps {
  setSelectedTab: (tab: TabEnum) => void;
}

const UseGasTab = ({ setSelectedTab }: UseGasTabProps) => {
  return (
    <div className={styles.inner_center}>
      <div className={styles.center_center_box}>
        <img src={mainBg} width="100%" alt="" />
        <div className={styles.backBtn} onClick={() => setSelectedTab(TabEnum.OVERVIEW)}>
          返回调度总览
        </div>
      </div>

      <div className={styles.center_right_box}>
        {/* 右侧 */}
        <div className={styles.right_wrapper}>
          <div className={styles.content_wrapper}>
            <div className={styles.content_top_box}>
              <AlarmList title="报警列表-管子中心" ouIds={ouIds} />
            </div>
            {/* 车间用量 */}
            <div className={styles.content_top_box}>
              <WorkshopUsage />
            </div>
            <div className={styles.content_top_box}>
              <EnergyApplication title="用能申请-管子中心" data={pipeCenterList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseGasTab;
