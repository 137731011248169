import FirstImg from '../../assets/firstImg@2x.png';
import secondImg from '../../assets/secondImg@2x.png';
import thirdImg from '../../assets/thirdImg@2x.png';
import styles from './index.module.scss';
import { Empty, Tooltip } from '@maxtropy/components';
import SubTitle from '../subTitle';
import { V2OeeApplyGetOeeDayPostResponse } from '@maxtropy/device-customer-apis-v2';
import { isEmpty } from 'lodash-es';

export type BaseOeeInfoList = V2OeeApplyGetOeeDayPostResponse['baseOeeInfo'];
export type BaseOeeInfo = {
  deviceName: string;
  timeOee: number;
  runTime: number;
  standbyRunTime: number;
  standByTime: number;
  downTime: number;
};

const RankImg = {
  0: FirstImg,
  1: secondImg,
  2: thirdImg,
};

const RenderTooltip = (item: {
  deviceName: string;
  timeOee: number;
  runTime: number;
  standbyRunTime: number;
  standByTime: number;
  downTime: number;
}) => (
  <div className={styles.barTooltipContent}>
    <div>{item.deviceName || '--'}</div>
    <div>
      <span>
        <span className={styles.dot} />
        空转率
      </span>
      {item.timeOee ?? '--'}%
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#52E7FF' }} />
        工作时段运行时长
      </span>
      {item.runTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#2D8DFF' }} />
        非工作时段运行时长
      </span>
      {item.standbyRunTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#FFCB47' }} />
        非工作时段待机时长
      </span>
      {item.standByTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#E64242' }} />
        停机时长
      </span>
      {item.downTime ?? '--'}h
    </div>
  </div>
);

const transPercent = (member: number | undefined, members: number[]) => {
  return member ? Number(((member / members.reduce((a, b) => a + b, 0)) * 100).toFixed(2)) : 0;
};

const RenderBar = (item: BaseOeeInfo, index: keyof typeof RankImg) => {
  const runTimeRate = transPercent(item.runTime!, [
    item.runTime || 0,
    item.standbyRunTime || 0,
    item.standByTime || 0,
    item.downTime || 0,
  ]);

  const standbyTimeRate = transPercent(item.standByTime!, [
    item.runTime || 0,
    item.standbyRunTime || 0,
    item.standByTime || 0,
    item.downTime || 0,
  ]);

  const standbyRunTimeRate = transPercent(item.standbyRunTime!, [
    item.runTime || 0,
    item.standbyRunTime || 0,
    item.standByTime || 0,
    item.downTime || 0,
  ]);

  return (
    <div className={styles.list}>
      {RankImg[index] ? (
        <img src={RankImg[index]} width={20} height={20} alt="" />
      ) : (
        <span className={styles.rank}>{index + 1}</span>
      )}
      <Tooltip title={item.deviceName}>
        <span className={styles.name}>{item.deviceName}</span>
      </Tooltip>
      <Tooltip overlayClassName={styles.barTooltip} title={RenderTooltip(item)} arrow={false}>
        <div style={{ flex: 1, height: 12, marginRight: 6, background: 'rgba(255, 255, 255, 0.1)' }}>
          <div
            style={{
              height: 12,
              width: `${runTimeRate + standbyTimeRate + standbyRunTimeRate}%`,
              background: `linear-gradient(to right,
               #52E7FF 0%, 
               #52E7FF ${runTimeRate}%,
                #2D8DFF ${runTimeRate}%,
                #2D8DFF ${runTimeRate + standbyTimeRate}%,
                #FFCB47 ${runTimeRate + standbyTimeRate}%,
                #FFCB47 ${runTimeRate + standbyTimeRate + standbyRunTimeRate}%,
                #E64242 ${runTimeRate + standbyTimeRate + standbyRunTimeRate}%,
                #E64242 100%)`,
            }}
          />
        </div>
      </Tooltip>
      <div style={{ width: 156 }}>
        空转率：<span style={{ color: '#2D8DFF' }}>{item.timeOee ?? '--'}%</span>
      </div>
    </div>
  );
};
const DeviceUptime = ({ data }: { data: BaseOeeInfoList }) => {
  return (
    <div>
      <SubTitle title="设备空转分析" />
      {isEmpty(data) ? (
        <Empty style={{ margin: 50 }} />
      ) : (
        <>
          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <span style={{ background: '#52E7FF' }} />
              工作时段运行时长
            </div>
            <div className={styles.legendItem}>
              <span style={{ background: '#2D8DFF' }} />
              非工作时段运行时长
            </div>
            <div className={styles.legendItem}>
              <span style={{ background: '#FFCB47' }} />
              非工作时段待机时长
            </div>
            <div className={styles.legendItem}>
              <span style={{ background: '#E64242' }} />
              停机时长
            </div>
          </div>
          <div className={styles.content}>
            {data!.map((item, index) => RenderBar(item as BaseOeeInfo, index as keyof typeof RankImg))}
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceUptime;
