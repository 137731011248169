import React, { useState } from 'react';
import { Switch, PopConfirm, message } from '@maxtropy/components';

interface SwitchControlProps {
  totalCount: number; // 总数量
  openedCount: number; // 开启数量
  label: string; // 标签（如 "阀门" 或 "泵"）
  onStateChange?: (newOpenedCount: number) => void; // 状态变化的回调函数
}

const SwitchControl: React.FC<SwitchControlProps> = ({ totalCount, openedCount, label, onStateChange }) => {
  // 使用 useState 管理每个开关的状态
  const [switchStates, setSwitchStates] = useState<boolean[]>(
    Array.from({ length: totalCount }, (_, index) => index < openedCount)
  );
  // 使用 useState 管理每个开关的加载状态
  const [loadingStates, setLoadingStates] = useState<boolean[]>(Array.from({ length: totalCount }, () => false));

  // 模拟请求的异步函数
  const simulateRequest = async (index: number, checked: boolean) => {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 100); // 模拟 100ms 的请求时间
    });
  };

  // 处理开关状态变化的回调函数
  const handleSwitchChange = async (index: number, checked: boolean) => {
    // 设置当前开关的加载状态为 true
    const newLoadingStates = [...loadingStates];
    newLoadingStates[index] = true;
    setLoadingStates(newLoadingStates);

    const container = document.getElementById('full_screen_box');
    message.config({
      getContainer: () => container || document.body, // 确保 message 在正确的容器中
    });

    try {
      // 模拟请求
      await simulateRequest(index, checked);

      // 更新开关状态
      const newSwitchStates = [...switchStates];
      newSwitchStates[index] = checked;
      setSwitchStates(newSwitchStates);

      // 计算新的开启数量
      const newOpenedCount = newSwitchStates.filter(state => state).length;

      // 调用回调函数，将新的开启数量传递给父组件
      if (onStateChange) {
        onStateChange(newOpenedCount);
      }

      // 提示操作成功
      message.success(`${index + 1}#${label}  已${checked ? '开启' : '关闭'}`);
    } catch (error) {
      // 提示操作失败
      message.error('操作失败，请重试');
    } finally {
      // 设置当前开关的加载状态为 false
      newLoadingStates[index] = false;
      setLoadingStates(newLoadingStates);
    }
  };

  return (
    <>
      {Array.from({ length: totalCount }).map((_, index) => (
        <div key={index}>
          {index + 1}#{label}
          <PopConfirm
            title={`确认${switchStates[index] ? '关闭' : '开启'}${index + 1}#${label}吗？`}
            onConfirm={() => handleSwitchChange(index, !switchStates[index])}
            okText="确认"
            cancelText="取消"
            getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
          >
            <Switch size="small" checked={switchStates[index]} loading={loadingStates[index]} />
          </PopConfirm>
        </div>
      ))}
    </>
  );
};

export default SwitchControl;
