import { useEffect, useState } from 'react';
import { Form, Input, Modal, Select, message } from '@maxtropy/components';
import { Spin } from 'antd';
import {
  apiV2GasPreparationStationDeviceListPost,
  apiV2GasPreparationStationNodeAddPost,
  apiV2GasPreparationStationNodeEditPost,
  apiV2GasPreparationStationNodeGetPost,
  V2GasPreparationStationDeviceListPostRequest,
} from '@maxtropy/device-customer-apis-v2';
import { DeviceType, NodeType, NodeTypeDisplay, OperationType } from '../../utils';
import { ModalProp } from '..';

export type NodeTypeTs = V2GasPreparationStationDeviceListPostRequest['nodeType'];
type DeviceListIdsTs = Record<NodeType, DeviceType[]>;

const DeviceListIds: DeviceListIdsTs = {
  [NodeType.COMPRESSOR]: [
    DeviceType.ElectricityMeter,
    DeviceType.Flowmeter,
    DeviceType.PressureGauge,
    DeviceType.Thermometer,
  ],
  [NodeType.NITROGEN_GENERATOR]: [
    DeviceType.ElectricityMeter,
    DeviceType.Flowmeter,
    DeviceType.PurityMonitoringInstrument,
  ],
  [NodeType.GAS_TANK]: [DeviceType.PressureGauge],
  [NodeType.STATION_OUTLET]: [DeviceType.Flowmeter, DeviceType.PressureGauge],
  [NodeType.GAS_MANIFOLD]: [],
  [NodeType.THERMOMETER]: [DeviceType.Thermometer],
  [NodeType.REFRIGERATOR]: [
    DeviceType.ElectricityMeter,
    DeviceType.Flowmeter,
    DeviceType.PressureGauge,
    DeviceType.Thermometer,
    DeviceType.DewPointThermometer,
  ],
  [NodeType.FLOW_METER]: [DeviceType.Flowmeter],
  [NodeType.PRESSURE_GAUGE]: [DeviceType.PressureGauge],
  [NodeType.VALVE]: [],
};

const FormItem = Form.Item;

const NodeModal = ({ onCancel, onOk, operationType, selectedNode, stationData, uetId }: ModalProp) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const [type, setType] = useState<number>();
  const [deviceListMap, setDeviceListMap] = useState<Record<number, any>>();
  const nodeType = selectedNode?.getData()?.type as NodeType;

  useEffect(() => {
    if (operationType === OperationType.EDIT) {
      const { selfId, type } = selectedNode.getData();
      setLoading(true);
      apiV2GasPreparationStationNodeGetPost({ nodeType: type, nodeId: selfId })
        .then(res => {
          form.setFieldsValue({
            code: res?.code,
            name: res?.name,
            electricityMeterId: res?.electricityMeterId,
            flowMeterId: res?.flowMeterId,
            pressureGaugeId: res?.pressureGaugeId,
            thermometerId: res?.thermometerId,
            dewPointThermometerId: res?.dewPointThermometerId,
            id: res?.id,
          });
          setId(res?.id);
          setType(type);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, operationType]);

  useEffect(() => {
    if ((operationType === OperationType.EDIT && id) || operationType === OperationType.ADD) {
      apiV2GasPreparationStationDeviceListPost({
        nodeId: id,
        nodeType: type as NodeType,
        uetId,
        stationId: stationData?.stationId,
        stationType: stationData?.stationType,
        deviceType: DeviceListIds[nodeType],
      }).then(res => {
        const listMap = {} as Record<number, any>;

        DeviceListIds[nodeType].forEach(i => {
          console.log('item', i);
          listMap[i] = (res?.list?.find(item => item.deviceType === i)?.deviceBasicDtoList ?? []).map(item => ({
            label: item.name,
            value: item.id,
          }));
        });

        setDeviceListMap(listMap);
      });
    }
  }, [stationData, uetId, id, type, operationType]);

  const handleForm = () => {
    form.validateFields().then(() => {
      const values = {
        ...form.getFieldsValue(true),
        canvasId: selectedNode?.id,
        stationId: stationData?.stationId,
        stationCode: stationData?.stationCode,
        nodeType,
        stationType: stationData?.stationType,
      };

      setConfirmLoading(true);
      if (operationType === OperationType.ADD) {
        apiV2GasPreparationStationNodeAddPost(values)
          .then(res => {
            message.success('新增成功');
            onOk({ ...values, selfId: res.id });
          })
          .finally(() => setConfirmLoading(false));
      }

      if (operationType === OperationType.EDIT) {
        apiV2GasPreparationStationNodeEditPost({ ...values, nodeId: values.id })
          .then(() => {
            message.success('编辑成功');
            onOk({ ...values, selfId: values.id });
          })
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  return (
    <Modal
      title={`${NodeTypeDisplay[nodeType!]}节点`}
      contentClassName="modal-form-content"
      open
      bodyScroll
      onCancel={onCancel}
      onOk={handleForm}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          {operationType === OperationType.EDIT && (
            <FormItem label="节点编号" name="code">
              <Input placeholder="请输入" disabled />
            </FormItem>
          )}
          <FormItem
            label="节点名称"
            name="name"
            rules={[
              { required: true, message: '请输入节点名称' },
              { min: 2, message: '最少输入2个字符' },
              { max: 10, message: '最多输入10个字符' },
            ]}
          >
            <Input placeholder="请输入节点名称" allowClear />
          </FormItem>
          {DeviceListIds[nodeType].includes(DeviceType.ElectricityMeter) && (
            <FormItem label="计量电表" name="electricityMeterId">
              <Select
                placeholder="请选择"
                options={deviceListMap?.[DeviceType.ElectricityMeter] ?? []}
                showSearch
                allowClear
                optionFilterProp="label"
              />
            </FormItem>
          )}
          {DeviceListIds[nodeType].includes(DeviceType.Flowmeter) && (
            <FormItem label="流量计" name="flowMeterId">
              <Select
                placeholder="请选择"
                options={deviceListMap?.[DeviceType.Flowmeter] ?? []}
                showSearch
                allowClear
                optionFilterProp="label"
              />
            </FormItem>
          )}
          {DeviceListIds[nodeType].includes(DeviceType.PressureGauge) && (
            <FormItem label="压力表" name="pressureGaugeId">
              <Select
                placeholder="请选择"
                options={deviceListMap?.[DeviceType.PressureGauge] ?? []}
                showSearch
                allowClear
                optionFilterProp="label"
              />
            </FormItem>
          )}
          {DeviceListIds[nodeType].includes(DeviceType.Thermometer) && (
            <FormItem label="温度计" name="thermometerId">
              <Select
                placeholder="请选择"
                options={deviceListMap?.[DeviceType.Thermometer] ?? []}
                showSearch
                allowClear
                optionFilterProp="label"
              />
            </FormItem>
          )}
          {DeviceListIds[nodeType].includes(DeviceType.DewPointThermometer) && (
            <FormItem label="露点温度计" name="dewPointThermometerId">
              <Select
                placeholder="请选择"
                options={deviceListMap?.[DeviceType.DewPointThermometer] ?? []}
                showSearch
                allowClear
                optionFilterProp="label"
              />
            </FormItem>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default NodeModal;
