import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import { useBreadcrumbRoutes, Wrapper, FormTitle, DatePicker, Table, EllipsisSpan, Button } from '@maxtropy/components';
import CardBox from './CardBox';
import { getPieChartOption } from './chart';
import dayjs from 'dayjs';
import { Spin } from 'antd';

const colors = ['rgba(230,66,66,0.2)', 'rgba(250,173,20,0.2)', 'rgba(74,144,226,0.2)', 'rgba(255,255,255,0.1)'];
const textColor = ['#E64242', '#FAAD14', '#4A90E2', 'rgba(255,255,255,0.85)'];

export const faultColumns = [
  {
    title: '排名',
    dataIndex: 'order',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: string, row: any, index: number) => {
      const colorIndex = index >= 3 ? 3 : index;
      return (
        <div
          style={{
            backgroundColor: colors[colorIndex],
            color: textColor[colorIndex],
            width: 20,
            height: 20,
            textAlign: 'center',
          }}
        >
          {index + 1}
        </div>
      );
    },
  },
  {
    title: '能源工质',
    dataIndex: 'energyMedium',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '故障类型',
    dataIndex: 'failType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '占比',
    dataIndex: 'percent',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '环比',
    dataIndex: 'chain',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          <span style={{ color: v < 0 ? '#52C41A' : '#E64242' }}>
            {v > 0 ? '+' : ''}
            {v}%
          </span>
        }
      />
    ),
  },
];

export const areaColumns = [
  {
    title: '排名',
    dataIndex: 'order',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: string, row: any, index: number) => {
      const colorIndex = index >= 3 ? 3 : index;
      return (
        <div
          style={{
            backgroundColor: colors[colorIndex],
            color: textColor[colorIndex],
            width: 20,
            height: 20,
            textAlign: 'center',
          }}
        >
          {index + 1}
        </div>
      );
    },
  },
  {
    title: '区域名称',
    dataIndex: 'areaName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '故障类型',
    dataIndex: 'failType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '占比',
    dataIndex: 'percent',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '环比',
    dataIndex: 'chain',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          <span style={{ color: v < 0 ? '#52C41A' : '#E64242' }}>
            {v > 0 ? '+' : ''}
            {v}%
          </span>
        }
      />
    ),
  },
];

const TicketAnalysis: React.FC = () => {
  const navigate = useNavigate();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any[]>([]);
  const [faultData, setFaultData] = useState<any[]>([]);
  const [areaData, setAreaData] = useState<any[]>([]);
  const [areaChartData, setAreaChartData] = useState<any[]>([]);
  const [month, setMonth] = useState<string>('202503');

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const mockNum = 36;
      // @ts-ignore
      const mockData = [
        {
          month: '202502',
          chartData: [
            { name: '电', num: 25 },
            { name: '水', num: 17 },
            { name: '二氧化碳', num: 6 },
            { name: '压缩空气', num: 5 },
            { name: '氧气', num: 4 },
            { name: '氮气', num: 3 },
            { name: '氩弧气', num: 3 },
          ],
          areaChartData: [
            { name: '平直中心', num: 13 },
            { name: '平台', num: 13 },
            { name: '管子中心', num: 10 },
            { name: '能源站房', num: 6 },
            { name: '涂装车间', num: 7 },
            { name: '曲面中心', num: 7 },
            { name: '薄板车间', num: 3 },
            { name: '切割车间', num: 4 },
          ],
          areaData: [
            { order: 1, areaName: '涂装1期', failType: '压缩空气', percent: '5.59%', chain: 13 },
            { order: 2, areaName: '涂装2期', failType: '压缩空气', percent: '5.03%', chain: -8 },
            { order: 3, areaName: '涂装3期', failType: '压缩空气', percent: '4.83%', chain: 21 },
            { order: 4, areaName: '管子中心A', failType: '电', percent: '3.76%', chain: 27 },
            { order: 5, areaName: '1#平台', failType: '电', percent: '2.15%', chain: 12 },
            { order: 6, areaName: '4#配电房', failType: '电', percent: '1.22%', chain: 9 },
          ],
          faultData: [
            { order: 1, energyMedium: '电', failType: '电压波动', percent: '3.91%', chain: 27 },
            { order: 2, energyMedium: '电', failType: '电流过载', percent: '3.52%', chain: 14 },
            { order: 3, energyMedium: '电', failType: '电压过高', percent: '3.38%', chain: 19 },
            { order: 4, energyMedium: '压缩空气', failType: '压力不足', percent: '2.26%', chain: 5 },
            { order: 5, energyMedium: '电', failType: '三相不平衡', percent: '1.50%', chain: 10 },
          ],
        },
        {
          month: '202503',
          chartData: [
            { name: '水', num: 12 },
            { name: '电', num: 19 },
            { name: '压缩空气', num: 3 },
            { name: '氧气', num: 5 },
            { name: '二氧化碳', num: 4 },
            { name: '氮气', num: 2 },
            { name: '氩弧气', num: 7 },
          ],
          areaChartData: [
            { name: '管子中心', num: 10 },
            { name: '能源站房', num: 7 },
            { name: '涂装车间', num: 2 },
            { name: '平直中心', num: 13 },
            { name: '曲面中心', num: 8 },
            { name: '平台', num: 6 },
            { name: '薄板车间', num: 4 },
            { name: '切割车间', num: 2 },
          ],
          areaData: [
            { order: 1, areaName: '涂装1期', failType: '压缩空气', percent: '8.25%', chain: 2 },
            { order: 2, areaName: '涂装2期', failType: '压缩空气', percent: '7.43%', chain: 14 },
            { order: 3, areaName: '涂装3期', failType: '压缩空气', percent: '7.13%', chain: 25 },
            { order: 4, areaName: '管子中心A', failType: '电', percent: '5.56%', chain: 10 },
            { order: 5, areaName: '1#平台', failType: '电', percent: '3.17%', chain: 3 },
            { order: 6, areaName: '4#配电房', failType: '电', percent: '1.81%', chain: -8 },
          ],
          faultData: [
            { order: 1, energyMedium: '电', failType: '电压波动', percent: '1.57%', chain: -7 },
            { order: 2, energyMedium: '电', failType: '电流过载', percent: '1.42%', chain: -8 },
            { order: 3, energyMedium: '电', failType: '电压过高', percent: '1.36%', chain: 13 },
            { order: 4, energyMedium: '压缩空气', failType: '压力不足', percent: '1.06%', chain: 28 },
            { order: 5, energyMedium: '电', failType: '三相不平衡', percent: '0.60%', chain: -2 },
          ],
        },
      ];
      // const mockData = JSON.parse(window?.ticketAnalysisData ?? '[]');

      const currentMontnData = mockData.find((m: any) => m.month === month);
      if (currentMontnData) {
        setChartData(currentMontnData.chartData);
        setAreaChartData(currentMontnData.areaChartData);
        setAreaData(currentMontnData.areaData);
        setFaultData(currentMontnData.faultData);
      } else {
        setChartData([]);
        setAreaChartData([]);
        setAreaData([]);
        setFaultData([]);
      }
      setLoading(false);
    }, 300);
  }, [month]);

  const monthChange = (e: any) => {
    const month = dayjs(e).format('YYYYMM');
    setMonth(month);
  };

  // 饼图
  const chartPieOption = useMemo(() => getPieChartOption(chartData), [chartData]);
  const areaChartPieOption = useMemo(() => getPieChartOption(areaChartData), [areaChartData]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <FormTitle
        title="能源工单分析"
        style={{ marginBottom: 24 }}
        extraContent={
          <div style={{ display: 'flex', marginLeft: 16, flex: 1, justifyContent: 'space-between' }}>
            <DatePicker
              picker="month"
              value={dayjs(month)}
              onChange={monthChange}
              placeholder="请选择"
              style={{ width: '200px' }}
            />
            <div style={{ display: 'flex', columnGap: 8 }}>
              <Button
                type="primary"
                onClick={() => {
                  window.open(
                    // @ts-ignore
                    `${window.WORKORDERORIGIN}/workOrder/repairWorkOrder/workOrderList`,
                    '_blank'
                  );
                }}
              >
                前往工单列表
              </Button>
              <Button type="primary" onClick={() => window.open('/device/alarm/record', '_blank')}>
                查看故障
              </Button>
            </div>
          </div>
        }
      />
      <Spin spinning={loading}>
        <div style={{ display: 'flex', columnGap: 32 }}>
          <CardBox title="报警工单所涉及的能源介质类型分布">
            <div style={{ backgroundColor: 'rgba(255,255,255,0.05)' }}>
              <ReactEcharts style={{ height: 240 }} option={chartPieOption} notMerge lazyUpdate={false} />
            </div>
          </CardBox>
          <CardBox style={{ width: '50%' }} title="报警工单高频故障排名">
            <Table
              rowKey="order"
              sticky
              scroll={{ y: 220 }}
              size="small"
              dataSource={faultData}
              columns={faultColumns}
              pagination={false}
            />
          </CardBox>
        </div>
        <div style={{ display: 'flex', columnGap: 32 }}>
          <CardBox title="报警工单作业区域分布">
            <div style={{ backgroundColor: 'rgba(255,255,255,0.05)' }}>
              <ReactEcharts style={{ height: 260 }} option={areaChartPieOption} notMerge lazyUpdate={false} />
            </div>
          </CardBox>
          <CardBox style={{ width: '50%' }} title="报警工单高频作业区排名">
            <Table
              rowKey="order"
              sticky
              size="small"
              scroll={{ y: 220 }}
              dataSource={areaData}
              columns={areaColumns}
              pagination={false}
            />
          </CardBox>
        </div>
      </Spin>
    </Wrapper>
  );
};

export default TicketAnalysis;
