import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';

export interface CarbonAccountUnitsSearchParams extends PageRequest {
  name?: string;
}

// 核算指南列表
export interface CarbonAccountGuideListRes {
  id: Key;
  industryId: Key; // 行业id
  industryName: string; // 行业Name
  standard: string; // 指南标准
  issuingInstitution: string; // 颁发机构
  issuingDate: string; // 输入日期
  state: number; // 状态(0:草稿 1:启用 2:禁用)
  createTime: string;
  updateTime: string;
}

export const getAccountGuideList = () =>
  fetch<{ list: CarbonAccountGuideListRes[] }>(
    '/api/v2/carbon/accounting-unit/guide-list',
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

export interface CarbonAccountUnitsPageRes {
  id: number;
  subjectName: string; // 主体
  unitName: string; // 单元名称
  accountingGuideId: Key; // 核算指南id
  ouIds: Key[]; // ouIds
  startMonth: number; // 财年起始月
  industryName: string; // 行业Name
  standard: string; // 指南标准
  updateTime: string;
}

// 删除
export const delAccountUnit = (id: Key) =>
  fetch(
    `/api/v2/carbon/accounting-unit/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 详情
export const getAccountUnitDetailById = (id: Key) =>
  fetch<CarbonAccountUnitsPageRes>(
    `/api/v2/carbon/accounting-unit/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 新增编辑
export interface addAccountUnitBody {
  id?: number;
  subjectName?: string; // 主体
  unitName: string; // 单元名称
  accountingGuideId: number; // 核算指南id
  ouIds: number[];
  startMonth: number; // 财年起始月
}

export interface AccountUnitListItem {
  id: Key;
  unitName: string;
}

// 列表
export const getAccountUnitList = () =>
  fetch<{ list: AccountUnitListItem[] }>(
    `/api/v2/carbon/accounting-unit/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

export const getmeterTypeList = (params: getmeterTypeListItem) =>
  fetch(
    `/api/v2/energy-consumption-evaluation-analysis-unit/meterTypeList`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface getmeterTypeListItem {
  ids: number[];
}
