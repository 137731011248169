import { Button, DatePicker, Empty, Radio } from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, DatePickerProps, Progress, ProgressProps, RadioChangeEvent, Row, Spin } from 'antd';
import classNames from 'classnames';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import BeatLineChart from '../BeatLineChart.tsx';
import { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  apiV2ProductionRhythmConfigDetailPost,
  apiV2ProductionRhythmOverviewGetDataPost,
  apiV2ProductionRhythmOverviewGetIntelligentDataPost,
  V2ProductionRhythmOverviewGetDataPostResponse,
  V2ProductionRhythmOverviewGetIntelligentDataPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { StatusType, TaktMode, TimeGranularityType } from '@/pages/ProductionBeatConfig/utils';
import { isNil } from 'lodash-es';
import WaveFormChart from '../WaveFormChart.tsx';
import { useNavigate } from 'react-router';

const RangePicker = DatePicker.RangePicker;

const normalTwoColors: ProgressProps['strokeColor'] = {
  '0%': '#92ED79',
  '100%': '#59D744',
};

const abnormalTwoColors: ProgressProps['strokeColor'] = {
  '0%': '#FF8487',
  '100%': '#FF4D4F',
};

interface RightContentProps {
  appAnalysisSubscriptionId?: number | null;
  hasConfig: boolean;
  setHasConfig: (v: boolean) => void;
}

const Date_Format = 'YYYY-MM-DD';

export type CharacteristicsDataList = V2ProductionRhythmOverviewGetDataPostResponse['characteristicsData'];
export type BgDataList = V2ProductionRhythmOverviewGetDataPostResponse['bgData'];
export type RhythmHoursVO = V2ProductionRhythmOverviewGetDataPostResponse['rhythmHours'];

export type LineChartItem = Exclude<CharacteristicsDataList, undefined>[number];
export type WaveFormChartItem = Exclude<
  V2ProductionRhythmOverviewGetIntelligentDataPostResponse['characteristicsData'],
  undefined
>[number];
export type StandardDataItem = Exclude<
  V2ProductionRhythmOverviewGetIntelligentDataPostResponse['standardData'],
  undefined
>[number];

export type LineChartBackgroundItem = Exclude<BgDataList, undefined>[number];

const RightContent: React.FC<RightContentProps> = props => {
  const { appAnalysisSubscriptionId, setHasConfig, hasConfig } = props;
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>();
  // 节拍次数
  const [beatNum, setBeatNum] = useState<number>(0);
  // 正常节拍次数
  const [normalBeatNum, setNormalBeatNum] = useState<number>(0);
  // 工时
  const [rhythmHours, setRhythmHours] = useState<RhythmHoursVO>();
  // 时间颗粒度
  const [timeResolution, setTimeResolution] = useState<TimeGranularityType>(TimeGranularityType.MINUTE);
  // 日期
  const [dates, setDates] = useState<Dayjs[]>([]);
  // 折线图
  const [lineChartData, setLineChartData] = useState<LineChartItem[]>([]);
  // 标准用能曲线
  const [standardData, setStandardData] = useState<StandardDataItem[]>([]);
  // 异常节拍占比报警率（百分比）
  const [abnormalBeatAlarmRate, setAbnormalBeatAlarmRate] = useState<number>(0);
  // 非工作时常占比报警率（百分比）
  const [nonWorkingAlarmRate, setNonWorkingAlarmRate] = useState<number>(0);
  // 异常间隔数量
  const [abnormalIntervalNum, setAbnormalIntervalNum] = useState<number>(0);
  // 折线背景图
  const [lineChartBackground, setLineChartBackground] = useState<LineChartBackgroundItem[]>([]);
  // 单位
  const [generalName, setGeneralName] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [rhythmRatio, setRhythmRatio] = useState<number>(0); // 节拍时长占比
  const [waitRatio, setWaitRatio] = useState<number>(0); // 待机时长占比
  const [stopRatio, setStopRatio] = useState<number>(0); // 停机时长占比
  const [type, setType] = useState<TaktMode>();

  useEffect(() => {
    if (appAnalysisSubscriptionId) {
      // 配置信息
      apiV2ProductionRhythmConfigDetailPost({ id: appAnalysisSubscriptionId }).then(res => {
        if (isNil(res)) {
          setHasConfig(false);
          return;
        }
        setType(res.type ?? TaktMode.FOLLOW_WAVEFORM);
        setHasConfig(true);
        setTimeResolution((res.timeResolution as TimeGranularityType) ?? TimeGranularityType.MINUTE);
        setAbnormalBeatAlarmRate(res.abnormalBeatAlarmRate as number);
        setNonWorkingAlarmRate(res.nonWorkingAlarmRate as number);
        setGeneralName(res.generalName ?? '');
        const current = dayjs(); // 当天
        const before15 = dayjs().subtract(14, 'day'); // 近15天
        const before31 = dayjs().subtract(30, 'day'); // 近31天
        const date =
          res.timeResolution === TimeGranularityType.MINUTE
            ? [current, current]
            : res.timeResolution === TimeGranularityType.HOUR
            ? [before15, current]
            : [before31, current];
        setDates(date);
      });
    }
  }, [appAnalysisSubscriptionId, setHasConfig]);

  useEffect(() => {
    if (appAnalysisSubscriptionId && dates.length === 2 && hasConfig) {
      setLoading(true);
      const body = {
        appAnalysisSubscriptionId,
        startDate: dates[0].format(Date_Format),
        endDate: dates[1].format(Date_Format),
      };

      if (type === TaktMode.FOLLOW_WAVEFORM) {
        // 智能识别波形图
        apiV2ProductionRhythmOverviewGetIntelligentDataPost(body)
          .then(res => {
            setBeatNum((res.normalRhythmNumber ?? 0) + (res.abnormalRhythmNumber ?? 0));
            setNormalBeatNum(res.normalRhythmNumber ?? 0);
            setRhythmHours(res.rhythmHours);
            setRhythmRatio(res.rhythmHours?.rhythmRatio ?? 0);
            setWaitRatio(res.rhythmHours?.statusHours?.find(i => i?.status === StatusType.WAIT)?.statusRatio ?? 0);
            setStopRatio(res.rhythmHours?.statusHours?.find(i => i?.status === StatusType.STOP)?.statusRatio ?? 0);
            // 折线图
            setLineChartData(res.characteristicsData ?? []);
            // 标准用能曲线
            setStandardData(res.standardData ?? []);
            // 折线背景图
            setLineChartBackground(res.bgData ?? []);

            setAbnormalIntervalNum((res.bgData ?? []).filter(i => i.type === 1 && i.abnormalFlag).length);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        // 获取页面数据（折线图/背景图）
        apiV2ProductionRhythmOverviewGetDataPost(body)
          .then(res => {
            setBeatNum((res.normalRhythmNumber ?? 0) + (res.abnormalRhythmNumber ?? 0));
            setNormalBeatNum(res.normalRhythmNumber ?? 0);
            setRhythmHours(res.rhythmHours);
            setRhythmRatio(res.rhythmHours?.rhythmRatio ?? 0);
            setWaitRatio(res.rhythmHours?.statusHours?.find(i => i?.status === StatusType.WAIT)?.statusRatio ?? 0);
            setStopRatio(res.rhythmHours?.statusHours?.find(i => i?.status === StatusType.STOP)?.statusRatio ?? 0);
            // 折线图
            setLineChartData(res.characteristicsData ?? []);
            // 折线背景图
            setLineChartBackground(res.bgData ?? []);

            setAbnormalIntervalNum((res.bgData ?? []).filter(i => i.type === 1 && i.abnormalFlag).length);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [appAnalysisSubscriptionId, dates, hasConfig, type]);

  const onTimeResolutionChange = (e: RadioChangeEvent) => {
    setTimeResolution(e.target.value);
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (from) {
      return timeResolution === TimeGranularityType.MINUTE
        ? Math.abs(current.diff(from, 'days')) >= 3
        : timeResolution === TimeGranularityType.HOUR
        ? Math.abs(current.diff(from, 'days')) >= 15
        : Math.abs(current.diff(from, 'days')) >= 31;
    }
    return false;
  };

  return (
    <Spin spinning={loading}>
      <div
        ref={e => {
          if (e) ref.current = e;
        }}
        className={styles.rightContent}
      >
        {hasConfig ? (
          <>
            <div style={{ display: 'flex' }}>
              <Radio.Group buttonStyle="solid" onChange={onTimeResolutionChange} value={timeResolution}>
                {timeResolution === TimeGranularityType.MINUTE && (
                  <Radio.Button value={TimeGranularityType.MINUTE}>1分钟</Radio.Button>
                )}
                {timeResolution === TimeGranularityType.HOUR && (
                  <Radio.Button value={TimeGranularityType.HOUR}>按小时</Radio.Button>
                )}
                {timeResolution === TimeGranularityType.DAY && (
                  <Radio.Button value={TimeGranularityType.DAY}>按日</Radio.Button>
                )}
              </Radio.Group>
              <div style={{ marginLeft: 16, flex: 1 }}>
                <RangePicker
                  value={dates as [Dayjs, Dayjs]}
                  onChange={v => {
                    if (v && v?.[0] && v?.[1]) {
                      setDates([v[0], v[1]]);
                    }
                  }}
                  style={{ width: 400 }}
                  disabledDate={disabledDate}
                  allowClear={false}
                />
              </div>
              <Button
                type="primary"
                onClick={() => {
                  navigate('/energy/runLogRule/config');
                }}
              >
                报警设置
              </Button>
            </div>

            <div className={styles.progressContent}>
              <Row gutter={16}>
                <Col span={7}>
                  <Row className={styles.progressWrapper}>
                    <Col span={8}>
                      <Progress
                        type="circle"
                        percent={beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100}
                        strokeWidth={11}
                        size={108}
                        strokeColor={
                          (beatNum === 0 ? 0 : ((beatNum - normalBeatNum) / beatNum) * 100 > abnormalBeatAlarmRate)
                            ? abnormalTwoColors
                            : normalTwoColors
                        }
                        format={() => (
                          <div className={styles.progressCircleStyle}>
                            <div className={styles.progressCircleInlineStyle}>
                              <p className={styles.firstLine}>{beatNum}</p>
                              <p className={styles.secondLine}>节拍总数</p>
                            </div>
                          </div>
                        )}
                      />
                    </Col>
                    <Col span={16}>
                      <div className={styles.wordDescription}>
                        <div className={styles.firstLine}>
                          <p>正常节拍</p>
                          <p
                            className={classNames(
                              styles.numStyle,
                              (beatNum === 0 ? 0 : ((beatNum - normalBeatNum) / beatNum) * 100 > abnormalBeatAlarmRate)
                                ? styles.numStyleErr
                                : undefined
                            )}
                          >
                            {normalBeatNum}
                          </p>
                          <p
                            className={classNames(
                              styles.numStyle,
                              (beatNum === 0 ? 0 : ((beatNum - normalBeatNum) / beatNum) * 100 > abnormalBeatAlarmRate)
                                ? styles.numStyleErr
                                : undefined
                            )}
                          >
                            {beatNum === 0 ? 0 : ((normalBeatNum / beatNum) * 100).toFixed(2) + '%'}
                          </p>
                        </div>
                        <div className={classNames(styles.firstLine, styles.secondLine)}>
                          <p>异常节拍</p>
                          <p
                            className={classNames(
                              styles.numStyle,
                              (beatNum === 0 ? 0 : ((beatNum - normalBeatNum) / beatNum) * 100 > abnormalBeatAlarmRate)
                                ? styles.numStyleErr
                                : undefined
                            )}
                          >
                            {beatNum - normalBeatNum}
                          </p>
                          <p
                            className={classNames(
                              styles.numStyle,
                              (beatNum === 0 ? 0 : ((beatNum - normalBeatNum) / beatNum) * 100 > abnormalBeatAlarmRate)
                                ? styles.numStyleErr
                                : undefined
                            )}
                          >
                            {beatNum === 0 ? 0 : (((beatNum - normalBeatNum) / beatNum) * 100).toFixed(2) + '%'}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={17}>
                  <div className={styles.progressWrapper}>
                    <div className={styles.timeAndInfo}>
                      <p className={classNames(styles.ellipsisP, styles.timeStyle)}>
                        <span className={styles.word}>工时</span>
                        <span className={styles.time}>{(rhythmHours?.totalMin ?? 0).toFixed(0)}</span>
                        <span className={styles.unit}>min</span>
                      </p>
                      {(stopRatio + waitRatio) * 100 < nonWorkingAlarmRate ? (
                        <div className={styles.info}>
                          <div
                            className={styles.infoCard}
                            style={abnormalIntervalNum > 0 ? { marginRight: 8 } : { marginRight: 0 }}
                          >
                            <p className={styles.ellipsisP}>
                              <CheckCircleFilled /> 生产时长正常，请继续保持！
                            </p>
                          </div>
                          {abnormalIntervalNum > 0 && (
                            <div className={styles.infoCardWorn}>
                              <p className={styles.ellipsisP}>
                                <ExclamationCircleFilled /> 共有{abnormalIntervalNum}个节拍间隔异常，请重点关注！
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className={styles.info}>
                          <div
                            className={styles.infoCardWorn}
                            style={abnormalIntervalNum > 0 ? { marginRight: 8 } : { marginRight: 0 }}
                          >
                            <p>
                              <ExclamationCircleFilled /> 非工作时间占比超过{nonWorkingAlarmRate}%，请重点关注！
                            </p>
                          </div>
                          {abnormalIntervalNum > 0 && (
                            <div className={styles.infoCardWorn}>
                              <p>
                                <ExclamationCircleFilled /> 共有{abnormalIntervalNum}个节拍间隔异常，请重点关注！
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.progressLine}>
                      <div
                        style={{
                          height: 8,
                          width: '100%',
                          borderRadius: 4,
                          background: `linear-gradient(to right, #51a1ff 0%, #51dbff ${rhythmRatio * 100}%, #FAAD14 ${
                            rhythmRatio * 100
                          }%, #FAAD14 ${(rhythmRatio + waitRatio) * 100}%,#5D7092 ${
                            (rhythmRatio + waitRatio) * 100
                          }%, #5D7092 ${(rhythmRatio + waitRatio + stopRatio) * 100}%, rgba(74,144,226,0.1) ${
                            (rhythmRatio + waitRatio + stopRatio) * 100
                          }%, rgba(74,144,226,0.1) 100%)`,
                        }}
                      />
                      <div className={styles.legendLine}>
                        <div className={styles.legend}>
                          <div
                            className={styles.legendColor}
                            style={{ background: `linear-gradient( 90deg, #51A1FF 0%, #51DBFF 100%)` }}
                          />
                          <p className={styles.legendWord}>
                            节拍时长
                            <span className={styles.time}>{(rhythmHours?.rhythmMin ?? 0).toFixed(0)}min</span>
                            <span className={styles.rate}>
                              {Number(((rhythmHours?.rhythmRatio ?? 0) * 100).toFixed(2))}%
                            </span>
                          </p>
                        </div>
                        {rhythmHours?.statusHours?.map(i => i.status).includes(StatusType.WAIT) && (
                          <div className={classNames(styles.legendBorder, styles.legend)}>
                            <div className={styles.legendColor} style={{ background: `#FAAD14` }} />
                            <p className={styles.legendWord}>
                              待机时长
                              <span className={styles.time}>
                                {(rhythmHours?.statusHours ?? []).find(i => i?.status === StatusType.WAIT)?.statusMin ??
                                  0}
                                min
                              </span>
                              <span className={styles.rate}>{Number((waitRatio * 100).toFixed(2))}%</span>
                            </p>
                          </div>
                        )}
                        {rhythmHours?.statusHours?.map(i => i.status).includes(StatusType.STOP) && (
                          <div className={styles.legend}>
                            <div className={styles.legendColor} style={{ background: `#5D7092` }} />
                            <p className={styles.legendWord}>
                              停机时长
                              <span className={styles.time}>
                                {(
                                  (rhythmHours?.statusHours ?? []).find(i => i?.status === StatusType.STOP)
                                    ?.statusMin ?? 0
                                ).toFixed(0)}
                                min
                              </span>
                              <span className={styles.rate}>{Number((stopRatio * 100).toFixed(2))}%</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {type === TaktMode.FOLLOW_WAVEFORM ? (
              <WaveFormChart
                lineChartData={lineChartData}
                standardData={standardData}
                lineChartBackground={lineChartBackground}
                generalName={generalName}
                timeResolution={timeResolution}
              />
            ) : (
              <BeatLineChart
                lineChartData={lineChartData}
                lineChartBackground={lineChartBackground}
                generalName={generalName}
                timeResolution={timeResolution}
              />
            )}
          </>
        ) : (
          <Empty
            style={{
              width: '100%',
              height: 'calc(100vh - 170px)',
              paddingTop: 300,
            }}
          />
        )}
      </div>
    </Spin>
  );
};

export default RightContent;
