import { Col, Row, Space, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Input,
  FormTitle,
  SubContent,
  Tabs,
  Select,
  InputNumber,
  message,
} from '@maxtropy/components';

import { apiV2CacheGetPost, apiV2CacheSavePost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
const { Option } = Select;

const RunLogRule: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [activeKey, setActiveKey] = useState<string>('1');
  const triggerType = Form.useWatch('triggerType', form);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [mockData, setMockData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apiV2CacheGetPost({ cacheKey: 'alarmRuleConfig' })
      .then(res => {
        if (!isNil(res.cacheValue)) {
          const data = JSON.parse(res.cacheValue);
          setMockData(data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    console.log('333', mockData);
    const current = mockData.find((m: any) => m.activeKey === activeKey);
    if (current) {
      form.setFieldsValue({ ...current });
    } else {
      form.setFieldsValue({
        triggerType: undefined,
        triggerCount: undefined,
        duration: undefined,
        charactSignal: undefined,
        jumpAmplitude: undefined,
      });
    }
  }, [mockData, activeKey]);

  const onFinish = async () => {
    const values = form.getFieldsValue();
    const oldIndex = mockData.findIndex(m => m.activeKey === activeKey);
    if (oldIndex > -1) {
      mockData.splice(oldIndex, 1, {
        activeKey,
        ...values,
      });
    } else {
      mockData.push({
        activeKey,
        ...values,
      });
    }

    setMockData(mockData);
    const cacheValue = JSON.stringify(mockData);
    setLoading(true);
    apiV2CacheSavePost({ cacheKey: 'alarmRuleConfig', cacheValue })
      .then(res => {
        message.success('规则设定成功');
        setIsEdit(false);
        navigate(`/energy/analysis/productionBeatView`);
      })
      .finally(() => setLoading(false));
  };

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}设备报警规则` }];
  }, [isEdit]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle title="异常运行日志报警规则设定" />

      <Spin spinning={loading}>
        <Form form={form} disabled={!isEdit}>
          <SubContent title="基础信息" className="mb-8">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="ruleName"
                  label="规则名称"
                  rules={[
                    { required: true, message: '请输入规则名称' },
                    { max: 20, message: '最多输入二十个字' },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="alarmContent"
                  label="报警信息"
                  rules={[
                    { required: true, message: '请输入报警信息' },
                    { max: 500, message: '最多输入500字' },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="报警等级" className="mb-8">
            <Tabs
              activeKey={activeKey}
              onChange={key => setActiveKey(key)}
              items={[
                {
                  key: '1',
                  label: '最高级',
                },
                {
                  key: '2',
                  label: '高级',
                },
                {
                  key: '3',
                  label: '中级',
                },
                {
                  key: '4',
                  label: '低级',
                },
                {
                  key: '5',
                  label: '最低级',
                },
              ]}
            />
            <Row>
              <Col span={8}>
                <Form.Item name="triggerType" label="触发类型">
                  <Select placeholder="请选择">
                    <Option value={'1'}>连续异常工艺节拍</Option>
                    <Option value={'2'}>一定时长范围内特征信号</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {triggerType === '1' ? (
              <Row>
                <Col span={8}>
                  <Form.Item label="触发阀值">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0, marginRight: 6, color: 'var(--mx-text-base-color)' }}>连续</span>
                      <Form.Item name="triggerCount" noStyle>
                        <InputNumber placeholder="请输入" addonAfter={'个'} />
                      </Form.Item>
                      <span style={{ flexShrink: 0, marginLeft: 6, color: 'var(--mx-text-base-color)' }}>节拍</span>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col span={8}>
                    <Form.Item label="时长">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ flexShrink: 0, marginRight: 6, color: 'var(--mx-text-base-color)' }}>
                          小于等于
                        </span>
                        <Form.Item name="duration" noStyle>
                          <InputNumber placeholder="请输入" addonAfter={'个'} />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item name="charactSignal" label="特征信号">
                      <Select placeholder="请选择">
                        <Option value={'1'}>跳变</Option>
                        <Option value={'2'}>故障码</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item name="jumpAmplitude" label="跳变变化幅度">
                      <InputNumber placeholder="请输入1-9999999" addonAfter={'kW'} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </SubContent>
        </Form>
      </Spin>

      <Space size={8} className="sticky-footer">
        {isEdit ? (
          <>
            <Button type="primary" onClick={onFinish}>
              保存
            </Button>
            <Button onClick={() => setIsEdit(false)}>取消</Button>
          </>
        ) : (
          <>
            <Button type="primary" onClick={() => setIsEdit(true)}>
              编辑
            </Button>
            <Button
              onClick={() => {
                navigate(`/energy/analysis/productionBeatView`);
              }}
            >
              返回
            </Button>
          </>
        )}
      </Space>
    </Wrapper>
  );
};

export default RunLogRule;
