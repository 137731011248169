export enum PermissionsType {
  // Iot配置
  PBASICSETTING = 'p-basicSetting',

  // 设备信息模板库
  PDEVICETEMPLATE = 'p-deviceTemplate',
  // 固定属性模版
  PATTRIBUTETEMPLATE = 'p-attributeTemplate',

  // 物联配置
  PIOTMANAGE = 'p-iotManage',
  // 网关管理
  PEDGEGATEWAYMANAGE = 'p-edgeGatewayManage',
  // 设备绑定页面
  PGATEWAYBINDDEVICE = 'p-gatewayBindDevice',
  // 新建网关页面
  PCREATEGATEWAY = 'p-createGateway',
  // 编辑网关页面
  PUPDATEGATEWAY = 'p-updateGateway',
  // 作废/恢复按钮
  BEDGEGATEWAYMANAGEDISABLE = 'b-edgeGatewayManageDisable',
  // 新建网关按钮
  B_CREATEGATEWAY = 'b-createGateway',
  // 配置下发按钮 下发记录按钮
  B_GATEWAYDELIVERYCONFIG = 'b-gatewayDeliveryConfig',
  //导出按钮
  B_EXPORTGATEWAYDEVICE = 'b-exportGatewayDevice',

  // 设备管理
  PDEVICEMANAGE = 'p-deviceManage',
  // 设备管理页面
  PDEVICEMANAGEMENT = 'p-deviceManagement',
  // 新建设备页面
  PCREATEDEVICE = 'p-createDevice',
  // 编辑资产信息页面
  PUPDATEDEVICEBASICINFO = 'p-updateDeviceBasicInfo',
  // 查看页面
  PDEVICEINFO = 'p-deviceInfo',
  // 添加/编辑固定信息
  PUPSERTDEVICEATTRIBUTEINFO = 'p-upsertDeviceAttributeInfo',
  // 添加/编辑数采信息页面
  PUPSERTDEVICEEDGEINFO = 'p-upsertDeviceEdgeInfo',
  // 状态变更操作按钮
  BDEVICEUPDATESTATUS = 'b-deviceUpdateStatus',
  // 批量配置固定属性按钮
  B_BATCHCONFIGATTRIBUTE = 'b-batchConfigAttribute',
  // 批量修改数采信息按钮
  B_BATCHCONFIGEDGEINFO = 'b-batchConfigEdgeInfo',

  // 批量新建任务
  PBATCHIMPORTDEVICE = 'p-batchImportDevice',
  // 批量新建任务新建导入模板页面
  PCREATEIMPORTTEMPLATE = 'p-createImportTemplate',
  // 批量新建任务编辑资产信息页面
  PUPDATEBATCHDEVICEBASICINFO = 'p-updateBatchDeviceBasicInfo',
  // 批量新建任务导入按钮
  BDEVICEIMPORTBUTTON = 'b-deviceImportButton',
  // 批量新建任务查看页面
  PBATCHDEVICEINFO = 'p-batchDeviceInfo',
  // 批量新建任务添加/编辑固定信息页面
  PUPSERTBATCHDEVICEATTRIBUTEINFO = 'p-upsertBatchDeviceAttributeInfo',
  // 批量新建任务添加/编辑数采信息页面
  PUPSERTBATCHDEVICEEDGEINFO = 'p-upsertBatchDeviceEdgeInfo',

  // 运营单元
  P_OU = 'p-ou',
  // 单元管理
  P_OU_MANAGE = 'p-ouManage',
  // 新建运营单元
  P_CREATE_OU = 'p-createOu',
  // 编辑运营单元
  P_UPDATE_OU = 'p-updateOu',
  // 单元设备管理
  P_OU_DEVICE_MANAGE = 'p-ouDeviceManage',
  // 新建运营单元按钮
  B_CREATE_OU = 'b-createOu',
  // 添加单位设备管理按钮
  B_CREATE_OU_DEVICE = 'b-createOuDevice',
  // 运营单元实施
  P_OU_IMPLEMENT = 'p-ouImplement',
  // 管理员调整
  P_OU_IMPLEMENT_MANAGER = 'p-ouImplementManager',
  // 添加管理员
  B_OU_IMPLEMENT_ADD_MANAGER = 'b-ouImplementAddManager',
  // 作废运营单元按钮
  B_ABOLISH_OU = 'b-abolishOu',

  // 数据中心
  P_DATACENTER = 'p-dataCenter',
  // 历史数据
  P_HISTROYDATA = 'p-histroyData',
  // 设备历史数据
  PDEVICEHISTORY = 'p-deviceHistory',
  // 报告中心
  P_ANALYSISREPORTMANAGE = 'p-analysisReportManage',
  // 报告查看
  P_ANALYSISREPORTMANAGEDETAIL = 'p-analysisReportManageDetail',

  // 报警规则
  P_ALARM_RULE = 'p-alarmRule',
  // 规则管理
  P_RULE_MANAGE = 'p-ruleManage',
  // 新建规则
  P_CREATE_RULE = 'p-createRule',
  // 新建规则组
  P_CREATE_RULE_GROUP = 'p-createRuleGroup',
  // 编辑规则
  P_UPDATE_RULE = 'p-updateRule',
  // 编辑规则组
  P_UPDATE_RULE_GROUP = 'p-updateRuleGroup',
  // 新建规则按钮
  B_CREATE_RULE = 'b-createRule',
  // 新建规则组按钮
  B_CREATE_RULE_GROUP = 'b-createRuleGroup',
  // 启用/禁用按钮
  B_RULE_AND_GROUP_ENABLE_DISABLE = 'b-ruleAndGroupEnableDisable',
  // 删除按钮
  B_RULE_AND_GROUP_DELETE = 'b-ruleAndGroupDelete',

  // 报警推送
  P_ALARM_PUSH = 'p-alarmPush',
  // 报警记录
  P_ALARM_LOG = 'p-alarmLog',

  // 推送管理
  P_ALARM_PUSH_STRATEGY_MANAGE = 'p-alarmPushStrategyManage',
  // 新建推送
  P_CREATE_ALARM_PUSH_STRATEGY = 'p-createAlarmPushStrategy',
  // 编辑推送
  P_UPDATE_ALARM_PUSH_STRATEGY = 'p-updateAlarmPushStrategy',

  //添加组态
  B_CREATESCADA = 'b-createScada',
  //编辑组态基本信息
  B_UPDATESCADA = 'b-updateScada',
  //复制组态
  B_COPYSCADA = 'b-copyScada',
  //导出组态
  B_EXPORTSCADA = 'b-exportScada',
  //删除组态
  B_DELETESCADA = 'b-deleteScada',
  //组态列表
  P_SCADALIST = 'p-scadaList',
  //编辑组态
  P_UPDATESCADA = 'p-updateScada',
  // 查看组态
  P_SCADA_INFO = 'p-scadaInfo',

  // 自定义报表
  P_CUSTOM_REPORT = 'p-customReport',
  // 报表策略
  P_REPORT_STRATEGY = 'p-reportStrategy',
  // 报表列表
  P_REPORT_LIST = 'p-reportRecord',
  // 新增报表策略
  P_CREATE_REPORT_STRATEGY = 'p-createReportStrategy',
  // 修改报表策略
  P_UPDATE_REPORT_STRATEGY = 'p-updateReportStrategy',
  // 复制、新建报表策略
  B_CREATE_REPORT_STRATEGY = 'b-createReportStrategy',
  // 编辑报表策略
  B_UPDATE_REPORT_STRATEGY = 'b-updateReportStrategy',
  // 删除报表策略
  B_DELETE_REPORT_STRATEGY = 'b-deleteReportStrategy',
  // 执行报表策略
  B_EXECUTE_REPORT_STRATEGY = 'b-executeReportStrategy',

  B_REPORT_RECORD_DOWNLOAD = 'b-reportRecordDownload',
  B_REPORT_RECORD_DELETE = 'b-reportRecordDelete',

  //全域能源管理
  P_UET = 'p-uet',
  //全域能源基础设置
  P_UETBASICSET = 'p-uetBasicSet',
  //全域能源拓扑管理
  P_UETMANAGE = 'p-uetManage',
  //查看UET
  P_UETINFO = 'p-uetInfo',
  //新建UET
  P_CREATEUET = 'p-createUet',
  //负载/生产线
  P_LOAD_PROD = 'p-load/prod',
  //储能阵列
  P_BSA = 'p-bsa',
  P_BSA_NEW = 'p-bsaNew',
  // 微网电能拓扑
  P_MICROGRIDPOWERTOPOLOGY = 'p-microgridPowerTopology',
  // 能源介质拓扑
  P_EMTMANAGE = 'p-emtManage',
  // 创建能源/工质拓扑
  B_CREATEEMT = 'b-createEmt',
  // 编辑能源介质拓扑
  B_UPDATEEMT = 'b-updateEmt',
  // 预览能源/工质拓扑
  B_EMTPREVIEW = 'b-emtPreview',
  // 查看能源/工质拓扑
  B_EMTINFO = 'b-emtInfo',

  //新建UET按钮
  B_CREATEUET = 'b-createUet',
  //查看UET按钮
  B_UETINFO = 'b-uetInfo',
  //编辑UET状态按钮
  B_UPDATEUETSTATUS = 'b-updateUetStatus',
  //编辑UET按钮
  B_UPDATEUET = 'b-updateUet',
  // 管线
  B_LINE_PIPE = 'b-linePipe',
  // 新增管线
  B_LINE_PIPE_ADD = 'b-linePipeAdd',
  // 移除管线
  B_LINE_PIPE_DELETE = 'b-linePipeDelete',

  //fcs列表
  P_FCSPAGE = 'p-fcsPage',
  //新建fcs
  P_CREATEFCS = 'p-createFcs',
  //修改fcs
  P_UPDATEFCS = 'p-updateFcs',
  //删除fcs
  P_DELETEFCS = 'p-deleteFcs',
  //新建fcs按钮
  B_CREATEFCS = 'b-createFcs',
  //修改fcs按钮
  B_UPDATEFCS = 'b-updateFcs',

  // 运营配置
  P_OPERATION_CONFIG = 'p-operationConfig',

  // 能源费率管理
  P_PRICE_RATE = 'p-priceRate',

  // 账户管理
  P_ACCOUNT_MANAGE = 'p-accountManage',

  // 新增用电账户
  B_ACCOUNT_ADD_CONFIG = 'b-accountAddConfig',
  // 用电账户设备管理
  B_DEVICE_MANAGE = 'b-deviceManage',
  // 编辑用电账户
  B_ACCOUNT_EDIT = 'b-accountEdit',
  // 作废用电账户
  B_ACCOUNT_VOID = 'b-accountVoid',

  // 用电账户
  B_ELECTRIC_ACCOUNT = 'b-electricAccount',
  // 用汽账户
  B_THERMAL_ACCOUNT = 'b-thermalAccount',
  // 新增用汽账户
  B_THERMAL_ACCOUNT_ADD = 'b-thermalAccountAdd',
  // 编辑用汽账户
  B_THERMAL_ACCOUNT_UPDATE = 'b-thermalAccountUpdate',
  // 用汽设备管理
  B_THERMAL_ACCOUNT_DEVICE = 'b-thermalAccountDevice',
  // 修改抄表逻辑
  B_MODIFY_METEREADING_DATATYPE = 'b-modifyMeterReadingDataType',

  // 合同管理
  P_CONTRACT_MANAGE = 'p-contractManage',

  // 新增合同
  B_CONTRACT_ADD = 'b-contractAdd',
  // 编辑合同
  B_CONTRACT_EDIT = 'b-contractEdit',
  // 作废合同
  B_CONTRACT_VOID = 'b-contractVoid',
  // 调整生效日期
  B_CONTRACT_DATE_EDIT = 'b-contractDateEdit',
  // 编辑费率
  B_PRICE_RATE_EDIT = 'b-priceRateEdit',

  // 用量统计
  P_ENERGY_CONSUMPTION = 'p-energyConsumption',

  // 客户相关
  P_CLIENT = 'p-client',

  // 客户管理
  P_CLIENT_MANAGE = 'p-clientManage',
  // 新增客户
  B_CLIENT_ADD = 'b-clientAdd',
  // 编辑客户
  B_CLIENT_UPDATE = 'b-clientUpdate',
  // 删除客户
  B_CLIENT_DELETE = 'b-clientDelete',

  // 客户账户
  P_CLIENT_ACCOUNT = 'p-clientAccount',
  // 新增客户账户
  B_CLIENT_ACCOUNT_ADD = 'b-clientAccountAdd',
  // 编辑客户账户
  B_CLIENT_ACCOUNT_UPDATE = 'b-clientAccountUpdate',
  // 冻结/恢复
  B_FREEZE_RESTORE = 'b-freezeRestore',
  // 充值/退款
  B_RECHARGE_REFUND = 'b-rechargeRefund',
  // 手动扣款
  B_MANUALDEDUCTION = 'b-manualDeduction',

  //  用电销售合同
  P_PROP_MANAGE_CONTRACT = 'p-propManageContract',

  // 用电销售合同列表
  P_PROP_MANAGE_CONTRACT_LIST = 'p-propManageContractList',

  // 新建/编辑/终止/延长有效期
  B_ELECSALECONADD = 'b-elecSaleConAdd',

  // 能源抄表
  P_METER_READING = 'p-meterReading',

  // 用电抄表记录
  P_ELECTRIC_METER_READING_LOG = 'p-electricMeterReadingLog',

  // 结算规则
  P_SETTLERULE = 'p-settleRule',
  // 电费对外结算规则
  P_ELECTRICEXTERNALRULE = 'p-electricExternalRule',
  // 新增电费结算规则
  B_ELECSETTLERULEADD = 'b-elecSettleRuleAdd',
  // 批量修改日志
  B_BATCHIMPORTLOG = 'b-batchImportLog',
  // 批量修改操作
  B_BATCHIMPORTACTION = 'b-batchImportAction',
  // 导出选中
  B_EXPORTSELECTED = 'b-exportSelected',
  // 导出全部
  B_EXPORTALL = 'b-exportAll',
  // 结算规则日志按钮
  B_CHANGELOGLATEST = 'b-changeLogLatest',
  // 结算规则日志页面
  P_CHANGELOGLATEST = 'p-changeLogLatest',
  // 结算规则日志详情
  B_CHANGELOGDETAIL = 'b-changeLogDetail',
  // 抄表导入
  B_METER_READING_IMPORT = 'b-meterReadingImport',
  // 导入日志
  B_IMPORT_LOGS = 'b-importLogs',

  // 核查联列表
  P_ELECTRIC_BILL_LIST = 'p-electricBillList',
  // 缴费
  P_ELECTRIC_BILL_PAY = 'p-electricBillPay',
  // 明细
  P_ELECTRIC_BILL_DETAIL = 'p-electricBilldetail',
  // 缴费按钮
  B_ELECTRIC_BILL_PAY = 'b-electricBillPay',
  // 查看抄表调整记录
  B_ADJUSTMENT_METER_RECORD = 'b-adjustmentMeterRecord',
  // 确认开票
  B_ELECTRICBILLINVOICECONFIRM = 'b-electricBillInvoiceConfirm',
  // 作废发票
  B_ELECTRIC_BILL_INVOICE_RESCIND = 'b-electricBillInvoiceRescind',
  // 重新复核
  B_ELECTRIC_BILL_RECHECK = 'b-electricBillRecheck',
  // 反记账
  B_ELECTRIC_BILL_ANTI_BOOKKEEPING = 'b-electricBillAnti-bookkeeping',
  // 手动生成核查联页面
  P_GENERATE_ELECTRIC_BILL_MANUAL = 'p-generateElectricBillManual',
  // 手动生成核查联按钮
  B_GENERATE_ELECTRIC_BILL_MANUAL = 'b-generateElectricBillManual',
  // 作废
  B_DISCARD_ELECTRIC_BILL = 'b-discardElectricBill',
  //手动重新生成
  B_REGENERATE_ELECTRIC_BILL_MANUAL = 'b-reGenerateElectricBillManual',
  // 余额报警
  P_BALANCESERVERALARM = 'p-balanceServerAlarm',
  // 报警管理
  P_BALANCESERVERALARMMANAGE = 'p-balanceServerAlarmManage',
  // 新建规则
  B_BALANCESERVERRULEADD = 'b-balanceServerRuleAdd',
  // 批量新建规则
  B_BALANCESERVERRULEBATCHADD = 'b-balanceServerRuleBatchAdd',
  // 编辑
  B_BALANCESERVERRULEDATE = 'b-balanceServerRuleUpdate',
  // 禁用/启用
  B_BALANCESERVERRULEENABLEDISABLE = 'b-balanceServerRuleEnableDisable',
  // 删除
  B_BALANCESERVERRULEDELETE = 'b-balanceServerRuleDelete',

  // 报警记录
  P_BALANCESERVERRECORD = 'p-balanceServerRecord',

  // 服务端报警推送
  P_SERVERALARMPUSH = 'p-serverAlarmPush',
  // 新建
  B_SERVERALARMPUSHADD = 'b-serverAlarmPushAdd',
  // 编辑
  B_SERVERALARMPUSHUPDATE = 'b-serverAlarmPushUpdate',
  // 删除
  B_SERVERALARMPUSHDELETE = 'b-serverAlarmPushDelete',
  // 禁用/启用
  B_SERVERALARMPUSHENABLEDISABLE = 'b-serverAlarmPushEnableDisable',
  // 光伏站(暂时定义)
  P_PV = 'p-pvStation',
  // 光伏站新增
  B_PV_STATION_ADD = 'b-pvStationAdd',
  // 编辑
  B_PV_STATION_EDIT = 'b-pvStationEdit',
  // 新增并网点
  B_PV_GRID_ADD = 'b-pvGridconnectionPointAdd',
  // 移除并网点
  B_PV_GRID_REMOVE = 'b-pvGridconnectionPointRemove',
  // 编辑计量表
  B_PV_METER_EDIT = 'b-pvMeterEdit',
  // 编辑并网点信息
  B_PV_NET_EDIT = 'b-pvParallelNetworkEdit',
  // 投放
  B_PV_DELIVER = 'b-pvDeliver',
  // 光伏站运维
  P_PV_OPERATION = 'p-pvOperation',
  // 光伏基础设置
  P_PV_STATION_BASIC_CONFIG = 'p-pvStationBasicConfig',
  // 光伏站管理
  P_PV_STATION_MANAGE = 'p-pvStationManage',
  // 启用
  B_PV_STATION_START = 'b-pvStationStart',
  // 停用
  B_PV_STATION_STOP = 'b-pvStationStop',
  // 编辑基础信息
  B_PV_BASIC_EDIT = 'b-pvBasicMessageEdit',
  // 编辑阵列信息
  B_PV_ARRAY_EDIT = 'b-pvArrayEdit',
  // 编辑高压设备
  B_PV_HIGH_VOLTAGE_EDIT = 'b-pvHighVoltageEdit',

  // 创建工单
  B_WORKER_ORDER_CREATE = 'b-workOrderCreate',

  // 发起工单
  B_SUBMIT_WORKER_ORDER = 'b-initiateworkorder',

  // 查看工单
  B_WORKER_ORDER_VIEW = 'b-workOrderDetail',
  // 报警工单
  P_QL_WORK_ALARM = 'p-qlWorkOrderAlarm',

  // 能耗分析
  P_ENERGY_CONSUMPTION_ANALYSIS = 'p-energyConsumptionAnalysis',
  // 单元配置
  P_ENERGY_CONSUMPTION_ANALYSIS_UNIT_CONF = 'p-energyConsumptionAnalysisUnitConf',
  // 能耗分析单元
  P_ENERGY_CONSUMPTION_ANALYSIS_UNIT = 'p-energyConsumptionAnalysisUnit',
  // 新建能耗分析单元
  P_ENERGY_CONSUMPTION_ANALYSIS_UNIT_CREATE = 'p-energyConsumptionAnalysisUnitCreate',
  // 新建能耗分析单元
  B_ENERGY_CONSUMPTION_ANALYSIS_UNIT_CREATE = 'b-energyConsumptionAnalysisUnitCreate',
  // 编辑能耗分析单元
  P_ENERGY_CONSUMPTION_ANALYSIS_UNIT_UPD = 'p-energyConsumptionAnalysisUnitUpd',
  // 编辑能耗分析单元
  B_ENERGY_CONSUMPTION_ANALYSIS_UNIT_UPD = 'b-energyConsumptionAnalysisUnitUpd',
  // 删除能耗分析单元
  B_ENERGY_CONSUMPTION_ANALYSIS_UNIT_DEL = 'b-energyConsumptionAnalysisUnitDel',
  // 能耗分析配置
  B_ENERGY_CONSUMPTION_ANALYSIS_CONF = 'b-energyConsumptionAnalysisConf',
  // 能耗分析配置
  P_ENERGY_CONSUMPTION_ANALYSIS_CONF = 'p-energyConsumptionAnalysisConf',
  // 设备导出
  B_DEVICE_OUTPUT = 'b-deviceOutput',

  // 组网列表
  P_NETWORK_LIST = 'p-networkList',
  // 新建组网
  P_NETWORK_NEW = 'p-networkNew',
  // 编辑组网
  P_NETWORK_EDIT = 'p-networkEdit',
  // 查看组网
  P_NETWORK_DETAIL = 'p-networkDetail',
  // 新建组网
  B_NETWORK_NEW = 'b-networkNew',
  // 编辑组网
  B_NETWORK_EDIT = 'b-networkEdit',
  // 删除组网
  B_NETWORK_DELETE = 'b-networkDelete',
  // 组网配置下发
  B_NETWORK_CONFIG = 'b-networkConfig',
  // 下发记录
  B_NETWORK_CONFIG_LOG = 'b-networkConfigLog',
  // 数据点镜像
  P_POINT_MIRROR = 'p-dataPointMirror',
  // 数据点镜像按钮
  B_POINT_MIRROR = 'b-dataPointMirror',

  // AI报告
  P_AIREPORT = 'p-aiReport',
  // 人工智能报告
  P_ARTIFICALINTELLIGENCEREPORT = 'p-artificalIntelligenceReport',

  // 回路列表
  P_CIRCUITDCLIST = 'p-circuitDcList',
  // 回路详情
  P_CIRCUITDCDETAIL = 'p-circuitDcDetail',
  // 编辑回路
  P_CIRCUITDCEDIT = 'p-circuitDcEdit',
  // 创建回路
  P_CIRCUITDCCREATE = 'p-circuitDcCreate',
  // 创建回路按钮
  B_CIRCUITDCCREATE = 'b-circuitDcCreate',
  // 编辑回路按钮
  B_CIRCUITDCEDIT = 'b-circuitDcEdit',
  // 查看回路按钮
  B_CIRCUITDCDETAIL = 'b-circuitDcDetail',
  // 删除回路按钮
  B_CIRCUITDCDELETE = 'b-circuitDcDelete',
  // 批量创建回路按钮
  B_CIRCUITDCCREATEBATCH = 'b-circuitDcCreateBatch',
  // 回路指标绑定
  B_CIRCUITDCCONFIG = 'b-circuitDcConfig',
  // 回路拓扑
  B_CIRCUITDCTOPOLOGY = 'b-circuitDcTopology',
  // 导出回路
  B_CIRCUITDCOUTPUT = 'b-circuitDcOutput',
  // 回路标签管理
  P_CIRCUIT_LABEL_PAGE = 'p-LoopLabelsManagement',
  // 回路标签新建
  B_CIRCUIT_LABEL_CEATE = 'b-LoopLabelsCreate',
  // 回路标签新建
  B_CIRCUIT_LABEL_CEATE_V2 = 'b-loopLabelsCreateV2',
  // 回路标签编辑
  B_CIRCUIT_LABEL_EDIT = 'b-editLoopLabels',
  // 删除回路标签
  B_CIRCUIT_LABEL_DELETE = 'b-LoopLabelsDelete',
  // 关联回路
  B_CIRCUIT_LABEL_LINK = 'b-CorrelationLoops',
  // 配电柜
  P_DISTRIBUTIONCABINETMANAGE = 'p-distributionCabinetManage',
  // 新增配电柜
  B_CABINET_ADD = 'b-distributionCabinetAdd',
  // 编辑配电柜
  B_CABINET_EDIT = 'b-distributionCabinetModify',
  // 删除配电柜
  B_CABINET_DELETE = 'b-distributionCabinetDelete',
  // 就地锁屏密码
  B_PASSCODEBUTTON = 'b-passcodeButton',
  // 数采下发记录
  B_EDGEDEVICECONFIGPUBLISHRECORD = 'b-edgeDeviceConfigPublishRecord',
  // 数采配置下发
  B_EDGEDEVICECONFIGPUBLISH = 'b-edgeDeviceConfigPublish',
  // 就地端锁屏密码配置
  P_PASSCODEWEB = 'p-passcodeWeb',
  // 班组管理
  P_WORKTEAMMANAGE = 'p-workTeamManage',
  // 新建班组
  B_WORKTEAMCREATE = 'b-workTeamCreate',
  // 编辑班组
  B_WORKTEAMUPDATE = 'b-workTeamUpdate',
  // 查看班组
  B_WORKTEAMDETAIL = 'b-workTeamDetail',
  // 删除班组
  B_WORKTEAMDELETE = 'b-workTeamDelete',
  // 班次日历应用配置
  P_WORKSHIFTCALENDARUSECONFIG = 'p-workShiftCalendarUseConfig',
  // 修改应用配置
  B_WORKSHIFTCALENDARUSECONFIGUPDATE = 'b-workShiftCalendarUseConfigUpdate',
  // 查看应用配置
  B_WORKSHIFTCALENDARUSECONFIGDETAIL = 'b-workShiftCalendarUseConfigDetail',
  // 产量录入
  P_BELTLINEOUTPUTENTRY = 'p-beltlineOutputEntry',
  // 产量录入详情
  P_BELTLINEOUTPUTENTRYDETAIL = 'p-beltlineOutputEntryDetail',
  // 产量导出
  B_BELTLINEOUTPUTENTRYOUTPUT = 'b-beltlineOutputEntryOutput',
  // 产量导入
  B_BELTLINEOUTPUTENTRYINPUT = 'b-beltlineOutputEntryInput',
  // 录入记录
  B_BELTLINEOUTPUTENTRYINPUTRECORD = 'b-beltlineOutputEntryInputRecord',
  // 清空产量
  B_BELTLINEOUTPUTENTRYCLEAR = 'b-beltlineOutputEntryClear',
  // 录入产量
  B_BELTLINEOUTPUTENTRYCREATE = 'b-beltlineOutputEntryCreate',
  // 产出物设定
  P_BELTLINEOUTPUTCONFIG = 'p-beltlineOutputConfig',
  // 查看产出物
  B_BELTLINEOUTPUTDETAIL = 'b-beltlineOutputDetail',
  // 编辑产出物
  B_BELTLINEOUTPUTEDIT = 'b-beltlineOutputEdit',
  // 删除产出物
  B_BELTLINEOUTPUTDELETE = 'b-beltlineOutputDelete',
  // 新增产出物
  B_BELTLINEOUTPUTCREATE = 'b-beltlineOutputCreate',
  // 排班管理
  P_WORKSHIFTAPPLYMANAGE = 'p-workShiftApplyManage',
  // 查看排班详情按钮
  B_WORKSHIFTAPPLYDETAIL = 'b-workShiftApplyDetail',
  // 复制排班
  B_WORKSHIFTAPPLYCOPY = 'b-workShiftApplyCopy',
  // 排班详情页面
  P_WORKSHIFTAPPLYDETAIL = 'p-workShiftApplyDetail',
  // 快捷排班
  B_WORKSHIFTAPPLYSWIFT = 'b-workShiftApplySwift',
  // 工作日调整
  B_WORKSHIFTAPPLYWEEKDAY = 'b-workShiftApplyWeekday',
  // 导入排班
  B_WORKSHIFTAPPLYIMPORT = 'b-workShiftApplyImport',

  // 班次管理
  P_WORKSHIFT_MANAGE = 'p-workShiftManage',
  // 新建班次
  B_WORKSHIFT_CREATE = 'b-workShiftCreate',
  // 编辑班次
  B_WORKSHIFT_UPDATE = 'b-workShiftUpdate',
  // 删除班次
  B_WORKSHIFT_DELETE = 'b-workShiftDelete',
  // 查看班次
  B_WORKSHIFT_DETAIL = 'b-workShiftDetail',
  // 班次日历
  P_SHIFTCALENDAR_MANAGE = 'p-workShiftCalendarManage',
  // 班次查看
  B_SHIFTCALENDAR_DETAIL = 'b-workShiftCalendarDetail',
  // 班次编辑
  B_SHIFTCALENDAR_UPDATE = 'b-workShiftCalendarUpdate',
  // 班次删除
  B_SHIFTCALENDAR_DELETE = 'b-workShiftCalendarDelete',
  // 应用至单元
  B_SHIFTCALENDAR_USE = 'b-workShiftCalendarUse',

  // 水看板
  P_WATERDB = 'p-waterDB',
  // 热蒸汽看板
  P_STEAMDB = 'p-steamDB',
  // 用气看板
  P_GASDB = 'p-gasDB',
  // 用气看板导出
  B_GASDBIMPORT = 'b-gasDBImport',
  // 热蒸汽看板导出
  B_STEAMDBIMPORT = 'b-steamDBImport',
  // 用水看板导出
  B_WATERDBIMPORT = 'b-waterDBImport',
  // 空气看板导出
  B_COMPRESSEDAIRDBIMPORT = 'b-compressedAirDBImport',
  // 工业气体
  B_INDUSTRYGASDBIMPORT = 'b-industryGasDBImport',
  // HVAC看板导出
  B_HVACAIRDBIMPORT = 'b-hvacAirDBImport',
  // 工业液体
  B_LIQUIDDBIMPORT = 'b-liquidDBImport',
  // 工业液体看板配置
  B_LIQUIDDBCONF = 'b-liquidDBConf',
  // HVAC看板配置
  B_HVACAIRDBCONF = 'b-hvacAirDBConf',
  // 工业气体看板配置
  B_INDUSTRYGASDBCONF = 'b-industryGasDBConf',
  // 空压看板配置
  B_COMPRESSEDAIRDBCONF = 'b-compressedAirDBConf',
  // 用气看板配置
  B_GASDBCONF = 'b-gasDBConf',
  // 热蒸汽看板配置
  B_STEAMDBCONF = 'b-steamDBConf',
  // 用水看板配置
  B_WATERDBCONF = 'b-waterDBConf',

  // 工质监测指标
  P_INDICATOR_CONFIG = 'p-indicatorConfig',
  // 输入输出配置
  P_INOUT_CONFIG = 'p-inOutConfig',
  // 购电方用电记录查询
  P_PURCHASER_ELETRICITY_RECORD = 'p-purchaserElectricityRecord',

  // 用能成本配置页面
  P_COST_ALLOCATION = 'p-costAllocation',
  // 用能成本配置页面按钮
  B_COST_ALLOCATION = 'b-costAllocation',
  // 工质账户
  B_MEDIUMACCOUNT = 'b-mediumAccount',
  // 新增工质账户
  B_INCREASEMEDIUMACCOUNT = 'b-increaseMediumAccount',
  // 编辑工质账户
  B_MODIFYMEDIUMACCOUNT = 'b-modifyMediumAccount',
  //查看工质账户
  B_VIEWMEDIUMACCOUNT = 'b-viewMediumAccount',
  // 用电合同
  B_ELECTRICUSECONTRACT = 'b-electricUseContract',
  // 工质合同
  B_MEDIUMCONTRACT = 'b-mediumContract',
  // 新增工质合同
  B_CREATEMEDIUMCONTRACT = 'b-createMediumContract',
  // 费率配置
  B_RATEALLOCATION = 'b-rateAllocation',
  // 选中重新生成
  B_REGENERATEMETERREADING = 'b-regenerateMeterReading',
  // 编辑工质合同
  B_CONTRACTMANEDIT = 'b-contractManEdit',
  // 工站信息页面
  P_STATIONINFORMATION = 'p-stationInformation',
  // 查看工站信息
  B_VIEWINGSTATIONINFORMATION = 'b-viewingStationInformation',
  // 编辑工站信息
  B_EDITSTATIONINFORMATION = 'b-editStationInformation',
  // 禁用启用工站信息
  B_CONTROLSTATIONINFORMATION = 'b-controlStationInformation',
  // 废弃工站信息
  B_SCRAPSTATIONINFORMATION = 'b-scrapStationInformation',
  // 能耗单元映射页面
  P_ENERGYCONSUMPTIONUNITMAPP = 'p-energyConsumptionUnitMapp',
  // 绑定工序
  B_BINDINGPROCESS = 'b-bindingProcess',
  // 查看能耗单元映射
  B_VIEWENERGYCONSUMPTIONUNITMAPP = 'b-viewEnergyConsumptionUnitMapp',
  // 绑定工站
  B_BINDINGSTATION = 'b-bindingStation',
  // 绑定用能单元
  B_BINDINGENERGYUNIT = 'b-bindingEnergyUnit',
  B_CREATE_STATION = 'b-createStationInformation',

  // 工序信息
  P_PROCESS_INFO = 'p-processInformation',
  // 查看工序信息
  B_VIEW_PROCESS_INFO = 'b-viewOperationInformation',
  // 编辑工序信息
  B_EDIT_PROCESS_INFO = 'b-editOperationInformation',
  // 启用/禁用工序信息
  B_CONTROL_PROCESS_INFO = 'b-controlOperationInformation',
  // 废弃工序信息
  B_DROP_PROCESS_INFO = 'b-scrapOperationInformation',
  B_ADD_PROCESS_INFO = 'b-createOperationInformation',

  // 工作中心
  P_WORKCENTER_INFO = 'p-workCenterInformation',
  // 查看工作中心
  B_VIEW_WORKCENTER_INFO = 'b-viewWorkCenterInformation',
  // 编辑工作中心
  B_EDIT_WORKCENTER_INFO = 'b-editWorkCenterInformation',
  // 启用/禁用工作中心
  B_CONTROL_WORKCENTER_INFO = 'b-controlWorkCenterInformation',
  // 废弃
  B_DROP_WORKCENTER_INFO = 'b-scrapWorkCenterInformation',
  B_ADD_WORKCENTER_INFO = 'b-createWorkCenterInformation',

  // 分析组计量设置按钮
  B_ANALYSIS_GROUP_MEASUREMENT_SETTINGS = 'b-analysisGroupMeasurementSettings',

  // 生产能耗看板
  P_PRODUCTION_ENERGY_CONSUMPTION_KANBAN = 'p-productionEnergyConsumptionKanban',
  // 生产能耗看板导出按钮
  B_EXPORT_PRODUCTION_ENERGY_CONSUMPTION_KANBAN = 'b-exportProductionEnergyConsumptionKanban',
  // 生产能耗看板对比按钮
  B_COMPARISON_PRODUCTION_ENERGY_CONSUMPTION_KANBAN = 'b-comparisonProductionEnergyConsumptionKanban',
  // 生产能耗看板 demo
  P_PRODUCTION_ENERGY_CONSUMPTION_KANBAN_DEMO = 'p-productionEnergyConsumptionKanbanDEMO',

  // 用能单元看板
  P_CAN_USE_KANBAN_SEPARATELY = 'p-canUseKanbanSeparately',

  // 数据重计算
  B_DATA_RECALCULATION = 'b-dataCalculation',
  // 充电站
  P_CHARGINGSTATION = 'p-chargingStation',
  // 新增充电站
  B_ADDCHARGINGSTATION = 'b-addChargingStation',
  // 编辑充电站
  B_EDITCHARGINGSTATION = 'b-editChargingStation',

  // 能源资产门户配置
  P_CONFIGPORTAL = 'p-configPortal',

  // 用能总览
  P_ENERGYOVERVIEW = 'p-energyOverview',
  // 配置按钮
  B_CONFIGUREBUTTONS = 'b-configureButtons',
  // 用能总览配置
  P_ENERGYOVERVIEWCONFIGURE = 'p-energyOverviewConfigure',

  // 空压看板
  P_AIRCOMPRESSORBOARD = 'p-airCompressorBoard',
  // 工业气体看板
  P_INDUSTRIALGASBOARD = 'p-industrialGasBoard',
  // HVAC看板
  P_HVACBOARD = 'p-HVACBoard',
  //工业液体看板
  P_INDUSTRIALLIQUIDBOARD = 'p-industrialLiquidBoard',

  // 工艺流程
  P_PROCESSFLOW = 'p-processFlow',
  // 工艺流程配置
  P_PROCESSFLOWCONFIG = 'p-processFlowConfig',
  // 新建工艺流程
  B_NEWPROCESSFLOW = 'b-newProcessFlow',
  // 查看工艺流程
  B_VIEWPROCESSFLOW = 'b-viewProcessFlow',
  // 编辑工艺流程
  B_EDITPROCESSFLOW = 'b-editProcessFlow',
  // 启用/禁用工艺流程
  B_CONTROLPROCESSFLOW = 'b-controlProcessFlow',
  // 废弃工艺流程
  B_SCRAPPROCESSFLOW = 'b-scrapProcessFlow',
  // 单耗设定
  P_CONSUMPTIONSETTING = 'p-consumptionSetting',
  // 新建单耗阈值按钮
  B_CREATECONSUMPTIONTHRESHOLD = 'b-createConsumptionThreshold',
  // 新建单耗阈值页面
  P_CREATECONSUMPTIONTHRESHOLDPAGE = 'p-createConsumptionThresholdPage',
  // 编辑单耗阈值按钮
  B_EDITCONSUMPTIONTHRESHOLD = 'b-editConsumptionThreshold',
  // 编辑单耗阈值
  P_DEITCONSUMPTIONTHRESHOLDPAGE = 'p-editConsumptionThresholdPage',
  //查看单耗阈值按钮
  B_VIEWCOUNSUMPTIONTHRESHOLD = 'b-viewConsumptionThreshold',
  // 查看单耗阈值
  P_VIEWCONSUMPTIONTHRESHOLDPAGE = 'p-viewConsumptionThresholdPage',
  // 复制单耗阈值按钮'p-copyConsumptionThresholdPage'
  B_COPYCONSUMPTIONTHRESHOLD = 'b-copyConsumptionThreshold',
  // 复制单耗阈值
  P_COPYCONSUMPTIONTHRESHOLDPAGE = 'p-copyConsumptionThresholdPage',
  // 删除单耗阈值
  P_DELETECONSUMPTIONTHRESHOLD = 'p-deleteConsumptionThreshold',
  // 能耗报警规则
  P_ENERGYCONSUMPTIONALARMRULEPAGE = 'p-energyConsumptionAlarmRulePage',
  // 编辑规则页面
  P_EDITRULEPAGE = 'p-editRulePage',
  // 新建规则页面
  P_CREATERULEPAGE = 'p-createRulePage',
  // 新建规则
  B_ENERGYCONSUMPTIONCREATERULE = 'b-energyConsumptionCreateRule',
  // 删除
  B_ENERGYCONSUMPTIONDELETE = 'b-energyConsumptionDelete',
  // 编辑
  B_ENERGYCONSUMPTIONEDIT = 'b-energyConsumptionEdit',
  // 禁用/启用
  B_DISABLEENABLE = 'b-disableEnable',
  // 查看报警规则页面
  P_RULEPAGE = 'p-rulePage',
  // 能耗报警列表
  P_ENERGYCONSUMPTIONALARMLIST = 'p-energyConsumptionAlarmList',

  // 离散率服务端报警
  P_SERVERALARMRULE = 'p-serverAlarmRule',
  // 离散率服务端报警新建
  P_SERVERALARMCREATE = 'p-serverAlarmCreate',
  // 离散率服务端报警编辑
  P_SERVERALARMUPDATE = 'p-serverAlarmUpdate',
  // 离散率服务端报警详情
  P_SERVERALARMDETAIL = 'p-serverAlarmDetail',
  // 离散率服务端报警新建按钮
  B_SERVERALARMRULECREATE = 'b-serverAlarmRuleCreate',
  // 离散率服务端报警编辑按钮
  B_SERVERALARMRULEUPDATE = 'b-serverAlarmRuleUpdate',
  // 离散率服务端报警删除按钮
  B_SERVERALARMRULEDELETE = 'b-serverAlarmRuleDelete',
  // 离散率服务端报警禁用/启用按钮
  B_SERVERALARMRULESTATUS = 'b-serverAlarmRuleStatus',
  // 精益能流分析
  P_FLOW_ANALYSIS = 'p-leanValueStreamAnalysis',
  // 能源资产结算规则
  P_ENERGYPROPERTYRULE = 'p-energyPropertyRule',
  // 新建能源资产结算规则
  B_ENERGYPROPERTYCREATE = 'b-energyPropertyCreate',
  // 编辑能源资产结算规则
  B_ENERGYPROPERTYEDIT = 'b-energyPropertyEdit',
  // 删除能源资产结算规则
  B_ENERGYPROPERTYDELETE = 'b-energyPropertyDelete',
  // 禁用/启用能源资产结算规则
  B_ENERGYPROPERTYBAN = 'b-energyPropertyBan',
  // 复制能源资产结算规则
  B_ENERGYPROPERTYCOPY = 'b-energyPropertyCopy',
  // 能源资产结算规则新建
  P_ENERGYPROPERTYRULECREATE = 'p-energyPropertyRuleCreate',
  // 能源资产结算规则编辑
  P_ENERGYPROPERTYRULEEDIT = 'p-energyPropertyRuleEdit',
  // 能源资产结算单
  P_ENERGYPROPERTYSETTLEMENTSTATEMENT = 'p-energyPropertySettlementStatement',
  // 能源手动生成核查联
  B_ENERGYPROPERTYSETTLEMENTSTATEMENTMANALCHECK = 'b-energyPropertySettlementStatementManalCheck',
  // 核查联详情
  P_ENERGYPROPERTYSETTLEMENTSTATEMENTCHECKDETAIL = 'p-energyPropertySettlementStatementCheckDetail',
  // 作废
  B_ENERGYPROPERTYSETTLEMENTSTATEMENTBANCHECK = 'b-energyPropertySettlementStatementBanCheck',
  // 优化配置
  P_OPTIMIZEPROJECTCONFIG = 'p-optimizeProjectConfig',
  // 新建优化配置
  B_OPTIMIZEPROJECTCREATE = 'b-optimizeProjectCreate',
  // 编辑优化配置
  B_OPTIMIZEPROJECTEDIT = 'b-optimizeProjectEdit',
  // 删除优化配置
  B_OPTIMIZEPROJECTDELETE = 'b-optimizeProjectDelete',
  // 优化分析
  P_OPTIMIZEANALYSIS = 'p-optimizeAnalysis',
  // 优化明细按钮
  B_OPTIMIZEANALYSISBUTTON = 'b-optimizeAnalysisButton',
  // 优化明细
  P_OPTIMIZEANALYSISPAGE = 'p-optimizeAnalysisPage',
  // 排产节能AI诊断
  P_PRODUCTIONAI = 'p-productionAI',

  // 能源介质对比
  P_ENERGYMEDIUMCOMPARE = 'p-energyMediumCompare',
  // 能源介质分析
  P_PROCESSANALYSIS = 'p-processAnalysis',
  // 配置计量指标
  B_INDICATORCONFIG = 'b-indicatorConfig',
  // 核算单元
  P_CARBONACCOUNTINGUNIT = 'p-carbonAccountingUnit',
  // 年度碳排放计划
  P_CARBONEMISSIONPLAN = 'p-carbonEmissionPlan',
  // 碳排放计划新建
  P_CARBONEMISSIONPLANSAVE = 'p-carbonEmissionPlanSave',
  // 碳排放计划编辑
  P_CARBONEMISSIONPLANEDIT = 'p-carbonEmissionPlanUpdate',
  // 碳排放计划查看
  P_CARBONEMISSIONPLANDETAIL = 'p-carbonEmissionPlanDetail',
  // 碳排放计划新建按钮
  B_CARBONEMISSIONPLANCREATE = 'b-carbonEmissionPlanSave',
  // 碳排放计划编辑按钮
  B_CARBONEMISSIONPLANUPDATE = 'b-carbonEmissionPlanUpdate',
  // 碳排放计划查看按钮
  B_CARBONEMISSIONPLANDETAIL = 'b-carbonEmissionPlanDetail',
  // 碳排放计划删除按钮
  B_CARBONEMISSIONPLANDELETE = 'b-carbonEmissionPlanDelete',
  // 盘查任务
  P_CARBONINVENTORYTASK = 'p-carbonInventoryTask',
  // 自动填报规则管理
  P_CARBONEMISSIONAUTOFILLINGRULE = 'p-carbonEmissionAutoFillingRule',
  // 碳排填报
  P_CARBONEMISSIONFILLING = 'p-carbonEmissionFilling',

  // 监控账户配置
  P_SURVEILLANCEACCOUNTMANAGE = 'p-surveillanceAccountManage',
  // 添加账户
  B_SURVEILLANCEACCOUNTADD = 'b-surveillanceAccountAdd',
  // 编辑账户
  B_SURVEILLANCEACCOUNTEDIT = 'b-surveillanceAccountEdit',
  // 删除账户
  B_SURVEILLANCEACCOUNTDELETE = 'b-surveillanceAccountDelete',
  // 管理监控设备按钮
  B_SURVEILLANCEACCOUNTDEVICEBUTTON = 'b-surveillanceAccountDeviceButton',
  // 视频监控列表
  P_VIDEOSURVEILLANCELIST = 'p-videoSurveillanceList',
  // 管理监控设备按钮
  B_VIDEOSURVEILLANCEDEVICELIST = 'b-videoSurveillanceDeviceList',

  // 监控账户配置光伏tab
  B_SURVEILLANCEACCOUNTPVTAB = 'b-surveillanceAccountPvTab',
  // 监控账户配置Bsatab
  B_SURVEILLANCEACCOUNTBSATAB = 'b-surveillanceAccountBsaTab',
  // 监控账户配置微电网tab
  B_SURVEILLANCEACCOUNTDCTAB = 'b-surveillanceAccountDcTab',
  // 监控账户配置过程tab
  B_SURVEILLANCEACCOUNTPCTAB = 'b-surveillanceAccountPcTab',
  // 监控账户配置其他tab
  B_SURVEILLANCEACCOUNTOTHERTAB = 'b-surveillanceAccountOtherTab',

  // 视频监控光伏tab
  B_VIDEOSURVEILLANCELISTPVTAB = 'b-videoSurveillanceListPvTab',
  // 视频监控Bsatab
  B_VIDEOSURVEILLANCELISTBSATAB = 'b-videoSurveillanceListBsaTab',
  // 视频监控微电网tab
  B_VIDEOSURVEILLANCELISTDCTAB = 'b-videoSurveillanceListDcTab',
  // 视频监控过程tab
  B_VIDEOSURVEILLANCELISTPCTAB = 'b-videoSurveillanceListPcTab',
  // 视频监控其他tab
  B_VIDEOSURVEILLANCELISTOTHERTAB = 'b-videoSurveillanceListOtherTab',
  // 全域抄表查询页面
  P_GLOBALMETERREADING = 'p-globalMeterReading',
  // 导出报表按钮
  B_GLOBALMETERREADINGEXPORT = 'b-globalMeterReadingExport',
  // 详情
  B_GLOBALMETERREADINGDETAIL = 'b-globalMeterReadingDetail',
  // 抄表详情页面
  P_GLOBALMETERREADINGDETAIL = 'p-globalMeterReadingDetail',
  // 抄见值变更
  B_GLOBALMETERREADINGVALUEUPDATE = 'b-globalMeterReadingValueUpdate',
  // 批量调整
  B_GLOBALMETERREADINGBATCHADJUST = 'b-globalMeterReadingBatchAdjust',
  // 分摊录入
  B_GLOBALMETERREADINGAPPORTIONMENTENTRY = 'b-globalMeterReadingApportionmentEntry',
  // 调整
  B_GLOBALMETERREADINGADJUST = 'b-globalMeterReadingAdjust',
  // 生产工作实况-按钮
  B_PRODUCTCONDITION = 'b-productCondition',
  // 查看详情(能流概览)-按钮
  B_ENERGYFLOWDETAIL = 'b-energyFlowDetail',
  //抄表项目详情
  P_GLOBALMETERREADINGPROJECT = 'p-meterReadingProjectDetail',
  //抄表项目配置
  P_GLOBALMETERREADINGSETTING = 'p-meterReadingSetting',
  //抄表项目详情
  B_GLOBALMETERREADING = 'b-meterReadingDetail',
  //禁用/启用
  B_GLOBALMETERREADINGSWITCH = 'b-meterReadingSwitch',
  //新建抄表项目
  B_GLOBALMETERREADINGADD = 'b-meterReadingAdd',
  //拓扑节点管理
  B_TOPOLOGYNODEMANAGE = 'b-topologyNodeManage',
  //能源调度管理 p-ued
  P_UED = 'p-ued',
  //能源调度基础设置 p-uedBasicSet
  P_UEDBASICSET = 'p-uedBasicSet',
  // 添加调度组按钮 b-addUed
  B_ADDUED = 'b-addUed',
  // 编辑调度组按钮 b-editUed
  B_EDITUED = 'b-editUed',
  // 编辑调度组基础信息按钮b-editUedBasicSet
  B_EDITUEDBASICSET = 'b-editUedBasicSet',
  // 启用调度组按钮b-uedEnable
  B_UEDENABLE = 'b-uedEnable',
  // 停用调度组按钮b-uedStop
  B_UEDSTOP = 'b-uedStop',
  // 调度拓扑结构tabp-edt
  P_EDT = 'p-edt',
  // 新建调度拓扑结构按钮b-addEdt
  B_ADDEDT = 'b-addEdt',
  // 编辑基础信息按钮b-editEdtBasicSet
  B_EDITEDTBASICSET = 'b-editEdtBasicSet',
  // 编辑拓扑结构按钮b-editEdt
  B_EDITEDT = 'b-editEdt',
  // 负载p-load
  P_LOAD = 'p-load',
  // 新增负载按钮b-addLoad
  B_ADDLOAD = 'b-addLoad',
  // 编辑负载按钮b-editLoad
  B_EDITLOAD = 'b-editLoad',
  // 备用电源p-powerSupply
  P_POWERSUPPLY = 'p-powerSupply',

  // 储能站新
  B_BSA_NEW_ADD = 'b-bsaAddNew',
  B_BSA_NEW_EDIT = 'b-bsaEditNew',
  // 新增备用电源按钮b-addPowerSupply
  B_ADDPOWERSUPPLY = 'b-addPowerSupply',
  // 编辑备用电源按钮b-editPowerSupply
  B_EDITPOWERSUPPLY = 'b-editPowerSupply',
  // 调度拓扑画布
  P_EDTSET = 'p-edtSet',
  // 查看拓扑结构按钮 b-viewEdt
  B_VIEWEDT = 'b-viewEdt',
  // 能源调度基础信息 p-uedBasicInfor
  P_UEDBASICINFOR = 'p-uedBasicInfor',
  // 工作中心用能拓扑图
  P_WORKCENTERSTRUCTUREDIAGRAM = 'p-WorkCenterStructureDiagram',
  // 工作中心用能拓扑图按钮
  B_WORKCENTERSTRUCTUREDIAGRAM = 'b-workCenterStructureDiagram',
  //新建班次日历
  P_WORKSHITFCALENDARCREATE = 'p-workShiftCalendarCreate',
  //用能统计详情-按钮
  B_ENERGYCONSUMDETAIL = 'b-energyConsumDetail',
  //监控详情-按钮
  B_MONITORDETAIL = 'b-monitorDetail',
  //设备详情-按钮
  B_DEVICEDEATIL = 'b-deviceDetail',
  // 用水总览
  P_WATER_OVERVIEW = 'p-waterOverview',
  // 用水总览配置
  P_WATER_OVERVIEW_CONFIG = 'p-configWaterOverview',
  // 用水总览配置按钮
  B_WATER_OVERVIEW_CONFIG = 'b-configWaterOverview',
  // 物料管理
  P_MATERIAL_MANAGE = 'p-materialManagement',
  // 新建物料
  B_MATERIAL_ADD = 'b-addmaterial',
  // 编辑物料
  B_MATERIAL_EDIT = 'b-editmaterial',
  // 删除物料
  B_MATERIAL_DEL = 'b-delmaterial',
  // 单元能流分析
  P_UNIT_ENERGY_FLOW_ANALYSIS = 'p-unitEnergyFlowAnalysis',
  B_AI_UNIT_ANALYSIS = 'b-AIUnitAnalysis',

  // 碳足迹模型管理
  P_PCFMODELMANAGEMENT = 'p-PCFModelManagement',
  // 维护BOM
  B_MAINTENANCEPRODUCTBOM = 'b-maintenanceProductBOM',
  // 启用禁用模型
  B_ENABLEPCFMODEL = 'b-EnablePCFModel',
  // 删除模型
  B_DELPCFMODEL = 'b-delPCFModel',
  // 新建模型
  B_ADDPCFMODEL = 'b-addPCFModel',
  // 维护模型
  B_MAINTENANCEPCFMODEL = 'b-maintenancePCFModel',
  // 维护模型
  P_MAINTENANCEPCFMODEL = 'p-maintenancePCFModel',
  // 能源管理
  P_ENERGYMANAGEMENT = 'p-energyManagement',
  // 新建能源
  B_ADDENERGY = 'b-addenergy',
  // 编辑能源
  B_EDITENERGY = 'b-editenergy',
  // 删除能源
  B_DELENERGY = 'b-delenergy',
  //产品碳足迹填报管理
  P_PCFFILLING = 'p-PCFFillingManagement',
  //新建填报
  B_ADDPCFFILLING = 'b-AddPCFFilling',
  //编辑填报
  B_EDITPCFFILLING = 'b-EditPCFFilling',
  //删除填报
  B_DELPCFFILLING = 'b-DelPCFFilling',
  //物料入库管理
  P_MATERIAL_RECEIPT_MANAGEMENT = 'p-MaterialReceiptManagement',
  //新建物料入库
  B_ADDMATERIALRECEIPT = 'b-AddMaterialReceipt',
  //编辑物料入库
  B_EDITMATERIALRECEIPT = 'b-EditMaterialReceipt',
  //删除物料入库
  B_DELMATERIALRECEIPT = 'b-DelMaterialReceipt',
  //新建核算主体按钮
  B_ADD_CARBON_ACCOUNTING_ENTITY = 'b-createCarbonAccountingEntity',
  //核算主体详情
  P_CARBON_ACCOUNTING_ENTITY_DETAIL = 'p-carbonAccountingEntityDetails',
  // 用能计划
  P_ENERGY_USE_PLAN = 'p-energyUsePlan',
  // 新建用能计划-用能分析组
  B_BUILT_ENERGY_USE_PLAN = 'b-builtEnergyUsePlan',
  // 用能计划配置按钮
  B_EDIT_ENERGY_USE_PLAN = 'b-editEnergyUsePlan',
  // 用能计划配置页面
  P_EDIT_ENERGY_USE_PLAN = 'p-editEnergyUsePlan',
  // 查看计划按钮
  B_ENERGY_USE_PLAN_VIEW = 'b-energyUsePlanView',
  // 查看计划页面
  P_ENERGY_USE_PLAN_VIEW = 'p-energyUsePlanView',
  // 删除按钮
  B_DELETE_ENERGY_USE_PLAN = 'b-deleteEnergyUsePlan',
  // 预览按钮
  B_ENERGY_PLAN_VIEW = 'b-energyPlanView',
  // 编辑按钮
  B_EDIT_ENERGY_PLAN = 'b-editEnergyPlan',
  // 供应商管理
  P_SUPPLIERMANAGEMENT = 'p-SupplierManagement',
  // 新建供应商
  B_ADDSUPPLIER = 'b-AddSupplier',
  // 编辑供应商
  B_EDITSUPPLIER = 'b-EditSupplier',
  // 删除供应商
  B_DELSUPPLIER = 'b-DelSupplier',
  // 班次日历-应用
  B_WORKSHIFTCALENDARUSE = 'b-workShiftCalendarUse',
  // 班次日历-应用到单元
  B_WORKSHIFTCALENDARUSECONFIGCREATE = 'b-workShiftCalendarUseConfigCreate',
  // 班次日历-应用到工序
  B_WORKSHIFTCALENDARUSEPROCESSES = 'b-workShiftCalendarUseProcesses',
  // 生产过程管理
  P_PRODUCTIONPROCESSMANAGEMENT = 'p-productionProcessManagement',
  // 生产节拍诊断DEMO p-taktTime
  P_TAKTTIME = 'p-taktTime',
  // 碳足迹下载报告
  B_PRODUUCTCARBONFOOTPRINTREPORTDOWNLOAD = 'b-productCarbonFootprintReportDownload',
  // 碳足迹查看报告
  B_PRODUUCTCARBONFOOTPRINTREPORTVIEW = 'b-productCarbonFootprintReportView',
  // 基线管理
  P_ENERGY_CONSUMPTIONBASELINEMANAGE = 'p-UnitConsumptionBaselineManage',
  // 新建单耗基线
  P_ADDUNITCONSUMPTIONBASELINE = 'p-AddUnitConsumptionBaseline',
  // 编辑单耗基线
  P_EDITUNITCONSUMPTIONBASELINE = 'p-EditUnitConsumptionBaseline',
  // 新建单耗基线按钮
  B_ADDUNITCONSUMPTIONBASELINE = 'b-AddUnitConsumptionBaseline',
  // 编辑单耗基线按钮
  B_EDITUNITCONSUMPTIONBASELINE = 'b-EditUnitConsumptionBaseline',
  // 启用禁用单耗基线按钮
  B_ENABLEUNITCONSUMPTIONBASELINE = 'b-EnableUnitConsumptionBaseline',
  // 删除单耗基线按钮
  B_DELUNITCONSUMPTIONBASELINE = 'b-DelUnitConsumptionBaseline',
  // 碳足迹概览
  P_CFPOVERVIEW = 'p-CFPOverview',
  // 应用分析订阅
  P_APPANALYTICSSUBSCRIPTION = 'p-appAnalyticsSubscription',
  // 批量配置
  B_BATCHCONFIGURATION = 'b-batchConfiguration',
  // 编辑
  B_EDITAPPANALYTICSSUBSCRIPTION = 'b-editAppAnalyticsSubscription',
  // 配置
  B_APPANALYTICSSUBSCRIPTIONCONFIG = 'b-appAnalyticsSubscriptionConfig',
  // 删除
  B_DELETEAPPANALYTICSSUBSCRIPTION = 'b-deleteAppAnalyticsSubscription',
  // 生产节拍诊断
  P_TAKTTIMEDIAGNOSIS = 'p-taktTimeDiagnosis',
  // 用能阈值配置
  P_ENERGYCONSUMPTIONTHRESHOLDCONFIGURATION = 'p-energyConsumptionThresholdConfiguration',
  // 气体制备看板
  P_GASSTATIONBOARD = 'p-gasStationBoard',
  // 看板配置按钮
  B_BOARDCONFIG = 'b-boardConfig',
  // 基础配置按钮
  B_BASECONFIG = 'b-baseConfig',
  // 站房详情按钮
  B_STATIONDETAILSBTN = 'b-stationDetailsBtn',
  // 看板配置页面
  P_BOARDCONFIGPAGE = 'p-boardConfigPage',
  // 气体制备站
  P_GASSTATION = 'p-GasStation',
  // 新建空压站
  B_ADDAIRCOMPRESSIONSTATION = 'b-AddAirCompressionStation',
  // 新建制氮站
  B_ADDNITROGENSTATION = 'b-AddNitrogenStation',
  // 编辑空压站
  B_EDITAIRCOMPRESSIONSTATION = 'b-EditAirCompressionStation',
  // 编辑制氮站
  B_EDITNITROGENSTATION = 'b-EditNitrogenStation',
  // 编辑站内拓扑
  B_EDITSTATIONTOPOLOGY = 'b-EditStationtopology',
  // 新建站内拓扑
  B_ADDSTATIONTOPOLOGY = 'b-AddStationtopology',
  // 节点详情页面
  P_NODEDETAILS = 'p-nodeDetails',
  // 站房详情页面
  P_STATIONDETAILS = 'p-stationDetails',
  //派工计划
  P_DISPATCHPROGRAMLIST = 'p-dispatchProgramlist',
  //派工计划新增按钮
  B_ADDDISPATCHPROGRM = 'b-AdddispatchProgram',
  //派工计划编辑按钮
  B_EDITDISPATCHPROGRAM = 'b-EditdispatchProgram',
  //删除派工计划按钮
  B_DELETEDISPATCHPROGRAM = 'b-deletedispatchProgram',
  // 设备生产计划配置
  P_DEVICESCHEDULEDPRODUCTION = 'p-deviceScheduledProduction',
  // 新增生产计划按钮
  B_ADDDEVICESCHEDULEDPRODUCTION = 'b-addDeviceScheduledProduction',
  // 编辑按钮
  B_EDITDEVICESCHEDULEDPRODUCTION = 'b-editDeviceScheduledProduction',
  // 删除按钮
  B_DELETEDEVICESCHEDULEDPRODUCTION = 'b-deleteDeviceScheduledProduction',
  // 产出物分类
  P_DESCRIPTIONANDSPECIFICATION = 'p-descriptionandspecification',
  // 产出物分类编辑
  B_EDITDESCRIPTIONANDSPECIFICATION = 'b-editdescriptionandspecification',
  // 新增产出物分类
  B_ADDDESCRIPTIONANDSPECIFICATION = 'b-adddescriptionandspecification',
  // 删除产出物分类
  B_DELDESCRIPTIONANDSPECIFICATION = 'b-deldescriptionandspecification',
  // 复制产出物分类
  B_COPYDESCRIPTIONANDSPECIFICATION = 'b-copydescriptionandspecification',
  // 暖通空调站
  P_HVACSTATION = 'p-HVACStation',
  // 新建暖通空调站
  B_ADDHVACSTATION = 'b-AddHVACStation',
  // 编辑暖通空调站
  B_EDIEHVACSTATION = 'b-EditHVACStation',
  // 新建站内拓扑
  B_ADDHVACSTATIONTOPOLOGY = 'b-AddHVACStationtopology',
  // 编辑站内拓扑
  B_EDITHVACSTATIONTOPOLOGY = 'b-EditHVACStationtopology',
  // 节点指标配置
  B_HVACSTATIONPROPERTYCONFIG = 'b-HVACStationPropertyConfig',
  // OEE应用页
  P_OEEAPPLICATIONPAGE = 'p-oEEApplicationPage',
  // OEE应用页对比
  B_COMPAREBUTTON = 'b-compareButton',
  // OEE应用页导出
  B_EXPORTBUTTON = 'b-exportButton',
  // 站内节点列表
  P_HVAC_STATION_PROPERTY_LIST = 'p-HVACStationPropertylist',
  // 站内节点列表-配置按钮
  B_HVAC_STATION_PROPERTY_LIST_CONFIG = 'b-HVACStationPropertylistConfig',
  // 节点指标配置
  P_HVAC_STATION_PROPERTY_CONFIG = 'p-HVACStationPropertyConfig',
  // 节点指标配置-编辑 b-HVACStationPropertyConfigEdit
  B_HVAC_STATION_PROPERTY_CONFIG_EDIT = 'b-HVACStationPropertyConfigEdit',
  // 节点指标配置-数据链路 b-HVACStationPropertyConfigDataLink
  B_HVAC_STATION_PROPERTY_CONFIG_DATALINK = 'b-HVACStationPropertyConfigDataLink',
  // 生产报工管理
  P_PRODUCTIONREPORTINGMANAGEMENT = 'p-ProductionReportingManagement',
  // 导入生产报工单
  B_IMPORTPRODUCTIONREPORTING = 'b-ImportProductionReporting',
  // 导入日志
  B_IMPORTPRODUCTIONREPORTINGLOG = 'b-ImportProductionReportingLog',
  // 删除
  B_DELPRODUCTIONREPORTING = 'b-delProductionReporting',
  // 投料单管理
  P_MATERIALFEEDINGMANAGEMENT = 'P-MaterialFeedingManagement',
  // 导入投料单
  B_IMPORTMATERIALFEEDING = 'b-ImportMaterialFeeding',
  // 导入日志
  B_IMPORTMATERIALFEEDINGLOG = 'b-ImportMaterialFeedingLog',
  // 删除
  B_DELMATERIALFEEDING = 'b-delMaterialFeeding',

  // 生产车间看板
  P_KANBANPRODUCTIONFLOOR = 'p-kanbanProductionFloor',
  // 车间看板配置按钮
  B_KANBANPRODUCTIONFLOORCONGIF = 'b-kanbanProductionFloorConfig',
  // 车间看板配置
  P_KANBANPRODUCTIONFLOORCONGIF = 'p-kanbanProductionFloorConfig',
  // 监控指标配置页
  P_HVACMONITORINDEXCONFIG = 'p-HVACMonitorIndexConfig',
  // 生产节拍配置页
  P_TAKTTIMECONFIG = 'p-taktTimeConfig',
  // 按动作识别节拍
  B_TAKTTIMEFOLLOWTHEACTION = 'b-taktTimeFollowTheAction',
  // 暖通站房看板页面
  P_KANBANHAVC = 'p-kanbanHVAC',
  // 监控指标配置按钮
  B_KANBANHAVCMONITORINDEXCONFIG = 'b-kanbanHVACMonitorIndexConfig',
  // 单位配置按钮
  B_KANBANHAVCUNITCONFIG = 'b-kanbanHVACUnitConfig',
  // 单位配置页面
  P_DISPLAYUNITCONFIG = 'p-displayUnitConfig',
  // Hvac站房详情
  P_HVACSTATIONDETAILS = 'p-HVACStationDetails',
  // hvac节点详情页
  P_CHILLERDETAILS = 'p-CHILLERDetails',
  // 展示单位配置
  B_UNITEDIT = 'b-unitEdit',
  // 组网导出点表
  B_POINTEXPORT = 'b-networkDetailExportPointTable',
  // 设备台账
  P_EQUIPMENTLEDGER = 'p-equipmentledger',
  // 生产能耗监测看板
  P_PRODUCTION_UNIT_CONSUMPTION_KANBAN = 'p-productionUnitConsumptionKanban',
  // mes数据导入
  P_MESDATAIMPORT = 'p-mesdataImport',
  // 删除mes数据
  B_DELETEMESDATA = 'b-deleteMESData',

  // 中亿丰大屏
  P_LPSJMESLARGESCREEN = 'p-lpsjMesLargeScreen',
  // 中亿丰配置按钮
  B_LARGESCREENDISPOSITION = 'b-largeScreenDisposition',

  // 微电网模型
  P_MODELMANAGEMENT = 'p-modelManagement',
  // 新增光伏预测模型
  B_ADDPHOTOVOLTAICSTATIONPREDICTIONMODEL = 'b-addPhotovoltaicStationPredictionModel',

  // 三方设备绑定
  P_THREE_PARTYDEVICEBINDING = 'p-three-partyDeviceBinding',
  // 新增三方设备关联关系
  B_ADDTHREE_PARTYDEVICERELATIONSHIP = 'b-addThree-partyDeviceRelationship',
}
