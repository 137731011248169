import { keepTwoNull } from '@/pages/ZYFDashboard/utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { generateTimeAxis } from '../WorkshopUsage/chart';

export interface ChartsData {
  name: string;
  list: {
    value: number;
  }[];
}

export const getChartOption = (data?: ChartsData[]) => {
  const timeAxis = generateTimeAxis(30); // 生成30天的时间轴

  const areaColors = [
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(74,255,127,0.15)' },
      { offset: 1, color: 'rgba(74,255,127,0.05)' },
    ]),
  ];

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data } = item;
          const unit = 'kWh';
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data || '-'}${unit}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 10,
      right: 10,
      top: 40,
      bottom: 10,
      containLabel: true,
    },
    color: ['rgba(74, 255, 127)'],
    xAxis: [
      {
        type: 'category',
        data: timeAxis,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (value: number) {
            return dayjs(value).format('MM-DD');
          },
          fontSize: 14,
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'kWh',
        max: 4000,
        min: 0,
        interval: 1000,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    series: data?.map((item, index) => ({
      name: item.name,
      type: 'line',
      data: item?.list.map(i => keepTwoNull(i.value)),
      connectNulls: true,
      smooth: true,
      symbol: 'circle',
      symbolSize: 2,
      showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
      itemStyle: {
        // symbol样式
        normal: {
          borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
          borderWidth: 6, // symbol边框宽度
        },
      },
      lineStyle: {
        width: 2,
        type: 'solid', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
      },
      areaStyle: {
        color: areaColors[index % areaColors.length],
      },
    })),
  };
};
