import React, { Key, useEffect, useRef, useState } from 'react';
import CardBox from '../CardBox';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';
import { ChartsData, getChartOption } from './chart';
import dayjs from 'dayjs';
import { Button, Tabs } from '@maxtropy/components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CustomTooltip from '../CustomTooltip';
import { m3Data, tData } from '../../data';
import { Spin } from 'antd';

const UnitCoFactoryWide = () => {
  const chartRef = useRef<ReactEcharts>();
  const [tabActiveKey, setTabActiveKey] = useState<string>('t');
  const [data, setData] = useState<ChartsData[]>(tData);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  const handleResize = () => {
    const echartsInstance = chartRef.current?.getEchartsInstance();
    if (echartsInstance) {
      echartsInstance.resize();
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const echartsInstance = chartRef.current?.getEchartsInstance();
      if (echartsInstance) {
        echartsInstance.resize();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CardBox
      title={
        <div>
          全厂用量
          <CustomTooltip title="按标况下系数计数转换">
            <ExclamationCircleOutlined style={{ color: 'var(--mx-warning-color)', fontSize: 14, marginLeft: '8px' }} />
          </CustomTooltip>
        </div>
      }
      subTitle={dayjs().format('YYYY-MM-DD')}
      extraContent={
        <Button
          type="link"
          onClick={() => {
            // @ts-ignore
            window.open(`${window.ENERGYPLATFORMSORIGIN}/layout/home`, '_self');
          }}
        >
          详情 {'>>'}
        </Button>
      }
      className={styles.cardBoxSty}
    >
      <Tabs
        getPopupContainer={triggerNode => triggerNode}
        animated={true}
        className={styles.tabsStyle}
        activeKey={tabActiveKey as string}
        onChange={v => {
          setTabActiveKey(v);
          setData(v === 't' ? tData : m3Data);
        }}
        items={[
          {
            label: '质量',
            key: 't',
          },
          {
            label: '体积',
            key: 'm³',
          },
        ]}
      />
      <div className={styles.charts_wrapper} ref={containerRef}>
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <ReactEcharts
            style={{ height: '100%' }}
            ref={d => {
              if (d) {
                chartRef.current = d;
              }
            }}
            option={getChartOption(data, tabActiveKey)}
            notMerge
            lazyUpdate={false}
          />
        )}
      </div>
    </CardBox>
  );
};

export default UnitCoFactoryWide;
