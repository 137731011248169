import circleImg from './imgs/circle@2x.png';

export const getPieChartOption = (chartData?: { name: string; num: number; id: number }[]) => {
  const totalPower = chartData?.reduce((pre, cur) => (cur.num || 0) + pre, 0);
  const formatShowChartData = chartData?.slice(0, 8);
  const finalChartData = [...(formatShowChartData ?? [])];
  return {
    color: ['#52E7FF', '#16DD8E', '#2D8DFF', '#FFCB47', '#854ECA', '#ABD335', '#FF9247', '#FF477B'],
    legend: {
      left: '50%',
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 18,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        rich: {
          name: {
            fontSize: 14,
            lineHeight: 20,
            width: 100,
          },
          percent: {
            fontSize: 14,
            width: 100,
          },
        },
      },
      formatter: (name: string) => {
        const item = finalChartData?.find(item => item.name === name);
        const percent = totalPower ? (((item?.num ?? 0) / totalPower) * 100).toFixed(2) + '%' : '-';
        return `{name| ${name}}{percent| ${percent ?? '-'}}`;
      },
    },
    title: {
      text: chartData?.reduce((pre, cur) => (cur.num || 0) + pre, 0).toFixed(2),
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 20,
        lineHeight: 22,
      },
      subtext: '总故障数',
      subtextStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 14,
      },
      left: 120,
      top: 98,
      itemGap: 8,
      textAlign: 'center',
    },
    graphic: {
      elements: [
        {
          type: 'image',
          style: {
            image: circleImg,
            x: 69,
            y: 69,
            width: 112,
            height: 112,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: '总故障数',
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [66, 76],
        left: 50,
        top: 50,
        width: 150,
        height: 150,
        label: {
          show: false,
        },
        data: (finalChartData ?? []).map(item => ({
          name: item.name,
          value: item.num,
        })),
        showEmptyCircle: false,
      },
      {
        showEmptyCircle: false,
        // emptyCircleStyle: {
        //   color: 'rgba(82,231,255,0.05)',
        // },
        data: (finalChartData ?? [])?.map(item => ({
          name: item.name,
          value: item.num,
        })),
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: 50,
        top: 50,
        name: '总故障数',
        radius: [74, 94],
        width: 150,
        height: 150,
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};
