import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import CardBox from '../CardBox';
import { Carousel, Spin } from 'antd';
import dayjs from 'dayjs';

const EnergyApplication = ({ title, data }: { title: string; data: any[] }) => {
  const [loading, setLoading] = useState<boolean>(false);

  // 模拟异步请求数据
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <CardBox title={title} subTitle={dayjs().format('YYYY-MM-DD')} className={styles.cardBoxSty}>
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <>
          <div className={styles.tableHeader}>
            <div style={{ width: '30%' }}>车间名称</div>
            <div style={{ width: '30%' }}>时间</div>
            <div style={{ width: '20%' }}>类目</div>
            <div style={{ width: '20%' }}>申请量</div>
          </div>
          <div className={styles.tableBody}>
            <Carousel
              autoplay={true}
              vertical={true}
              dots={false}
              cssEase="linear"
              autoplaySpeed={800}
              slidesToShow={6}
              speed={800}
            >
              {data.map((item, index) => (
                <div className={styles.tableRow} key={index}>
                  <div style={{ width: '30%' }}>{item.name}</div>
                  <div style={{ width: '30%' }}>{dayjs(item.time, 'HH:mm').format('MM-DD HH:mm')}</div>
                  <div style={{ width: '20%' }}>{item.type}</div>
                  <div style={{ width: '20%' }}>{item.value}</div>
                </div>
              ))}
            </Carousel>
          </div>
        </>
      )}
    </CardBox>
  );
};

export default EnergyApplication;
