import React, { useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SceneTypeEnum, StationEnum } from '../StationArea/type';
import StationAreaStyles from '../StationArea/index.module.scss';
import styles from './index.module.scss';
import { AlarmLevel, AlarmLevelDisplay } from '@/shared/types';
import { DownOutlined } from '@ant-design/icons';
import CustomTooltip from '@/pages/ControlCenter/components/CustomTooltip';
import LineConnector from '../LineConnector';
import SwitchControl from './SwitchControl';
import ErrorImg from '../../../imgs/error-fill@2x.png';

interface StationInfoProps {
  todayUsage?: number;
  unit?: string;
  status?: AlarmLevel;
  openedValve?: number;
  totalValve?: number;
  openedPump?: number;
  totalPump?: number;
}
interface StationItemProps {
  sceneType?: SceneTypeEnum;
  stationType?: StationEnum;
  stationName?: string;
  stationInfo?: StationInfoProps;
  points?: any;
}
const StationItem = ({ stationType, stationName, stationInfo, points, sceneType }: StationItemProps) => {
  const startRef = useRef(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const navigate = useNavigate();

  const renderDropDownContent = (stationInfo?: StationInfoProps) => {
    return (
      <>
        {/* 使用 SwitchControl 渲染阀门 */}
        {stationInfo?.totalValve && (
          <SwitchControl
            totalCount={stationInfo.totalValve}
            openedCount={stationInfo.openedValve || 0}
            label="阀门"
            onStateChange={openedCount => {
              stationInfo.openedValve = openedCount;
              forceUpdate();
            }}
          />
        )}
        {/* 使用 SwitchControl 渲染泵 */}
        {stationInfo?.totalPump && (
          <SwitchControl
            totalCount={stationInfo.totalPump}
            openedCount={stationInfo.openedPump || 0}
            label="泵"
            onStateChange={openedCount => {
              stationInfo.openedPump = openedCount;
              forceUpdate();
            }}
          />
        )}
      </>
    );
  };

  // 点击页面跳转
  const handleClick = () => {
    if (stationType === StationEnum.GAS) {
      if (sceneType === SceneTypeEnum.AIR_COMPRESSOR_STATION) {
        navigate('/production/manage/gasMachineDashBoard');
      } else if (sceneType === SceneTypeEnum.INDUSTRIAL_GAS) {
        navigate('/energyAssets/energyMediumDashboard/industrialGas');
      } else {
        navigate('/energyAssets/energyMediumDashboard/gas');
      }
      return;
    }
    if (stationType === StationEnum.ELECTRICITY) {
      window.open('/scada/390', '_blank');
      return;
    }

    if (stationType === StationEnum.WATER_PUMP) {
      return navigate(`/energyAssets/energyMediumDashboard/water`);
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: `${points?.from?.x}%`,
        top: `${points?.from?.y}%`,
      }}
    >
      <div
        ref={startRef}
        className={`${StationAreaStyles.stationItem} 
          ${stationType == StationEnum.GAS && StationAreaStyles.gasStation} 
          ${stationType == StationEnum.ELECTRICITY && StationAreaStyles.electricityStation} 
          ${stationType == StationEnum.WATER_PUMP && StationAreaStyles.waterPumpStation}`}
        style={{ color: stationInfo?.status ? '#E64242' : 'inherit' }}
        onClick={handleClick}
      >
        {stationName}
        {stationInfo?.status && (
          <>
            <img className={StationAreaStyles.errorIcon} src={ErrorImg} alt="error" />
            <div className={StationAreaStyles.errorItem}></div>
          </>
        )}
      </div>
      <div className={styles.stationInfo}>
        <div>
          今日用量
          <span style={{ color: '#FAAD14' }}>
            {stationInfo?.todayUsage || '--'}
            {stationInfo?.unit || '--'}
          </span>
        </div>
        <div>
          报警状态
          <span style={{ color: stationInfo?.status ? '#E64242' : 'rgba(255,255,255,0.3)' }}>
            {stationInfo?.status ? AlarmLevelDisplay[stationInfo.status] : '无报警'}
          </span>
        </div>
        {stationInfo?.totalValve && (
          <div>
            阀门(开启/总)
            <span style={{ color: ' #FFFFFF' }}>
              <span style={{ color: '#52C41A' }}>{stationInfo.openedValve}</span>/{stationInfo.totalValve}
            </span>
          </div>
        )}
        {stationInfo?.totalPump && (
          <div>
            泵(开启/总)
            <span style={{ color: ' #FFFFFF' }}>
              <span style={{ color: '#52C41A' }}>{stationInfo.openedPump}</span>/{stationInfo.totalPump}
            </span>
          </div>
        )}
        {(stationInfo?.totalPump || stationInfo?.totalValve) && (
          <CustomTooltip
            trigger={'click'}
            className={styles.dropdownContent}
            title={renderDropDownContent(stationInfo)}
          >
            <div className={styles.dropdownSwitch}>
              <DownOutlined size={12} />
            </div>
          </CustomTooltip>
        )}
      </div>
      {points && (
        <LineConnector
          startRef={startRef}
          startPoint={points.from}
          endPoint={points.to}
          placement={points.placement}
          bends={points?.bends || []}
        />
      )}
    </div>
  );
};
export default StationItem;
