import { keepTwoNull } from '@/pages/ZYFDashboard/utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';

export interface ChartsData {
  name: string;
  value: number;
}

const seriesOption = {
  type: 'bar',
  label: {
    show: true,
    position: 'top',
    color: '#16DD8E',
  },
  emphasis: {
    focus: 'series',
  },
  barWidth: 20,
  itemStyle: {
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: 'rgba(74,255,127,0.9)', // 0% 处的颜色
        },
        {
          offset: 0.65,
          color: 'rgba(74,255,127,0.5)', // 65% 处的颜色
        },
        {
          offset: 1,
          color: 'rgba(74,255,127,0)', // 100% 处的颜色
        },
      ],
      global: false, // 缺省为 false
    },
  },
};

export const getChartOption = (data: ChartsData[], tabActiveKey: string) => {
  let max = 44;
  let unit = 't';
  if (tabActiveKey === 'm³') {
    max = 24000;
    unit = 'm³';
  }

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { data, marker } = item;
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;">${marker}</span>${axisValue} : &nbsp;${
            data?.value || data?.value === 0 ? data?.value : '-'
          }${unit}</div>`;
          str = dayjs().format('YYYY-MM-DD') + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 10,
      right: 10,
      top: 40,
      bottom: 10,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: data?.map(item => item.name),
        axisLabel: {
          interval: 0,
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          fontSize: 12,
          width: 42, // 设置标签的固定宽度
          overflow: 'truncate', // 超出部分显示省略号
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: unit,
        max: max,
        min: 0,
        interval: max / 4,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    series: [
      {
        ...seriesOption,
        data,
      },
    ],
  };
};
