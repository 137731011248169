import {
  EllipsisSpan,
  Table,
  Button,
  Wrapper,
  Paging,
  usePaging,
  useBreadcrumbRoutes,
  Form,
  Modal,
  CustomFilter,
  DatePicker,
  Tag,
} from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { meterProjectPagingItem } from '@/api/productionConsumptionBoard';

import dayjs from 'dayjs';

type SearchParams = {
  alarmDateRange?: any;
};

const { RangePicker } = DatePicker;

const originMockData = [
  {
    id: 1,
    escalationTime: '2025年03月19日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2025年3月',
    kpiChild: '天然气',
    status: 1,
    kpiTarget: '7,800m³',
    alertType: '消耗率超标',
    alertThreshold: '87%(消耗率/时间)',
    realValue: '7,150m³',
  },
  {
    id: 2,
    escalationTime: '2025年03月05日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2025年3月',
    kpiChild: '氧气',
    status: 1,
    kpiTarget: '95t',
    alertType: '消耗率超标',
    alertThreshold: '85%(消耗率/时间)',
    realValue: '83.6t',
  },
  {
    id: 3,
    escalationTime: '2025年02月17日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2025年2月',
    kpiChild: '水',
    status: 1,
    kpiTarget: '3,500m³',
    alertType: '消耗率超标',
    alertThreshold: '86%(消耗率/时间)',
    realValue: '3,150m³',
  },
  {
    id: 4,
    escalationTime: '2025年02月03日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2025年3月',
    kpiChild: '水',
    status: 1,
    kpiTarget: '2,200m³',
    alertType: '消耗率超标',
    alertThreshold: '82%(消耗率/时间)',
    realValue: '1,890m³',
  },
  {
    id: 5,
    escalationTime: '2025年01月14日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2025年1月',
    kpiChild: '压缩空气',
    status: 0,
    kpiTarget: '28,000m³',
    alertType: '消耗率超标',
    alertThreshold: '76%(消耗率/时间)',
    realValue: '22,300m³',
  },
  {
    id: 6,
    escalationTime: '2025年01月09日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2025年3月',
    kpiChild: '压缩空气',
    status: 0,
    kpiTarget: '42,000m³',
    alertType: '消耗率超标',
    alertThreshold: '77%(消耗率/时间)',
    realValue: '34,000m³',
  },
  {
    id: 7,
    escalationTime: '2024年12月28日',
    kpiType: '年度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '电',
    status: 1,
    kpiTarget: '950,000kWh',
    alertType: '消耗率超标',
    alertThreshold: '89%(消耗率/时间)',
    realValue: '872,000kWh',
  },
  {
    id: 8,
    escalationTime: '2024年11月28日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '二氧化碳',
    status: 0,
    kpiTarget: '55t',
    alertType: '消耗率超标',
    alertThreshold: '82%(消耗率/时间)',
    realValue: '47.3t',
  },
  {
    id: 9,
    escalationTime: '2024年11月05日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '氮气',
    status: 1,
    kpiTarget: '32t',
    alertType: '消耗率超标',
    alertThreshold: '81%(消耗率/时间)',
    realValue: '27.5t',
  },
  {
    id: 10,
    escalationTime: '2024年10月30日',
    kpiType: '年度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '电',
    status: 1,
    kpiTarget: '1,200,000kWh',
    alertType: '消耗率超标',
    alertThreshold: '93%(消耗率/时间)',
    realValue: '1,134,000kWh',
  },
  {
    id: 11,
    escalationTime: '2024年10月11日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2024年10月',
    kpiChild: '氧气',
    status: 1,
    kpiTarget: '85t',
    alertType: '消耗率超标',
    alertThreshold: '88%(消耗率/时间)',
    realValue: '79.3t',
  },
  {
    id: 12,
    escalationTime: '2024年09月18日',
    kpiType: '年度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '二氧化碳',
    status: 0,
    kpiTarget: '48t',
    alertType: '消耗率超标',
    alertThreshold: '72%(消耗率/时间)',
    realValue: '38.6t',
  },
  {
    id: 13,
    escalationTime: '2024年08月22日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2024年8月',
    kpiChild: '氮气',
    status: 1,
    kpiTarget: '45t',
    alertType: '消耗率超标',
    alertThreshold: '79%(消耗率/时间)',
    realValue: '37.8t',
  },
  {
    id: 14,
    escalationTime: '2024年08月09日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2024年9月',
    kpiChild: '天然气',
    status: 1,
    kpiTarget: '6,500m³',
    alertType: '消耗率超标',
    alertThreshold: '85%(消耗率/时间)',
    realValue: '5,980m³',
  },
  {
    id: 15,
    escalationTime: '2024年07月22日',
    kpiType: '月度能耗计划KPI',
    assessmentPeriod: '2024年7月',
    kpiChild: '水',
    status: 1,
    kpiTarget: '1,800m³',
    alertType: '消耗率超标',
    alertThreshold: '78%(消耗率/时间)',
    realValue: '1,520m³',
  },
  {
    id: 16,
    escalationTime: '2024年07月07日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2024年9月',
    kpiChild: '氧气',
    status: 1,
    kpiTarget: '120t',
    alertType: '消耗率超标',
    alertThreshold: '84%(消耗率/时间)',
    realValue: '108t',
  },
  {
    id: 17,
    escalationTime: '2024年06月30日',
    kpiType: '年度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '天然气',
    status: 1,
    kpiTarget: '9,200m³',
    alertType: '消耗率超标',
    alertThreshold: '90%(消耗率/时间)',
    realValue: '8,740m³',
  },
  {
    id: 18,
    escalationTime: '2024年06月15日',
    kpiType: '季度能耗计划KPI',
    assessmentPeriod: '2024年6月',
    kpiChild: '压缩空气',
    status: 0,
    kpiTarget: '35,000m³',
    alertType: '消耗率超标',
    alertThreshold: '83%(消耗率/时间)',
    realValue: '30,450m³',
  },
  {
    id: 19,
    escalationTime: '2024年05月25日',
    kpiType: '年度能耗计划KPI',
    assessmentPeriod: '2024年12月',
    kpiChild: '二氧化碳',
    status: 0,
    kpiTarget: '65t',
    alertType: '消耗率超标',
    alertThreshold: '91%(消耗率/时间)',
    realValue: '62.4t',
  },
];

const columns: any = [
  {
    title: '序号',
    ellipsis: true,
    dataIndex: 'id',
    width: 60,
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '上报时间',
    ellipsis: true,
    dataIndex: 'escalationTime',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'KPI类型',
    ellipsis: true,
    dataIndex: 'kpiType',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    ellipsis: true,
    dataIndex: 'status',
    render: (v: number) => (
      <EllipsisSpan
        value={
          v === 1 ? (
            <Tag border="solid" color="success">
              已确认
            </Tag>
          ) : (
            <Tag border="solid" color="warning">
              待确认
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '考核时段',
    ellipsis: true,
    dataIndex: 'assessmentPeriod',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'KPI子项',
    dataIndex: 'kpiChild',
    ellipsis: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'KPI目标',
    dataIndex: 'kpiTarget',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '预警类型',
    ellipsis: true,
    dataIndex: 'alertType',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '预警阀值',
    ellipsis: true,
    dataIndex: 'alertThreshold',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '实际发生值',
    ellipsis: true,
    dataIndex: 'realValue',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface FilterParams extends Omit<meterProjectPagingItem, 'page' | 'size'> {}

const KPIAlarmRecord: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { setTotalCount, setPageOffset } = pagingInfo;
  const [tableList, setTableList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [mockData, setMockData] = useState<any[]>(originMockData);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, contextHolder] = Modal.useModal();

  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    // @ts-ignore
    const mockList = JSON.parse(window?.kpiAlarmData ?? '[]');
    setTotalCount(mockList.length ?? 0);
    setTableList(mockList.map((m: any, index: number) => ({ num: index + 1, ...m })));
    setMockData(mockList);
  }, []);

  const onFinish = (val: any) => {
    const { alarmDateRange } = val;
    setSearchParams(val);
    setLoading(true);
    setTimeout(() => {
      if (alarmDateRange && alarmDateRange.length === 2) {
        const startDate = dayjs(alarmDateRange[0]);
        const endDate = dayjs(alarmDateRange[1]);

        // 筛选符合日期范围的数据
        const filteredData = mockData.filter(item => {
          const escalationDate = dayjs(item.escalationTime, 'YYYY年MM月DD日');
          return escalationDate.isBetween(startDate, endDate, null, '[]'); // [] 表示包含边界
        });

        setTotalCount(filteredData.length);
        setTableList(filteredData.map((m, index) => ({ num: index + 1, ...m })));
      } else {
        // 如果没有选择日期，则恢复所有数据
        setTotalCount(mockData.length);
        setTableList(mockData.map((m, index) => ({ num: index + 1, ...m })));
      }
      setLoading(false);
    }, 200);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
    navigate(`?`);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 140,
      render: (v: any, record: any) => {
        return record.status === 1 ? (
          <Button type="link" disabled>
            已确认
          </Button>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                setTableList(prevList => prevList.map(item => (item.id === record.id ? { ...item, status: 1 } : item)));
              }, 200);
            }}
          >
            确认
          </Button>
        );
      },
    },
  ];

  return (
    <Wrapper
      className="page_wrapper"
      filters={
        <div>
          <CustomFilter<FilterParams> colSpan={6} form={form} onFinish={onFinish} onReset={onReset}>
            <Form.Item name="alarmDateRange" label="触发报警日期">
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </CustomFilter>
        </div>
      }
      routes={[...(breadcrumbRoutes?.routes ?? [])]}
    >
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Button
          type="primary"
          onClick={() => {
            navigate(`/energy/analysis/plustekEnergyPlan`);
          }}
        >
          报警规则设置
        </Button>
      </div>
      <Table
        rowKey={'id'}
        sticky
        loading={loading}
        scroll={{ x: 1300 }}
        dataSource={tableList}
        columns={buildColumns}
        style={{ marginTop: 10 }}
      />

      <Paging pagingInfo={pagingInfo} />

      {contextHolder}
    </Wrapper>
  );
};

export default KPIAlarmRecord;
