import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import settingImg from '../../imgs/button-setting@2x.png';
import fullscreenImg from '../../imgs/button-fullscreen@2x.png';
import exitImg from '../../imgs/button-exit@2x.png';

export enum TabEnum {
  OVERVIEW = 'overview',
  GAS = 'gas',
  LIGHTING = 'lighting',
}

interface IHeaderProps {
  fullBtn: () => void;
  isFullScreen: boolean;
  selectedTab: TabEnum;
  setSelectedTab: (tab: TabEnum) => void;
}

const Header: FC<IHeaderProps> = props => {
  const { fullBtn, isFullScreen, selectedTab, setSelectedTab } = props;
  const [currentTime, setCurrentTime] = useState<string>();
  useRequest(
    async () => {
      setCurrentTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    },
    {
      pollingInterval: 1000,
    }
  );

  return (
    <div className={styles.header_area_box}>
      <div className={styles.title_box}>外高桥无人化集控中心</div>
      <div className={styles.tab_box}>
        <div
          className={`${styles.tab_item} ${selectedTab === TabEnum.OVERVIEW && styles.tab_active}`}
          onClick={() => setSelectedTab(TabEnum.OVERVIEW)}
        >
          调度总览
        </div>
        <div
          className={`${styles.tab_item} ${selectedTab === TabEnum.GAS && styles.tab_active}`}
          onClick={() => setSelectedTab(TabEnum.GAS)}
        >
          末端用气
        </div>
        <div
          className={`${styles.tab_item} ${selectedTab === TabEnum.LIGHTING && styles.tab_active}`}
          onClick={() => setSelectedTab(TabEnum.LIGHTING)}
        >
          公区照明
        </div>
        <div className={styles.tab_item}>...</div>
      </div>
      <div className={styles.set_box}>
        {/* 全屏按钮 */}
        <div className={styles.refreshTimeSty}>{currentTime}</div>
        <div className={styles.btn}>
          <img src={settingImg} width={57} alt="配置" />
        </div>

        <div className={styles.btn} onClick={fullBtn}>
          {isFullScreen ? (
            <img src={exitImg} width={57} alt="退出" />
          ) : (
            <img src={fullscreenImg} width={57} alt="退出" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
