// src/pages/ControlCenter/index.tsx
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { cancelFullscreen, goFullscreen } from './utils';
import Header, { TabEnum } from './components/Header';
import DispatchOverviewTab from './DispatchOverviewTab';
import UseGasTab from './UseGasTab';
import LightingTab from './LightingTab';

const ControlCenter: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<TabEnum>(TabEnum.OVERVIEW);
  const innerDivRef = useRef<HTMLDivElement>();
  const baseDivRef = useRef<HTMLDivElement>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false); // 是否全屏

  const fullBtn = () => {
    if (baseDivRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(baseDivRef.current);
    }
  };

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  return (
    <div
      className={styles.outer_wrap}
      id="full_screen_box"
      style={{
        overflow: 'hidden',
      }}
      ref={ref => {
        if (ref) {
          baseDivRef.current = ref;
        }
      }}
    >
      <div
        className={styles.inner}
        ref={ref => {
          if (ref) {
            innerDivRef.current = ref;
          }
        }}
      >
        {/* 上方header区域 */}
        <div className={styles.inner_top}>
          <Header
            fullBtn={fullBtn}
            isFullScreen={isFullScreen}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        {/* 中间content区域 */}
        {selectedTab === TabEnum.OVERVIEW && <DispatchOverviewTab setSelectedTab={setSelectedTab} />}
        {selectedTab === TabEnum.GAS && <UseGasTab setSelectedTab={setSelectedTab} />}
        {selectedTab === TabEnum.LIGHTING && <LightingTab setSelectedTab={setSelectedTab} />}
      </div>
    </div>
  );
};

export default ControlCenter;
