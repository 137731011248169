import { keepTwoNull } from '@/pages/ZYFDashboard/utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';

export interface ChartsData {
  name: string;
  list: {
    value: number;
  }[];
}

// 生成时间轴数据
export const generateTimeAxis = (days: number) => {
  const today = dayjs();
  const timeAxis = [];
  for (let i = days - 1; i >= 0; i--) {
    timeAxis.push(today.subtract(i, 'day').format('YYYY-MM-DD'));
  }
  return timeAxis;
};

export const getChartOption = (data?: ChartsData[]) => {
  //  生成示例数据
  const timeAxis = generateTimeAxis(30); // 生成30天的时间轴

  const areaColors = [
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(74,255,127,0.15)' },
      { offset: 1, color: 'rgba(74,255,127,0.05)' },
    ]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(255,71,123,0.15)' },
      { offset: 1, color: 'rgba(255,71,123,0.05)' },
    ]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(255, 228, 74,0.15)' },
      { offset: 1, color: 'rgba(255, 228, 74,0.05)' },
    ]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(0,173,255,0.15)' },
      { offset: 1, color: 'rgba(0,173,255,0.05)' },
    ]),
  ];

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      position: function (point: any, params: any, dom: any, rect: any, size: any) {
        var x = 0; // x坐标位置
        var y = 0; // y坐标位置

        // 当前鼠标位置
        var pointX = point[0];
        var pointY = point[1];

        // 提示框大小
        var boxWidth = size.contentSize[0];
        var boxHeight = size.contentSize[1];

        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = boxWidth / 2;
        } else {
          // 左边放的下
          x = pointX - boxWidth - 10;
        }

        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = boxHeight / 2;
        } else {
          // 上边放得下
          y = pointY - boxHeight - 10;
        }
        return [x, y];
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM-DD');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data } = item;
          const unit = seriesName === '天然气' || seriesName === '压缩空气' ? 'm³' : 'kg';
          otherStr += `<div style="margin-top:2px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data || '-'}${unit}</div>`;
          str = firstStr + otherStr;
        });
        return str;
      },
    },
    grid: {
      left: 10,
      right: 10,
      top: 60,
      bottom: 10,
      containLabel: true,
    },
    color: ['#4AFF7F', '#FF477B', '#FFE44A', '#00ADFF'],
    legend: {
      right: 10,
      textStyle: {
        color: 'rgba(255, 255, 255, 0.85)',
      },
      inactiveColor: 'rgba(255, 255, 255, 0.45)',
      itemWidth: 16,
      itemHeight: 16,
      data: data?.map(item => ({
        name: item.name,
        icon: 'rect',
      })),
    },
    xAxis: [
      {
        type: 'category',
        data: timeAxis,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (value: number) {
            return dayjs(value).format('MM-DD');
          },
          fontSize: 14,
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'm³',
        max: 3600,
        min: 0,
        interval: 3600 / 4,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
      {
        type: 'value',
        name: 'kg',
        max: 3600,
        min: 0,
        interval: 3600 / 4,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    series: data?.map((item, index) => ({
      name: item.name,
      type: 'line',
      data: item?.list.map(i => keepTwoNull(i.value)),
      connectNulls: true,
      smooth: true,
      symbol: 'circle',
      symbolSize: 2,
      showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
      itemStyle: {
        // symbol样式
        normal: {
          borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
          borderWidth: 6, // symbol边框宽度
        },
      },
      lineStyle: {
        width: 2,
        type: 'solid', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
      },
      areaStyle: {
        color: areaColors[index % areaColors.length],
      },
    })),
  };
};
