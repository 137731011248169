import React, { useState } from 'react';
import styles from './index.module.scss';
import { StationEnum, StationEnumDisplay } from './type';
import StationItem from '../StationItem';
import { stationList } from '@/pages/ControlCenter/data';

const StationArea = () => {
  const [selectedTabs, setSelectedTabs] = useState<StationEnum[]>([StationEnum.GAS]);

  // 点击事件处理函数
  const handleTabClick = (stationType: StationEnum) => {
    let result: StationEnum[] = [];
    if (selectedTabs.includes(stationType)) {
      // 如果已经选中，则移除
      result = selectedTabs.filter(tab => tab !== stationType);
    } else {
      // 如果未选中，则添加
      result = [...selectedTabs, stationType];
    }
    setSelectedTabs(result);
  };

  return (
    <div className={styles.stationArea}>
      <div className={styles.stationTab}>
        {Object.entries(StationEnumDisplay).map(([key, value]) => {
          const stationType = Number(key) as unknown as StationEnum;
          return (
            <div
              key={key}
              onClick={() => handleTabClick(stationType)}
              className={`${styles.stationItem} 
              ${stationType === StationEnum.GAS && styles.gasStation} 
              ${stationType === StationEnum.ELECTRICITY && styles.electricityStation} 
              ${stationType === StationEnum.WATER_PUMP && styles.waterPumpStation} 
              ${selectedTabs.includes(stationType) && styles.stationDisabled}`}
            >
              {value}
            </div>
          );
        })}
      </div>
      <div className={styles.stationList}>
        {stationList.map(station => {
          if (selectedTabs.includes(station.stationType)) return null;
          return (
            <StationItem
              stationType={station.stationType}
              stationName={station.stationName}
              stationInfo={station.stationInfo}
              sceneType={station.sceneType}
              points={station.points}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StationArea;
