import { Tooltip } from '@maxtropy/components';
import { RenderFunction } from 'antd/es/_util/getRenderPropValue';
interface Iprops {
  title?: React.ReactNode | RenderFunction;
  children?: React.ReactNode;
  className?: string;
  trigger?: 'click' | 'hover' | 'focus' | 'contextMenu' | ('click' | 'hover' | 'focus' | 'contextMenu')[];
}
/**
 * @descripe
 * 兼容全屏状态下popup框不显示问题
 * @param props
 * @returns
 */
const CustomTooltip = (props: Iprops) => {
  return (
    <Tooltip
      getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
      title={props.title}
      overlayClassName={props.className}
      arrow={false}
      trigger={props.trigger ?? ['hover']}
    >
      {props.children}
    </Tooltip>
  );
};

export default CustomTooltip;
