export const mockData = {
  oeeRateInfo: {
    lessThan50: 1,
    moreThan50: 4,
    moreThan80: 1,
    other: 0,
  },
  baseOeeInfo: [
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
      deviceName: '冲砂设备1',
      loadTime: 998,
      oee: 83.3,
      timeOee: 92.8,
      runTime: 12.04,
      standbyRunTime: 1.39,
      performanceOee: 85.8,
      standByTime: 1.06,
      downTime: 0.6,
      yieldRate: 95.8,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
      deviceName: '冲砂设备2',
      loadTime: 998,
      oee: 85.6,
      timeOee: 93.5,
      runTime: 12.02,
      performanceOee: 90.3,
      standbyRunTime: 0.53,
      standByTime: 1.22,
      downTime: 1.08,
      yieldRate: 91.5,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
      deviceName: '喷涂设备1',
      loadTime: 998,
      oee: 85.8,
      timeOee: 94.2,
      runTime: 11.74,
      performanceOee: 92.1,
      standbyRunTime: 1.17,
      standByTime: 1.66,
      downTime: 1.06,
      yieldRate: 94.6,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
      deviceName: '除湿机设备1',
      loadTime: 998,
      oee: 86.8,
      timeOee: 92.5,
      runTime: 11.54,
      performanceOee: 93.1,
      standbyRunTime: 1.27,
      standByTime: 1.26,
      downTime: 1.06,
      yieldRate: 96.5,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
      deviceName: '除湿机设备2',
      loadTime: 998,
      oee: 89.5,
      timeOee: 91.9,
      runTime: 11.44,
      performanceOee: 92.9,
      standbyRunTime: 1.27,
      standByTime: 1.68,
      downTime: 1.16,
      yieldRate: 94.8,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
      deviceName: '喷涂设备2',
      loadTime: 998,
      oee: 88.9,
      performanceOee: 92.8,
      timeOee: 90.6,
      runTime: 11.74,
      standbyRunTime: 1.77,
      standByTime: 1.86,
      downTime: 1.54,
      yieldRate: 95.6,
    },
  ],
  oeePercentList: [
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
      deviceName: '喷涂设备1',
      percent: 87,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
      deviceName: '冲砂设备2',
      percent: 82,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
      deviceName: '冲砂设备1',
      percent: 80,
    },
  ],
  shutdownTimeList: [
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
      deviceName: '喷涂设备2',
      time: 2.46,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
      deviceName: '除湿机设备2',
      time: 2.11,
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
      deviceName: '除湿机设备1',
      time: 1.87,
    },
    {
      deviceId: '825d4f1f-0644-4a06-9abd-f685e6632e1e1',
      deviceName: '喷涂设备1',
      time: 1.87,
    },
    {
      deviceId: '825d4f1f-0644-4a06-9abd-f685e6632e1e1',
      deviceName: '冲砂设备1',
      time: 1.85,
    },
    {
      deviceId: '825d4f1f-0644-4a06-9abd-f685e6632e1e1',
      deviceName: '冲砂设备2',
      time: 1.83,
    },
  ],
  timedInfoList: [
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
      deviceName: '冲砂设备1',
      timedInfoList: [
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-01T10:00:00Z',
          oee: 57.2,
          timeOee: 79.3,
          performanceOee: 82.4,
          yieldRate: 91.7,
          plannedShutdownTime: 1.6,
          unplannedShutdownTime: 0.8,
          idling: 16.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-02T10:00:00Z',
          oee: 54.6,
          timeOee: 77.1,
          performanceOee: 80.9,
          yieldRate: 90.2,
          plannedShutdownTime: 1.4,
          unplannedShutdownTime: 1.2,
          idling: 19.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-03T10:00:00Z',
          oee: 53.8,
          timeOee: 76.5,
          performanceOee: 80.5,
          yieldRate: 89.8,
          plannedShutdownTime: 1.5,
          unplannedShutdownTime: 1.3,
          idling: 18.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-04T10:00:00Z',
          oee: 64.1,
          timeOee: 85.7,
          performanceOee: 85.3,
          yieldRate: 93.5,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.6,
          idling: 14.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-05T10:00:00Z',
          oee: 68.3,
          timeOee: 88.9,
          performanceOee: 86.9,
          yieldRate: 94.3,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.4,
          idling: 12.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-06T10:00:00Z',
          oee: 71.7,
          timeOee: 91.2,
          performanceOee: 89.3,
          yieldRate: 95.1,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.2,
          idling: 9.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-07T10:00:00Z',
          oee: 70.2,
          timeOee: 89.8,
          performanceOee: 88.1,
          yieldRate: 94.6,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.5,
          idling: 11.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-08T10:00:00Z',
          oee: 66.4,
          timeOee: 86.7,
          performanceOee: 85.8,
          yieldRate: 93.0,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 1.1,
          idling: 13.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-09T10:00:00Z',
          oee: 56.2,
          timeOee: 77.8,
          performanceOee: 81.0,
          yieldRate: 90.3,
          plannedShutdownTime: 1.2,
          unplannedShutdownTime: 1.1,
          idling: 17.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-10T10:00:00Z',
          oee: 54.9,
          timeOee: 77.0,
          performanceOee: 80.7,
          yieldRate: 89.5,
          plannedShutdownTime: 1.3,
          unplannedShutdownTime: 1.0,
          idling: 18.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-11T10:00:00Z',
          oee: 73.1,
          timeOee: 92.3,
          performanceOee: 90.2,
          yieldRate: 96.0,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 8.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-12T10:00:00Z',
          oee: 75.8,
          timeOee: 93.7,
          performanceOee: 91.5,
          yieldRate: 96.6,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 7.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-13T10:00:00Z',
          oee: 74.2,
          timeOee: 92.9,
          performanceOee: 91.0,
          yieldRate: 96.2,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.3,
          idling: 8.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-14T10:00:00Z',
          oee: 70.8,
          timeOee: 90.5,
          performanceOee: 89.5,
          yieldRate: 95.5,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.4,
          idling: 10.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-15T10:00:00Z',
          oee: 77.1,
          timeOee: 94.5,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 6.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-16T10:00:00Z',
          oee: 59.3,
          timeOee: 80.1,
          performanceOee: 82.5,
          yieldRate: 91.6,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.9,
          idling: 16.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-17T10:00:00Z',
          oee: 57.9,
          timeOee: 78.7,
          performanceOee: 81.7,
          yieldRate: 90.1,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 1.2,
          idling: 17.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-18T10:00:00Z',
          oee: 77.6,
          timeOee: 94.8,
          performanceOee: 92.0,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.1,
          idling: 7.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-19T10:00:00Z',
          oee: 79.9,
          timeOee: 95.1,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-20T10:00:00Z',
          oee: 81.5,
          timeOee: 95.3,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-21T10:00:00Z',
          oee: 82.9,
          timeOee: 95.5,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-22T10:00:00Z',
          oee: 80.9,
          timeOee: 95.0,
          performanceOee: 91.7,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.1,
          idling: 6.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-23T10:00:00Z',
          oee: 63.2,
          timeOee: 81.9,
          performanceOee: 83.3,
          yieldRate: 92.2,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.8,
          idling: 15.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-24T10:00:00Z',
          oee: 61.0,
          timeOee: 80.5,
          performanceOee: 82.1,
          yieldRate: 90.9,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 1.1,
          idling: 16.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-25T10:00:00Z',
          oee: 83.0,
          timeOee: 95.7,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-26T10:00:00Z',
          oee: 83.0,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-27T10:00:00Z',
          oee: 82.6,
          timeOee: 95.4,
          performanceOee: 91.9,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-28T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-29T10:00:00Z',
          oee: 82.3,
          timeOee: 95.2,
          performanceOee: 91.6,
          yieldRate: 96.6,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.1,
          idling: 5.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-30T10:00:00Z',
          oee: 66.3,
          timeOee: 85.1,
          performanceOee: 85.0,
          yieldRate: 93.4,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.6,
          idling: 13.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade01',
          deviceName: '冲砂设备1',
          ts: '2024-03-31T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
      ],
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
      deviceName: '冲砂设备2',
      timedInfoList: [
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-01T10:00:00Z',
          oee: 56.3,
          timeOee: 78.5,
          performanceOee: 82.1,
          yieldRate: 91.4,
          plannedShutdownTime: 1.7,
          unplannedShutdownTime: 0.9,
          idling: 17.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-02T10:00:00Z',
          oee: 53.1,
          timeOee: 76.2,
          performanceOee: 80.5,
          yieldRate: 89.7,
          plannedShutdownTime: 1.5,
          unplannedShutdownTime: 1.3,
          idling: 19.8,
        }, // 周六
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-03T10:00:00Z',
          oee: 52.8,
          timeOee: 75.9,
          performanceOee: 80.3,
          yieldRate: 90.1,
          plannedShutdownTime: 1.6,
          unplannedShutdownTime: 1.4,
          idling: 18.6,
        }, // 周日
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-04T10:00:00Z',
          oee: 63.4,
          timeOee: 85.2,
          performanceOee: 85.7,
          yieldRate: 93.8,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.7,
          idling: 14.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-05T10:00:00Z',
          oee: 67.9,
          timeOee: 88.7,
          performanceOee: 87.2,
          yieldRate: 94.1,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.3,
          idling: 12.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-06T10:00:00Z',
          oee: 71.2,
          timeOee: 91.4,
          performanceOee: 89.6,
          yieldRate: 95.3,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.2,
          idling: 9.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-07T10:00:00Z',
          oee: 69.5,
          timeOee: 89.6,
          performanceOee: 88.3,
          yieldRate: 94.7,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.6,
          idling: 11.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-08T10:00:00Z',
          oee: 65.8,
          timeOee: 86.3,
          performanceOee: 86.1,
          yieldRate: 93.2,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.8,
          idling: 13.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-09T10:00:00Z',
          oee: 55.7,
          timeOee: 77.4,
          performanceOee: 81.2,
          yieldRate: 90.5,
          plannedShutdownTime: 1.3,
          unplannedShutdownTime: 1.2,
          idling: 17.8,
        }, // 周六
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-10T10:00:00Z',
          oee: 54.2,
          timeOee: 76.8,
          performanceOee: 80.8,
          yieldRate: 89.9,
          plannedShutdownTime: 1.4,
          unplannedShutdownTime: 1.1,
          idling: 18.4,
        }, // 周日
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-11T10:00:00Z',
          oee: 72.6,
          timeOee: 92.1,
          performanceOee: 90.5,
          yieldRate: 96.2,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.1,
          idling: 8.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-12T10:00:00Z',
          oee: 75.3,
          timeOee: 93.5,
          performanceOee: 91.8,
          yieldRate: 96.8,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 7.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-13T10:00:00Z',
          oee: 73.8,
          timeOee: 92.8,
          performanceOee: 91.2,
          yieldRate: 96.4,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.4,
          idling: 9.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-14T10:00:00Z',
          oee: 70.1,
          timeOee: 90.3,
          performanceOee: 89.7,
          yieldRate: 95.7,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.5,
          idling: 10.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-15T10:00:00Z',
          oee: 76.4,
          timeOee: 94.2,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 6.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-16T10:00:00Z',
          oee: 58.9,
          timeOee: 79.6,
          performanceOee: 82.7,
          yieldRate: 91.8,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 1.0,
          idling: 16.3,
        }, // 周六
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-17T10:00:00Z',
          oee: 57.4,
          timeOee: 78.3,
          performanceOee: 81.9,
          yieldRate: 90.3,
          plannedShutdownTime: 1.2,
          unplannedShutdownTime: 1.3,
          idling: 17.5,
        }, // 周日
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-18T10:00:00Z',
          oee: 77.2,
          timeOee: 94.7,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 7.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-19T10:00:00Z',
          oee: 79.5,
          timeOee: 95.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-20T10:00:00Z',
          oee: 81.3,
          timeOee: 95.2,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-21T10:00:00Z',
          oee: 82.7,
          timeOee: 95.4,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-22T10:00:00Z',
          oee: 80.6,
          timeOee: 94.9,
          performanceOee: 91.8,
          yieldRate: 96.8,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 6.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-23T10:00:00Z',
          oee: 62.7,
          timeOee: 81.5,
          performanceOee: 83.5,
          yieldRate: 92.4,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.9,
          idling: 15.7,
        }, // 周六
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-24T10:00:00Z',
          oee: 60.5,
          timeOee: 80.2,
          performanceOee: 82.3,
          yieldRate: 91.1,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 1.2,
          idling: 16.9,
        }, // 周日
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-25T10:00:00Z',
          oee: 82.9,
          timeOee: 95.6,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-26T10:00:00Z',
          oee: 83.0,
          timeOee: 95.8,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-27T10:00:00Z',
          oee: 82.4,
          timeOee: 95.3,
          performanceOee: 91.9,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-28T10:00:00Z',
          oee: 83.0,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-29T10:00:00Z',
          oee: 82.1,
          timeOee: 95.1,
          performanceOee: 91.7,
          yieldRate: 96.7,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 6.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-30T10:00:00Z',
          oee: 65.8,
          timeOee: 84.7,
          performanceOee: 85.2,
          yieldRate: 93.6,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.7,
          idling: 14.2,
        }, // 周六
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade02',
          deviceName: '冲砂设备2',
          ts: '2024-03-31T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
      ],
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
      deviceName: '喷涂设备1',
      timedInfoList: [
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-01T10:00:00Z',
          oee: 59.8,
          timeOee: 81.2,
          performanceOee: 84.6,
          yieldRate: 93.2,
          plannedShutdownTime: 1.3,
          unplannedShutdownTime: 0.5,
          idling: 14.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-02T10:00:00Z',
          oee: 56.3,
          timeOee: 78.9,
          performanceOee: 82.1,
          yieldRate: 91.5,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 0.9,
          idling: 17.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-03T10:00:00Z',
          oee: 55.1,
          timeOee: 78.2,
          performanceOee: 81.7,
          yieldRate: 90.8,
          plannedShutdownTime: 1.2,
          unplannedShutdownTime: 1.0,
          idling: 18.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-04T10:00:00Z',
          oee: 66.7,
          timeOee: 87.4,
          performanceOee: 87.2,
          yieldRate: 94.8,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.4,
          idling: 12.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-05T10:00:00Z',
          oee: 70.3,
          timeOee: 89.8,
          performanceOee: 88.9,
          yieldRate: 95.3,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.2,
          idling: 10.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-06T10:00:00Z',
          oee: 72.9,
          timeOee: 91.5,
          performanceOee: 90.2,
          yieldRate: 96.1,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 8.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-07T10:00:00Z',
          oee: 71.5,
          timeOee: 90.3,
          performanceOee: 89.5,
          yieldRate: 95.7,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.3,
          idling: 9.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-08T10:00:00Z',
          oee: 68.2,
          timeOee: 87.9,
          performanceOee: 87.8,
          yieldRate: 94.2,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.6,
          idling: 12.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-09T10:00:00Z',
          oee: 57.4,
          timeOee: 79.5,
          performanceOee: 82.8,
          yieldRate: 91.7,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.8,
          idling: 16.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-10T10:00:00Z',
          oee: 56.2,
          timeOee: 78.8,
          performanceOee: 82.3,
          yieldRate: 91.0,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 0.9,
          idling: 17.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-11T10:00:00Z',
          oee: 74.1,
          timeOee: 92.6,
          performanceOee: 91.1,
          yieldRate: 96.5,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 7.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-12T10:00:00Z',
          oee: 76.8,
          timeOee: 93.9,
          performanceOee: 91.9,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 6.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-13T10:00:00Z',
          oee: 75.3,
          timeOee: 93.2,
          performanceOee: 91.5,
          yieldRate: 96.6,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 7.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-14T10:00:00Z',
          oee: 72.0,
          timeOee: 91.0,
          performanceOee: 90.1,
          yieldRate: 95.9,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.3,
          idling: 9.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-15T10:00:00Z',
          oee: 63.8,
          timeOee: 85.2,
          performanceOee: 86.3,
          yieldRate: 94.1,
          plannedShutdownTime: 1.8,
          unplannedShutdownTime: 0.0,
          idling: 13.7,
        }, // 计划维护日
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-16T10:00:00Z',
          oee: 60.5,
          timeOee: 81.4,
          performanceOee: 83.7,
          yieldRate: 92.8,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.7,
          idling: 15.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-17T10:00:00Z',
          oee: 58.9,
          timeOee: 80.2,
          performanceOee: 83.1,
          yieldRate: 91.5,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.8,
          idling: 16.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-18T10:00:00Z',
          oee: 78.2,
          timeOee: 94.9,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 6.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-19T10:00:00Z',
          oee: 80.1,
          timeOee: 95.2,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-20T10:00:00Z',
          oee: 81.7,
          timeOee: 95.5,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-21T10:00:00Z',
          oee: 82.5,
          timeOee: 95.7,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-22T10:00:00Z',
          oee: 71.3,
          timeOee: 89.1,
          performanceOee: 89.3,
          yieldRate: 89.3,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.5,
          idling: 10.2,
        }, // 质量异常日
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-23T10:00:00Z',
          oee: 64.2,
          timeOee: 83.6,
          performanceOee: 85.1,
          yieldRate: 93.7,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.6,
          idling: 14.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-24T10:00:00Z',
          oee: 62.0,
          timeOee: 82.1,
          performanceOee: 84.3,
          yieldRate: 92.4,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.7,
          idling: 15.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-25T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-26T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-27T10:00:00Z',
          oee: 82.8,
          timeOee: 95.8,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-28T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-29T10:00:00Z',
          oee: 82.4,
          timeOee: 95.5,
          performanceOee: 91.8,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.1,
          idling: 5.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-30T10:00:00Z',
          oee: 68.9,
          timeOee: 87.2,
          performanceOee: 86.8,
          yieldRate: 94.5,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.4,
          idling: 12.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade03',
          deviceName: '喷涂设备1',
          ts: '2024-03-31T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
      ],
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
      deviceName: '喷涂设备2',
      timedInfoList: [
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-01T10:00:00Z',
          oee: 63.5,
          timeOee: 84.8,
          performanceOee: 86.5,
          yieldRate: 94.5,
          plannedShutdownTime: 1.2,
          unplannedShutdownTime: 0.3,
          idling: 12.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-02T10:00:00Z',
          oee: 60.1,
          timeOee: 82.1,
          performanceOee: 84.8,
          yieldRate: 93.2,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.7,
          idling: 15.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-03T10:00:00Z',
          oee: 58.9,
          timeOee: 81.4,
          performanceOee: 84.2,
          yieldRate: 92.5,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 0.8,
          idling: 16.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-04T10:00:00Z',
          oee: 70.2,
          timeOee: 89.5,
          performanceOee: 88.9,
          yieldRate: 95.8,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.2,
          idling: 11.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-05T10:00:00Z',
          oee: 72.6,
          timeOee: 90.9,
          performanceOee: 90.1,
          yieldRate: 96.2,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.1,
          idling: 9.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-06T10:00:00Z',
          oee: 74.3,
          timeOee: 92.2,
          performanceOee: 91.0,
          yieldRate: 96.6,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 8.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-07T10:00:00Z',
          oee: 73.0,
          timeOee: 91.3,
          performanceOee: 90.4,
          yieldRate: 96.3,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.3,
          idling: 9.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-08T10:00:00Z',
          oee: 69.5,
          timeOee: 88.7,
          performanceOee: 88.6,
          yieldRate: 95.1,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.5,
          idling: 11.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-09T10:00:00Z',
          oee: 59.8,
          timeOee: 82.0,
          performanceOee: 85.2,
          yieldRate: 93.0,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.7,
          idling: 15.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-10T10:00:00Z',
          oee: 58.5,
          timeOee: 81.3,
          performanceOee: 84.6,
          yieldRate: 92.3,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.8,
          idling: 16.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-11T10:00:00Z',
          oee: 76.1,
          timeOee: 93.5,
          performanceOee: 91.5,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 7.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-12T10:00:00Z',
          oee: 78.4,
          timeOee: 94.8,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-13T10:00:00Z',
          oee: 77.2,
          timeOee: 94.1,
          performanceOee: 91.8,
          yieldRate: 96.8,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.1,
          idling: 6.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-14T10:00:00Z',
          oee: 74.1,
          timeOee: 92.0,
          performanceOee: 90.6,
          yieldRate: 96.2,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.4,
          idling: 8.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-15T10:00:00Z',
          oee: 66.3,
          timeOee: 87.1,
          performanceOee: 87.8,
          yieldRate: 95.0,
          plannedShutdownTime: 1.6,
          unplannedShutdownTime: 0.0,
          idling: 13.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-16T10:00:00Z',
          oee: 62.6,
          timeOee: 83.8,
          performanceOee: 85.8,
          yieldRate: 93.6,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.6,
          idling: 14.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-17T10:00:00Z',
          oee: 61.3,
          timeOee: 83.1,
          performanceOee: 85.2,
          yieldRate: 92.9,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.7,
          idling: 15.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-18T10:00:00Z',
          oee: 79.5,
          timeOee: 95.0,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 6.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-19T10:00:00Z',
          oee: 81.2,
          timeOee: 95.3,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-20T10:00:00Z',
          oee: 82.0,
          timeOee: 95.6,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-21T10:00:00Z',
          oee: 82.8,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-22T10:00:00Z',
          oee: 81.5,
          timeOee: 95.2,
          performanceOee: 91.9,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.1,
          idling: 5.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-23T10:00:00Z',
          oee: 64.9,
          timeOee: 85.4,
          performanceOee: 86.3,
          yieldRate: 94.0,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.5,
          idling: 14.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-24T10:00:00Z',
          oee: 63.6,
          timeOee: 84.7,
          performanceOee: 85.7,
          yieldRate: 93.3,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.6,
          idling: 15.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-25T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-26T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-27T10:00:00Z',
          oee: 82.9,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-28T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-29T10:00:00Z',
          oee: 82.6,
          timeOee: 95.7,
          performanceOee: 91.9,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-30T10:00:00Z',
          oee: 70.5,
          timeOee: 88.9,
          performanceOee: 88.0,
          yieldRate: 95.3,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.3,
          idling: 11.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade04',
          deviceName: '喷涂设备2',
          ts: '2024-03-31T10:00:00Z',
          oee: 83.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
        },
      ],
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
      deviceName: '除湿机设备1',
      timedInfoList: [
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-01T10:00:00Z',
          oee: 78.3,
          timeOee: 92.5,
          performanceOee: 89.2,
          yieldRate: 94.8,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.1,
          idling: 7.2,
          humidityRemovalRate: 85.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-02T10:00:00Z',
          oee: 76.1,
          timeOee: 90.3,
          performanceOee: 88.7,
          yieldRate: 94.2,
          plannedShutdownTime: 0.9,
          unplannedShutdownTime: 0.3,
          idling: 8.5,
          humidityRemovalRate: 83.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-03T10:00:00Z',
          oee: 77.5,
          timeOee: 91.8,
          performanceOee: 89.0,
          yieldRate: 94.5,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.2,
          idling: 7.8,
          humidityRemovalRate: 84.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-04T10:00:00Z',
          oee: 79.6,
          timeOee: 93.2,
          performanceOee: 89.8,
          yieldRate: 95.1,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.1,
          idling: 6.7,
          humidityRemovalRate: 86.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-05T10:00:00Z',
          oee: 80.2,
          timeOee: 93.8,
          performanceOee: 90.3,
          yieldRate: 95.4,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.1,
          idling: 6.3,
          humidityRemovalRate: 86.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-06T10:00:00Z',
          oee: 81.7,
          timeOee: 94.5,
          performanceOee: 91.0,
          yieldRate: 95.8,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.0,
          idling: 5.8,
          humidityRemovalRate: 87.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-07T10:00:00Z',
          oee: 80.9,
          timeOee: 94.0,
          performanceOee: 90.7,
          yieldRate: 95.6,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.1,
          idling: 6.1,
          humidityRemovalRate: 87.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-08T10:00:00Z',
          oee: 75.3,
          timeOee: 89.7,
          performanceOee: 88.2,
          yieldRate: 93.1,
          plannedShutdownTime: 1.0,
          unplannedShutdownTime: 0.2,
          idling: 9.2,
          humidityRemovalRate: 82.4,
        }, // 传感器校准
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-09T10:00:00Z',
          oee: 77.8,
          timeOee: 92.1,
          performanceOee: 89.3,
          yieldRate: 94.7,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.1,
          idling: 7.5,
          humidityRemovalRate: 85.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-10T10:00:00Z',
          oee: 76.4,
          timeOee: 91.2,
          performanceOee: 88.9,
          yieldRate: 94.3,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.2,
          idling: 8.1,
          humidityRemovalRate: 84.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-11T10:00:00Z',
          oee: 82.3,
          timeOee: 95.0,
          performanceOee: 91.5,
          yieldRate: 96.2,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.0,
          idling: 5.5,
          humidityRemovalRate: 88.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-12T10:00:00Z',
          oee: 83.1,
          timeOee: 95.4,
          performanceOee: 91.8,
          yieldRate: 96.4,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 5.1,
          humidityRemovalRate: 88.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-13T10:00:00Z',
          oee: 82.7,
          timeOee: 95.2,
          performanceOee: 91.7,
          yieldRate: 96.3,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.0,
          idling: 5.3,
          humidityRemovalRate: 88.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-14T10:00:00Z',
          oee: 81.5,
          timeOee: 94.3,
          performanceOee: 91.2,
          yieldRate: 96.0,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.1,
          idling: 5.9,
          humidityRemovalRate: 87.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-15T10:00:00Z',
          oee: 72.8,
          timeOee: 88.2,
          performanceOee: 87.3,
          yieldRate: 93.9,
          plannedShutdownTime: 1.5,
          unplannedShutdownTime: 0.0,
          idling: 10.3,
          humidityRemovalRate: 81.2,
        }, // 滤网更换
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-16T10:00:00Z',
          oee: 78.6,
          timeOee: 92.7,
          performanceOee: 89.6,
          yieldRate: 95.0,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.1,
          idling: 7.1,
          humidityRemovalRate: 85.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-17T10:00:00Z',
          oee: 77.2,
          timeOee: 91.9,
          performanceOee: 89.1,
          yieldRate: 94.6,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.2,
          idling: 7.8,
          humidityRemovalRate: 84.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-18T10:00:00Z',
          oee: 83.6,
          timeOee: 95.7,
          performanceOee: 91.9,
          yieldRate: 96.5,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
          humidityRemovalRate: 89.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-19T10:00:00Z',
          oee: 84.2,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 96.6,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.8,
          humidityRemovalRate: 89.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-20T10:00:00Z',
          oee: 84.5,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.6,
          humidityRemovalRate: 90.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-21T10:00:00Z',
          oee: 84.8,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.5,
          humidityRemovalRate: 90.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-22T10:00:00Z',
          oee: 84.1,
          timeOee: 95.8,
          performanceOee: 91.9,
          yieldRate: 96.5,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 4.9,
          humidityRemovalRate: 89.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-23T10:00:00Z',
          oee: 79.1,
          timeOee: 93.1,
          performanceOee: 89.5,
          yieldRate: 95.2,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.1,
          idling: 6.9,
          humidityRemovalRate: 86.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-24T10:00:00Z',
          oee: 77.9,
          timeOee: 92.4,
          performanceOee: 89.2,
          yieldRate: 94.9,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.2,
          idling: 7.4,
          humidityRemovalRate: 85.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-25T10:00:00Z',
          oee: 84.9,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.5,
          humidityRemovalRate: 90.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-26T10:00:00Z',
          oee: 85.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.5,
          humidityRemovalRate: 90.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-27T10:00:00Z',
          oee: 84.7,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.6,
          humidityRemovalRate: 90.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-28T10:00:00Z',
          oee: 86.2,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.2,
          plannedShutdownTime: 0.0,
          unplannedShutdownTime: 0.0,
          idling: 4.2,
          humidityRemovalRate: 91.2,
        }, // 湿度骤升
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-29T10:00:00Z',
          oee: 84.3,
          timeOee: 95.8,
          performanceOee: 91.9,
          yieldRate: 96.5,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.8,
          humidityRemovalRate: 89.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-30T10:00:00Z',
          oee: 80.5,
          timeOee: 94.2,
          performanceOee: 90.8,
          yieldRate: 95.9,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.1,
          idling: 6.2,
          humidityRemovalRate: 87.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade05',
          deviceName: '除湿机设备1',
          ts: '2024-03-31T10:00:00Z',
          oee: 85.0,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.5,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.5,
          humidityRemovalRate: 89.7,
        },
      ],
    },
    {
      deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
      deviceName: '除湿机设备2',
      timedInfoList: [
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-01T10:00:00Z',
          oee: 79.5,
          timeOee: 93.2,
          performanceOee: 89.8,
          yieldRate: 95.3,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.1,
          idling: 6.5,
          humidityRemovalRate: 86.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-02T10:00:00Z',
          oee: 77.2,
          timeOee: 91.5,
          performanceOee: 89.1,
          yieldRate: 94.7,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.2,
          idling: 7.8,
          humidityRemovalRate: 85.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-03T10:00:00Z',
          oee: 78.6,
          timeOee: 92.8,
          performanceOee: 89.6,
          yieldRate: 95.1,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.1,
          idling: 7.1,
          humidityRemovalRate: 86.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-04T10:00:00Z',
          oee: 80.1,
          timeOee: 94.0,
          performanceOee: 90.3,
          yieldRate: 95.6,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.1,
          idling: 6.2,
          humidityRemovalRate: 87.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-05T10:00:00Z',
          oee: 81.3,
          timeOee: 94.7,
          performanceOee: 90.9,
          yieldRate: 95.9,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.0,
          idling: 5.7,
          humidityRemovalRate: 88.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-06T10:00:00Z',
          oee: 82.5,
          timeOee: 95.3,
          performanceOee: 91.4,
          yieldRate: 96.3,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.0,
          idling: 5.2,
          humidityRemovalRate: 88.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-07T10:00:00Z',
          oee: 81.7,
          timeOee: 94.8,
          performanceOee: 91.1,
          yieldRate: 96.0,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.1,
          idling: 5.5,
          humidityRemovalRate: 88.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-08T10:00:00Z',
          oee: 76.4,
          timeOee: 90.2,
          performanceOee: 88.5,
          yieldRate: 93.8,
          plannedShutdownTime: 1.1,
          unplannedShutdownTime: 0.3,
          idling: 8.9,
          humidityRemovalRate: 83.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-09T10:00:00Z',
          oee: 78.9,
          timeOee: 93.0,
          performanceOee: 89.7,
          yieldRate: 95.2,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.1,
          idling: 6.8,
          humidityRemovalRate: 86.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-10T10:00:00Z',
          oee: 77.5,
          timeOee: 92.1,
          performanceOee: 89.3,
          yieldRate: 94.8,
          plannedShutdownTime: 0.8,
          unplannedShutdownTime: 0.2,
          idling: 7.4,
          humidityRemovalRate: 85.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-11T10:00:00Z',
          oee: 83.2,
          timeOee: 95.6,
          performanceOee: 91.7,
          yieldRate: 96.4,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 5.0,
          humidityRemovalRate: 89.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-12T10:00:00Z',
          oee: 84.1,
          timeOee: 95.9,
          performanceOee: 91.9,
          yieldRate: 96.6,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.8,
          humidityRemovalRate: 89.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-13T10:00:00Z',
          oee: 83.7,
          timeOee: 95.7,
          performanceOee: 91.8,
          yieldRate: 96.5,
          plannedShutdownTime: 0.2,
          unplannedShutdownTime: 0.0,
          idling: 5.1,
          humidityRemovalRate: 89.6,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-14T10:00:00Z',
          oee: 82.6,
          timeOee: 95.1,
          performanceOee: 91.3,
          yieldRate: 96.2,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.1,
          idling: 5.6,
          humidityRemovalRate: 88.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-15T10:00:00Z',
          oee: 73.9,
          timeOee: 89.0,
          performanceOee: 87.8,
          yieldRate: 94.3,
          plannedShutdownTime: 1.6,
          unplannedShutdownTime: 0.0,
          idling: 9.8,
          humidityRemovalRate: 82.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-16T10:00:00Z',
          oee: 79.7,
          timeOee: 93.5,
          performanceOee: 90.0,
          yieldRate: 95.5,
          plannedShutdownTime: 0.6,
          unplannedShutdownTime: 0.1,
          idling: 6.4,
          humidityRemovalRate: 87.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-17T10:00:00Z',
          oee: 78.3,
          timeOee: 92.8,
          performanceOee: 89.5,
          yieldRate: 95.0,
          plannedShutdownTime: 0.7,
          unplannedShutdownTime: 0.2,
          idling: 7.1,
          humidityRemovalRate: 86.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-18T10:00:00Z',
          oee: 84.6,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.6,
          humidityRemovalRate: 90.4,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-19T10:00:00Z',
          oee: 85.1,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.5,
          humidityRemovalRate: 90.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-20T10:00:00Z',
          oee: 85.4,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.4,
          humidityRemovalRate: 90.9,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-21T10:00:00Z',
          oee: 85.7,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.0,
          unplannedShutdownTime: 0.0,
          idling: 4.3,
          humidityRemovalRate: 91.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-22T10:00:00Z',
          oee: 85.0,
          timeOee: 95.8,
          performanceOee: 91.9,
          yieldRate: 96.7,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.7,
          humidityRemovalRate: 90.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-23T10:00:00Z',
          oee: 80.2,
          timeOee: 94.3,
          performanceOee: 90.7,
          yieldRate: 95.8,
          plannedShutdownTime: 0.4,
          unplannedShutdownTime: 0.1,
          idling: 6.1,
          humidityRemovalRate: 87.7,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-24T10:00:00Z',
          oee: 79.0,
          timeOee: 93.6,
          performanceOee: 90.3,
          yieldRate: 95.5,
          plannedShutdownTime: 0.5,
          unplannedShutdownTime: 0.2,
          idling: 6.7,
          humidityRemovalRate: 87.1,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-25T10:00:00Z',
          oee: 85.8,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.0,
          unplannedShutdownTime: 0.0,
          idling: 4.3,
          humidityRemovalRate: 91.2,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-26T10:00:00Z',
          oee: 85.9,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.0,
          plannedShutdownTime: 0.0,
          unplannedShutdownTime: 0.0,
          idling: 4.2,
          humidityRemovalRate: 91.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-27T10:00:00Z',
          oee: 85.6,
          timeOee: 95.9,
          performanceOee: 92.0,
          yieldRate: 96.9,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.4,
          humidityRemovalRate: 91.0,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-28T10:00:00Z',
          oee: 86.5,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 97.3,
          plannedShutdownTime: 0.0,
          unplannedShutdownTime: 0.0,
          idling: 4.1,
          humidityRemovalRate: 91.8,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-29T10:00:00Z',
          oee: 85.2,
          timeOee: 95.8,
          performanceOee: 91.9,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.6,
          humidityRemovalRate: 90.5,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-30T10:00:00Z',
          oee: 81.6,
          timeOee: 94.5,
          performanceOee: 91.0,
          yieldRate: 96.0,
          plannedShutdownTime: 0.3,
          unplannedShutdownTime: 0.1,
          idling: 5.9,
          humidityRemovalRate: 88.3,
        },
        {
          deviceId: 'fa19b366-4283-463c-aaf5-ec0b440ade06',
          deviceName: '除湿机设备2',
          ts: '2024-03-31T10:00:00Z',
          oee: 85.5,
          timeOee: 96.0,
          performanceOee: 92.0,
          yieldRate: 96.8,
          plannedShutdownTime: 0.1,
          unplannedShutdownTime: 0.0,
          idling: 4.4,
          humidityRemovalRate: 91.0,
        },
      ],
    },
  ],
};
